import { AxiosResponse } from 'axios';
import moment from 'moment';
import { PinLoginDto } from '../components/pin-login.dto';
import { PinDto } from '../components/pin.dto';
import { ThresholdType } from '../enums/threshold-type.enum';
import { AcceptOrganizationRelationDto } from '../interfaces/accept-organization-relation.dto';
import { AccessToken } from '../interfaces/access-token';
import { AddUserToOrganizationDto } from '../interfaces/add-user-to-organization.dto';
import { Alert } from '../interfaces/alert';
import { Balance } from '../interfaces/balance';
import { BalanceDto } from '../interfaces/balance.dto';
import { Brand } from '../interfaces/brand';
import { BrandDto } from '../interfaces/brand.dto';
import { CancelRepairDto } from '../interfaces/cancel-repair.dto';
import { CancelSaleDto } from '../interfaces/cancel-sale.dto';
import { CashMovement } from '../interfaces/cash-movement';
import { CashRegisterCheck } from '../interfaces/cash-register-check';
import { Category } from '../interfaces/category';
import { CategoryProductsCountDto } from '../interfaces/category-products-count.dto';
import { CategoryDto } from '../interfaces/category.dto';
import { ChangeEnableProductStock } from '../interfaces/change-enable-product-stock';
import { ChangePasswordDto } from '../interfaces/change-password.dto';
import { ChangeRepairCustomerDto } from '../interfaces/change-repair-customer.dto';
import { ClosingCashRegisterCheckDto } from '../interfaces/closing-cash-register-check.dto';
import { Collection } from '../interfaces/collection';
import { CollectionDto } from '../interfaces/collection.dto';
import { CreateExpirationDto } from '../interfaces/create-expiration.dto';
import { CreateRepairDto } from '../interfaces/create-repair.dto';
import { CreateTransferDto } from '../interfaces/create-transfer.dto';
import { CsvColumnDto } from '../interfaces/csv-column.dto';
import { Customer } from '../interfaces/customer';
import { CustomerPrivacyMarketingDto } from '../interfaces/customer-privacy-marketing.dto';
import { CustomerSignDto } from '../interfaces/customer-sign.dto';
import { CustomerDto } from '../interfaces/customer.dto';
import { DeleteUserWorkClockingDto } from '../interfaces/delete-user-work-clocking.dto';
import { DeliverRepairDto } from '../interfaces/deliver-repair.dto';
import { DeliveryNote } from '../interfaces/delivery-note';
import { DeliveryNoteCreateTransfersDto } from '../interfaces/delivery-note-create-transfers.dto';
import { DeliveryNoteDraftTransfersDto } from '../interfaces/delivery-note-draft-transfers.dto';
import { DeliveryNoteFileDto } from '../interfaces/delivery-note-file.dto';
import { DeliveryNoteInvoiceDetailsDto } from '../interfaces/delivery-note-invoice-details.dto';
import { DeliveryNoteProduct } from '../interfaces/delivery-note-product';
import { DeliveryNoteProductInfo } from '../interfaces/delivery-note-product-info.dto';
import { DeliveryNoteDto } from '../interfaces/delivery-note.dto';
import { DeliveryNotesQuery } from '../interfaces/delivery-notes-query';
import { DetailCashRegister } from '../interfaces/detail-cash-register';
import { ElasticSearchRequest } from '../interfaces/elastic-search-request';
import { Expiration } from '../interfaces/expiration';
import { ExpirationPaymentDto } from '../interfaces/expiration-payment.dto';
import { ExportCustomersQuery } from '../interfaces/export-customers-query';
import { Family } from '../interfaces/family';
import { FamilyDto } from '../interfaces/family.dto';
import { FromBase64Dto } from '../interfaces/from-base64.dto';
import { FromLinkDto } from '../interfaces/from-link.dto';
import { GetFiltersInventory } from '../interfaces/get-filters-inventory';
import { GetPaginatedCustomers } from '../interfaces/get-paginated-customers';
import { GetPaginatedProducts } from '../interfaces/get-paginated-products';
import { GetPaginatedStock } from '../interfaces/get-paginated-stock';
import { GetPaginatedStockExtended } from '../interfaces/get-paginated-stock-extended';
import { GetPaginatedStockOrganization } from '../interfaces/get-paginated-stock-organization';
import { GetPaginatedStockWholesaler } from '../interfaces/get-paginated-stock-wholesaler';
import { GetProductsTags } from '../interfaces/get-product-tags';
import { GetProducts } from '../interfaces/get-products';
import { GetPurchaseOrdersQuery } from '../interfaces/get-purchase-orders-query';
import { GetSalesQuery } from '../interfaces/get-sales-query';
import { GetSalesStats } from '../interfaces/get-sales-stats';
import { GetStatsMostSelledProducts } from '../interfaces/get-stats-most-selled-products';
import { GetStock } from '../interfaces/get-stock';
import { GetStockTotals } from '../interfaces/get-stock-totals';
import { GetUserTimeSheet } from '../interfaces/get-user-time-sheets-query';
import { GetOrganizationUserWorkClockingsQuery } from '../interfaces/get-user-work-clockings-organization-query';
import { GetUserWorkClockingsQuery } from '../interfaces/get-user-work-clockings-query';
import { GetWorkShiftsQuery } from '../interfaces/get-work-shifts-query';
import { ImportCsvResultDto } from '../interfaces/import-csv-result.dto';
import { ImportCsvDto } from '../interfaces/import-csv.dto';
import { ImportDeliveryNoteDto } from '../interfaces/import-delivery-note.dto';
import { ImportElectronicInvoiceDto } from '../interfaces/import-electronic-invoice.dto';
import { ImportProductsCsvDto } from '../interfaces/import-products-csv.dto';
import { InternalEan } from '../interfaces/internal-ean';
import { Inventory } from '../interfaces/inventory';
import { InventoryFilters } from '../interfaces/inventory-filters';
import { InventoryDto } from '../interfaces/inventory.dto';
import { Invoice } from '../interfaces/invoice';
import { InvoiceNotesDto } from '../interfaces/invoice-notes.dto';
import { InvoiceDto } from '../interfaces/invoice.dto';
import { LoginDto } from '../interfaces/login.dto';
import { MarkAllNotificationsAsReadDto } from '../interfaces/mark-all-notifications-as-read.dto';
import { NotesDto } from '../interfaces/notes.dto';
import { Notification } from '../interfaces/notification';
import { OnlineStore } from '../interfaces/online-store';
import { OnlineStoreStock } from '../interfaces/online-store-stock';
import { OnlineStoreSynchronization } from '../interfaces/online-store-synchronization';
import { OnlineStoreDto } from '../interfaces/online-store.dto';
import { OpeningCashRegisterCheckDto } from '../interfaces/opening-cash-register-check.dto';
import { Organization } from '../interfaces/organization';
import { OrganizationClient } from '../interfaces/organization-client';
import { OrganizationClientDto } from '../interfaces/organization-client.dto';
import { OrganizationDto } from '../interfaces/organization.dto';
import { PaginatedDto } from '../interfaces/paginated.dto';
import { PaymentMethod } from '../interfaces/payment-method';
import { PaymentMethodDto } from '../interfaces/payment-method.dto';
import { Payroll } from '../interfaces/payroll';
import { PayrollDto } from '../interfaces/payroll.dto';
import { PreviosNextSaleDto } from '../interfaces/previos-next-sale.dto';
import { PreviousNextStock } from '../interfaces/previous-next-stock';
import { Product } from '../interfaces/product';
import { ProductHistory } from '../interfaces/product-history';
import { ProductImage } from '../interfaces/product-image';
import { ProductInStore } from '../interfaces/product-in-store';
import { ProductStoreStock } from '../interfaces/product-store-stock';
import { ProductTransferDto } from '../interfaces/product-transfer.dto';
import { ProductUnitsInStore } from '../interfaces/product-units-in-stores';
import { PurchaseOrder } from '../interfaces/purchase-order';
import { PurchaseOrderRevisionDto } from '../interfaces/purchase-order-revision.dto';
import { PurchaseOrderDto } from '../interfaces/purchase-order.dto';
import { ReassignSaleToCustomerDto } from '../interfaces/reasign-sale-to-customer.dto';
import { RecoverPasswordDto } from '../interfaces/recover-password.dto';
import { RecoverPinDto } from '../interfaces/recover-pin.dto';
import { RefreshTokenDto } from '../interfaces/refresh-token.dto';
import { Repair } from '../interfaces/repair';
import { RepairAcceptBudgetDto } from '../interfaces/repair-accept-budget.dto';
import { RepairBudgetInStoreDto } from '../interfaces/repair-budget-in-store.dto';
import { RepairImage } from '../interfaces/repair-image';
import { RepairInternalReferenceDto } from '../interfaces/repair-internal-reference.dto';
import { RepairPricesDto } from '../interfaces/repair-prices.dto';
import { RepairReceivedFromTechnicalServiceDto } from '../interfaces/repair-received-from-technical-service.dto';
import { RepairRecord } from '../interfaces/repair-record';
import { RepairRecordDto } from '../interfaces/repair-record.dto';
import { RepairSentTechnicalServiceDto } from '../interfaces/repair-sent-technical-service.dto';
import { ReplaceManualSaleProductDto } from '../interfaces/replace-manual-sale-product.dto';
import { ResetPasswordDto } from '../interfaces/reset-password.dto';
import { ReturnDto } from '../interfaces/return.dto';
import { Sale } from '../interfaces/sale';
import { SaleDto } from '../interfaces/sale.dto';
import { SalesStats } from '../interfaces/sales-stats';
import { SalesStatsByProduct } from '../interfaces/sales-stats-by-product';
import { ShoppingStats } from '../interfaces/shopping-stats';
import { StatsMostSelledProduct } from '../interfaces/stats-most-selled-product';
import { Stock } from '../interfaces/stock';
import { StockControl } from '../interfaces/stock-control';
import { StockStats } from '../interfaces/stock-stats';
import { StockStatsByProduct } from '../interfaces/stock-stats-by-product';
import { StockTotals } from '../interfaces/stock-totals';
import { StockTransfer } from '../interfaces/stock-transfer';
import { StockDto } from '../interfaces/stock.dto';
import { Store } from '../interfaces/store';
import { StoreDto } from '../interfaces/store.dto';
import { SubFamilyDto } from '../interfaces/sub-familiy.dto';
import { SubCategory } from '../interfaces/subcategory';
import { SubCategoryDto } from '../interfaces/subcategory.dto';
import { SubFamily } from '../interfaces/subfamily';
import { Supplier } from '../interfaces/supplier';
import { SupplierDto } from '../interfaces/supplier.dto';
import { TechnicalService } from '../interfaces/technical-service';
import { TechnicalServiceDto } from '../interfaces/technical-service.dto';
import { Threshold } from '../interfaces/threshold';
import { ThresholdDto } from '../interfaces/threshold.dto';
import { ChangeEditableDto } from '../interfaces/toggle-editable-products.dto';
import { TokenDto } from '../interfaces/token.dto';
import { TotalUnreadNotifications } from '../interfaces/total-unread-notifications.dto';
import { Transfer } from '../interfaces/transfer';
import { UpdateBookingPaymentDto } from '../interfaces/update-booking-payment.dto';
import { UpdateCashRegisterCheckDto } from '../interfaces/update-cash-register-check.dto';
import { UpdateInventoryDto } from '../interfaces/update-inventory.dto';
import { UpdateOnlineStatusSaleDto } from '../interfaces/update-online-status-sale.dto';
import { UpdateOnlineStock } from '../interfaces/update-online-stock';
import { UpdateOnlineStoreStock } from '../interfaces/update-online-store-stock.dto';
import { UpdatePaymentOnAccountDto } from '../interfaces/update-payment-on-account.dto';
import { UpdateProductDto } from '../interfaces/update-product.dto';
import { UpdatePurchaseOrderDto } from '../interfaces/update-purchase-order.dto';
import { UpdateRepairPaymentMethodDto } from '../interfaces/update-repair-payment-method.dto';
import { UpdateRepairWithWarrantyDto } from '../interfaces/update-repair-with-warranty.dto';
import { UpdateRepairDto } from '../interfaces/update-repair.dto';
import { UpdateSalePaymentMethodDto } from '../interfaces/update-sale-payment-method.dto';
import { UpdateSaleDto } from '../interfaces/update-sale.dto';
import { UpdateStockDto } from '../interfaces/update-stock.dto';
import { UpdateTransferDto } from '../interfaces/update-transfer.dto';
import { User } from '../interfaces/user';
import { UserInOrganizationDto } from '../interfaces/user-in-organization.dto';
import { UserTimeSheet } from '../interfaces/user-time-sheet';
import { UserTimeSheetDto } from '../interfaces/user-time-sheet.dto';
import { UserWorkClocking } from '../interfaces/user-work-clocking';
import { UserWorkClockingDto } from '../interfaces/user-work-clocking.dto';
import { UserDto } from '../interfaces/user.dto';
import { VerifyProductTransferDto } from '../interfaces/verify-product-transfer.dto';
import { Withdrawal } from '../interfaces/withdrawal';
import { WithdrawalDto } from '../interfaces/withdrawal.dto';
import { WorkShift } from '../interfaces/work-shift';
import { WorkShiftDto } from '../interfaces/work-shift.dto';
import { WorkShiftsDto } from '../interfaces/work-shifts.dto';
import httpClient from './http-client';

class Api {
  // ALERTS

  public async getUnreadAlerts(storeId: number): Promise<Alert[]> {
    let url = '/alerts';
    if (storeId > 0) {
      url += `?storeId=${storeId}`;
    }
    const axiosResponse: AxiosResponse<Alert[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async markAlertAsRead(alertId: number): Promise<Alert> {
    const url = `/alerts/${alertId}`;
    const axiosResponse: AxiosResponse<Alert> = await httpClient.put(url);
    return axiosResponse.data;
  }

  // AUTH

  public async login(loginDto: LoginDto): Promise<AccessToken> {
    const url = '/auth/login';
    const axiosResponse: AxiosResponse<AccessToken> = await httpClient.post(url, loginDto);
    return axiosResponse.data;
  }

  public async loginWithPin(pinLoginDto: PinLoginDto): Promise<AccessToken> {
    const url = '/auth/login-pin';
    const axiosResponse: AxiosResponse<AccessToken> = await httpClient.post(url, pinLoginDto);
    return axiosResponse.data;
  }

  public async recoverPin(recoverPinDto: RecoverPinDto): Promise<AccessToken> {
    const url = '/auth/recover-pin';
    const axiosResponse: AxiosResponse<AccessToken> = await httpClient.post(url, recoverPinDto);
    return axiosResponse.data;
  }

  public async checkPin(pinDto: PinDto): Promise<void> {
    const url = '/auth/check-pin';
    await httpClient.post(url, pinDto);
  }

  public async refreshAccessToken(refreshTokenDto: RefreshTokenDto): Promise<AccessToken> {
    const url = '/auth/refresh-token';
    const axiosResponse: AxiosResponse<AccessToken> = await httpClient.post(url, refreshTokenDto);
    return axiosResponse.data;
  }

  public async recoverPassword(recoverPasswordDto: RecoverPasswordDto): Promise<void> {
    const url = '/auth/recover-password';
    await httpClient.post(url, recoverPasswordDto);
  }

  public async resetPassword(resetPasswordDto: ResetPasswordDto): Promise<void> {
    const url = '/auth/reset-password';
    await httpClient.post(url, resetPasswordDto);
  }

  // BALANCES

  public async getCustomerBalance(customerId: number): Promise<Balance> {
    const url = `/balances/customer/${customerId}`;
    const axiosResponse: AxiosResponse<Balance> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async updateCustomerBalance(balanceDto: BalanceDto): Promise<Balance> {
    const url = '/balances';
    const axiosResponse: AxiosResponse<Balance> = await httpClient.put(url, balanceDto);
    return axiosResponse.data;
  }

  // BRANDS

  public async getBrands(organizationId?: number): Promise<Brand[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    const url = `/brands?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Brand[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createBrand(brandDto: BrandDto): Promise<Brand> {
    const url = `/brands`;
    const axiosResponse: AxiosResponse<Brand> = await httpClient.post(url, brandDto);
    return axiosResponse.data;
  }

  public async updateBrand(id: number, brandDto: BrandDto): Promise<Brand> {
    const url = `/brands/${id}`;
    const axiosResponse: AxiosResponse<Brand> = await httpClient.put(url, brandDto);
    return axiosResponse.data;
  }

  public async deleteBrand(id: number): Promise<Brand> {
    const url = `/brands/${id}`;
    const axiosResponse: AxiosResponse<Brand> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async updateEditableFieldInProductsGivenBrandId(id: number, changeEditableDto: ChangeEditableDto): Promise<void> {
    const url = `/brands/${id}/products/editable`;
    await httpClient.post(url, changeEditableDto);
  }

  // CASH MOVEMENTS

  public async getCashMovements(storeId: number, startDate: Date, endDate: Date): Promise<CashMovement[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('storeId', storeId.toString());
    urlSearchParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
    urlSearchParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    const url = `/cash-movements?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<CashMovement[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  // CASH REGISTERS

  public async getCashRegistersChecks(startDate: Date | null, endDate: Date | null, organizationId: number, storeId: number): Promise<CashRegisterCheck[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (startDate) {
      urlSearchParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
    }
    if (endDate) {
      urlSearchParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    }
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    if (storeId && storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    const url = `/cash-registers?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<CashRegisterCheck[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getCurrentCashRegisterCheck(storeId: number): Promise<CashRegisterCheck> {
    const url = `/cash-registers/current?storeId=${storeId}`;
    const axiosResponse: AxiosResponse<CashRegisterCheck> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getDetailCashRegisterCheck(id: number): Promise<DetailCashRegister> {
    const url = `/cash-registers/${id}`;
    const axiosResponse: AxiosResponse<DetailCashRegister> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async updateCashRegisterCheck(id: number, cashRegisterCheckDto: UpdateCashRegisterCheckDto): Promise<CashRegisterCheck> {
    const url = `/cash-registers/${id}`;
    const axiosResponse: AxiosResponse<CashRegisterCheck> = await httpClient.put(url, cashRegisterCheckDto);
    return axiosResponse.data;
  }

  public async openCashRegisterCheck(openingCashRegisterCheckDto: OpeningCashRegisterCheckDto): Promise<CashRegisterCheck> {
    const url = `/cash-registers/opening`;
    const axiosResponse: AxiosResponse<CashRegisterCheck> = await httpClient.post(url, openingCashRegisterCheckDto);
    return axiosResponse.data;
  }

  public async closeCashRegisterCheck(closingCashRegisterCheckDto: ClosingCashRegisterCheckDto): Promise<Store> {
    const url = `/cash-registers/closing`;
    const axiosResponse: AxiosResponse<Store> = await httpClient.post(url, closingCashRegisterCheckDto);
    return axiosResponse.data;
  }

  public async markCashRegisterAsResolved(id: number): Promise<CashRegisterCheck> {
    const url = `/cash-registers/${id}/resolved`;
    const axiosResponse: AxiosResponse<CashRegisterCheck> = await httpClient.post(url);
    return axiosResponse.data;
  }

  public async deleteCashRegister(id: number): Promise<CashRegisterCheck> {
    const url = `/cash-registers/${id}`;
    const axiosResponse: AxiosResponse<CashRegisterCheck> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async getTicketCashRegisterCheck(id: number): Promise<Buffer> {
    const url = `/cash-registers/${id}/ticket`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, { responseType: 'arraybuffer' });
    return axiosResponse.data;
  }

  // CATEGORIES

  public async getCategories(organizationId?: number): Promise<Category[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    const url = `/categories?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Category[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getCategoriesSubcategoriesProductsCount(organizationId: number): Promise<CategoryProductsCountDto[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    const url = `/categories/subcategories-products-count?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<CategoryProductsCountDto[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getCategory(id: number): Promise<Category> {
    const url = `/categories/${id}`;
    const axiosResponse: AxiosResponse<Category> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createCategory(categoryDto: CategoryDto): Promise<Category> {
    const url = `/categories`;
    const axiosResponse: AxiosResponse<Category> = await httpClient.post(url, categoryDto);
    return axiosResponse.data;
  }

  public async updateCategory(id: number, categoryDto: CategoryDto): Promise<Category> {
    const url = `/categories/${id}`;
    const axiosResponse: AxiosResponse<Category> = await httpClient.put(url, categoryDto);
    return axiosResponse.data;
  }

  public async deleteCategory(id: number): Promise<Category> {
    const url = `/categories/${id}`;
    const axiosResponse: AxiosResponse<Category> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // COLLECTIONS

  public async getCollections(organizationId?: number): Promise<Collection[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    const url = `/collections?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Collection[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getCollection(id: number): Promise<Collection> {
    const url = `/collections/${id}`;
    const axiosResponse: AxiosResponse<Collection> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createCollection(collectionDto: CollectionDto): Promise<Collection> {
    const url = `/collections`;
    const axiosResponse: AxiosResponse<Collection> = await httpClient.post(url, collectionDto);
    return axiosResponse.data;
  }

  public async updateCollection(id: number, collectionDto: CollectionDto): Promise<Collection> {
    const url = `/collections/${id}`;
    const axiosResponse: AxiosResponse<Collection> = await httpClient.put(url, collectionDto);
    return axiosResponse.data;
  }

  public async deleteCollection(id: number): Promise<Collection> {
    const url = `/collections/${id}`;
    const axiosResponse: AxiosResponse<Collection> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // COMMON

  public async downloadApiFileGivenPath(urlPath: string): Promise<Buffer> {
    const url = `${process.env.REACT_APP_PUBLIC_URL}${urlPath}`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, { responseType: 'arraybuffer' });
    return axiosResponse.data;
  }

  // CUSTOMERS

  public async getCustomer(id: number): Promise<Customer> {
    const url = `/customers/${id}`;
    const axiosResponse: AxiosResponse<Customer> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getCustomers(organizationId?: number, query?: string): Promise<Customer[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    if (query && query.length > 0) {
      urlSearchParams.append('query', query);
    }
    const url = `/customers?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Customer[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getPaginatedCustomers(getPaginatedCustomers: GetPaginatedCustomers): Promise<PaginatedDto<Customer>> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('orderField', getPaginatedCustomers.orderField);
    urlSearchParams.append('order', getPaginatedCustomers.order);
    urlSearchParams.append('limit', getPaginatedCustomers.limit.toString());
    urlSearchParams.append('page', getPaginatedCustomers.page.toString());
    if (getPaginatedCustomers.query && getPaginatedCustomers.query.length > 0) {
      urlSearchParams.append('query', getPaginatedCustomers.query);
    }
    urlSearchParams.append('organizationId', getPaginatedCustomers.organizationId.toString());
    if (getPaginatedCustomers.storeId > 0) {
      urlSearchParams.append('storeId', getPaginatedCustomers.storeId.toString());
    }
    if (getPaginatedCustomers.hasEmail !== '') {
      urlSearchParams.append('hasEmail', getPaginatedCustomers.hasEmail.toString());
    }
    const url = `/customers/paginated?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<PaginatedDto<Customer>> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async exportCustomers(exportCustomersQuery: ExportCustomersQuery): Promise<string> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('orderField', exportCustomersQuery.orderField);
    urlSearchParams.append('order', exportCustomersQuery.order);
    if (exportCustomersQuery.query && exportCustomersQuery.query.length > 0) {
      urlSearchParams.append('query', exportCustomersQuery.query);
    }
    urlSearchParams.append('organizationId', exportCustomersQuery.organizationId.toString());
    if (exportCustomersQuery.storeId > 0) {
      urlSearchParams.append('storeId', exportCustomersQuery.storeId.toString());
    }
    if (exportCustomersQuery.hasEmail !== '') {
      urlSearchParams.append('hasEmail', exportCustomersQuery.hasEmail.toString());
    }
    const url = `/customers/export?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<string> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createCustomer(customerDto: CustomerDto): Promise<Customer> {
    const url = `/customers`;
    const axiosResponse: AxiosResponse<Customer> = await httpClient.post(url, customerDto);
    return axiosResponse.data;
  }

  public async updateCustomer(id: number, customerDto: CustomerDto): Promise<Customer> {
    const url = `/customers/${id}`;
    const axiosResponse: AxiosResponse<Customer> = await httpClient.put(url, customerDto);
    return axiosResponse.data;
  }

  public async searchCustomers(elasticSearchRequest: ElasticSearchRequest): Promise<PaginatedDto<Customer>> {
    const url = `/customers/search`;
    const axiosResponse: AxiosResponse<PaginatedDto<Customer>> = await httpClient.post(url, elasticSearchRequest);
    return axiosResponse.data;
  }

  public async deleteCustomer(id: number): Promise<Customer> {
    const url = `/customers/${id}`;
    const axiosResponse: AxiosResponse<Customer> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async verifyCustomerPrivacy(tookenDto: TokenDto): Promise<boolean> {
    const url = `/customers/privacy`;
    const axiosResponse: AxiosResponse<boolean> = await httpClient.post(url, tookenDto);
    return axiosResponse.data;
  }

  public async verifyCustomerAdvertising(tookenDto: TokenDto): Promise<boolean> {
    const url = `/customers/advertising`;
    const axiosResponse: AxiosResponse<boolean> = await httpClient.post(url, tookenDto);
    return axiosResponse.data;
  }

  public async verifyUnsubscribeAdvertisingToken(tookenDto: TokenDto): Promise<boolean> {
    const url = `/customers/unsubscribe-advertising`;
    const axiosResponse: AxiosResponse<boolean> = await httpClient.post(url, tookenDto);
    return axiosResponse.data;
  }

  public async getImportCsvColumnsCustomers(): Promise<CsvColumnDto[]> {
    const url = `/customers/import-csv/columns`;
    const axiosResponse: AxiosResponse<CsvColumnDto[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async importCsvCustomers(importCsvDto: ImportCsvDto): Promise<ImportCsvResultDto> {
    const formData: FormData = new FormData();
    formData.append('file', importCsvDto.file);
    formData.append('storeId', importCsvDto.storeId!.toString());
    formData.append('columns', JSON.stringify(importCsvDto.columns));
    const url = `/customers/import-csv`;
    const axiosResponse: AxiosResponse<ImportCsvResultDto> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async sendEmailPrivacy(customerId: number): Promise<Customer> {
    const url = `/customers/${customerId}/send-email-privacy`;
    const axiosResponse: AxiosResponse<Customer> = await httpClient.post(url);
    return axiosResponse.data;
  }

  public async sendEmailAdvertising(customerId: number): Promise<Customer> {
    const url = `/customers/${customerId}/send-email-advertising`;
    const axiosResponse: AxiosResponse<Customer> = await httpClient.post(url);
    return axiosResponse.data;
  }

  public async updateCustomerPrivacyAndMarketing(customerId: number, customerPrivacyMarketingDto: CustomerPrivacyMarketingDto): Promise<Customer> {
    const url = `/customers/${customerId}/privacy-marketing`;
    const axiosResponse: AxiosResponse<Customer> = await httpClient.put(url, customerPrivacyMarketingDto);
    return axiosResponse.data;
  }

  public async sign(customerSignDto: CustomerSignDto): Promise<Customer> {
    const url = `/customers/sign`;
    const axiosResponse: AxiosResponse<Customer> = await httpClient.post(url, customerSignDto);
    return axiosResponse.data;
  }

  public async exportHistoricalBalances(customerId: number): Promise<any> {
    const url = `/customers/${customerId}/historical-balances/export`;
    const axiosResponse: AxiosResponse<any> = await httpClient.get(url, {
      responseType: 'blob',
    });
    return axiosResponse.data;
  }

  public async exporTickettHistoricalBalances(customerId: number, storeId: number): Promise<any> {
    const url = `/customers/${customerId}/historical-balances/ticket?storeId=${storeId}`;
    const axiosResponse: AxiosResponse<any> = await httpClient.get(url, {
      responseType: 'blob',
    });
    return axiosResponse.data;
  }

  // DELIVERY NOTES

  public async getDeliveryNotes(deliveryNotesQuery: DeliveryNotesQuery): Promise<DeliveryNote[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('startDate', moment(deliveryNotesQuery.startDate).format('YYYY-MM-DD'));
    urlSearchParams.append('endDate', moment(deliveryNotesQuery.endDate).format('YYYY-MM-DD'));
    urlSearchParams.append('organizationId', deliveryNotesQuery.organizationId.toString());
    if (deliveryNotesQuery.storeId && deliveryNotesQuery.storeId > 0) {
      urlSearchParams.append('storeId', deliveryNotesQuery.storeId.toString());
    }
    const url = `/delivery-notes?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<DeliveryNote[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getDeliveryNotesWholesaler(deliveryNotesQuery: DeliveryNotesQuery): Promise<DeliveryNote[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('startDate', moment(deliveryNotesQuery.startDate).format('YYYY-MM-DD'));
    urlSearchParams.append('endDate', moment(deliveryNotesQuery.endDate).format('YYYY-MM-DD'));
    urlSearchParams.append('organizationId', deliveryNotesQuery.organizationId.toString());
    if (deliveryNotesQuery.storeId && deliveryNotesQuery.storeId > 0) {
      urlSearchParams.append('storeId', deliveryNotesQuery.storeId.toString());
    }
    const url = `/delivery-notes/wholesaler?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<DeliveryNote[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getDeliveryNote(id: number): Promise<DeliveryNote> {
    const url = `/delivery-notes/${id}`;
    const axiosResponse: AxiosResponse<DeliveryNote> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getDeliveryNoteProductInfo(supplierId: number, storeId: number, productId: number): Promise<DeliveryNoteProductInfo> {
    const url = `/delivery-notes/delivery-note-product-info?supplierId=${supplierId}&storeId=${storeId}&productId=${productId}`;
    const axiosResponse: AxiosResponse<DeliveryNoteProductInfo> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createDeliveryNote(deliveryNoteDto: DeliveryNoteDto): Promise<DeliveryNote> {
    const url = `/delivery-notes`;
    const axiosResponse: AxiosResponse<DeliveryNote> = await httpClient.post(url, deliveryNoteDto);
    return axiosResponse.data;
  }

  public async updateDeliveryNote(id: number, deliveryNoteDto: DeliveryNoteDto): Promise<DeliveryNote> {
    const url = `/delivery-notes/${id}`;
    const axiosResponse: AxiosResponse<DeliveryNote> = await httpClient.put(url, deliveryNoteDto);
    return axiosResponse.data;
  }

  public async updateDeliveryNoteInvoiceDetails(id: number, deliveryNoteInvoiceDetailsDto: DeliveryNoteInvoiceDetailsDto): Promise<DeliveryNote> {
    const url = `/delivery-notes/${id}/invoice-details`;
    const axiosResponse: AxiosResponse<DeliveryNote> = await httpClient.put(url, deliveryNoteInvoiceDetailsDto);
    return axiosResponse.data;
  }

  public async deleteDeliveryNote(id: number): Promise<DeliveryNote> {
    const url = `/delivery-notes/${id}`;
    const axiosResponse: AxiosResponse<DeliveryNote> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async importElectronicInvoice(importElectronicInvoiceDto: ImportElectronicInvoiceDto): Promise<DeliveryNote> {
    const formData: FormData = new FormData();
    formData.append('storeId', importElectronicInvoiceDto.storeId.toString());
    formData.append('file', importElectronicInvoiceDto.fileList![0]);
    const url = `/delivery-notes/import-electronic-invoice`;
    const axiosResponse: AxiosResponse<DeliveryNote> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async updateDraftTransfersFromDeliveryNote(id: number, deliveryNoteDraftTransfersDto: DeliveryNoteDraftTransfersDto): Promise<DeliveryNote> {
    const url = `/delivery-notes/${id}/draft-transfers`;
    const axiosResponse: AxiosResponse<DeliveryNote> = await httpClient.put(url, deliveryNoteDraftTransfersDto);
    return axiosResponse.data;
  }

  public async createTransfersFromDeliveryNote(id: number, deliveryNoteCreateTransfersDto: DeliveryNoteCreateTransfersDto): Promise<DeliveryNote> {
    const url = `/delivery-notes/${id}/transfers`;
    const axiosResponse: AxiosResponse<DeliveryNote> = await httpClient.post(url, deliveryNoteCreateTransfersDto);
    return axiosResponse.data;
  }

  public async getImportCsvColumnsDeliveryNotes(storeId: number): Promise<CsvColumnDto[]> {
    const url = `/delivery-notes/import-csv/columns?storeId=${storeId}`;
    const axiosResponse: AxiosResponse<CsvColumnDto[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async importCsvDeliveryNotes(importDeliveryNoteDto: ImportDeliveryNoteDto): Promise<ImportCsvResultDto> {
    const formData: FormData = new FormData();
    formData.append('date', moment(importDeliveryNoteDto.date).format('YYYY-MM-DD HH:mm:ss'));
    formData.append('externalNumber', importDeliveryNoteDto.externalNumber);
    formData.append('storeId', importDeliveryNoteDto.storeId!.toString());
    formData.append('supplierId', importDeliveryNoteDto.supplierId!.toString());
    if (importDeliveryNoteDto.invoiceDate) {
      formData.append('invoiceDate', moment(importDeliveryNoteDto.invoiceDate).format('YYYY-MM-DD'));
    }
    if (importDeliveryNoteDto.invoiceNumber) {
      formData.append('invoiceNumber', importDeliveryNoteDto.invoiceNumber);
    }
    formData.append('file', importDeliveryNoteDto.file!);
    formData.append('columns', JSON.stringify(importDeliveryNoteDto.columns));
    formData.append('hasEquivalenceSurcharge', importDeliveryNoteDto.hasEquivalenceSurcharge.toString());
    const url = `/delivery-notes/import-csv`;
    const axiosResponse: AxiosResponse<ImportCsvResultDto> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async uploadFileForDeliveryNote(id: number, deliveryNoteFileDto: DeliveryNoteFileDto): Promise<DeliveryNote> {
    const formData: FormData = new FormData();
    formData.append('file', deliveryNoteFileDto.file);
    const url = `/delivery-notes/${id}/file`;
    const axiosResponse: AxiosResponse<DeliveryNote> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async cancelDeliveryNote(id: number): Promise<DeliveryNote> {
    const url = `/delivery-notes/${id}/cancel`;
    const axiosResponse: AxiosResponse<DeliveryNote> = await httpClient.put(url);
    return axiosResponse.data;
  }

  // DELIVERY NOTE PRODUCTS

  public async getDeliveryNoteProducts(organizationId: number, storeId: number, productId: number): Promise<DeliveryNoteProduct[]> {
    let url = `/delivery-note-products?organizationId=${organizationId}`;
    if (storeId > 0) {
      url += `&storeId=${storeId}`;
    }
    if (productId > 0) {
      url += `&productId=${productId}`;
    }
    const axiosResponse: AxiosResponse<DeliveryNoteProduct[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  // EXPIRATIONS

  public async getExpirations(startDate: Date | null, endDate: Date | null, organizationId: number, storeId?: number): Promise<Expiration[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (startDate) {
      urlSearchParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
    }
    if (endDate) {
      urlSearchParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    }
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    if (storeId && storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    const url = `/expirations?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Expiration[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getExpiration(id: number): Promise<Expiration> {
    const url = `/expirations/${id}`;
    const axiosResponse: AxiosResponse<Expiration> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createExpiration(createExpirationDto: CreateExpirationDto): Promise<Expiration> {
    const url = `/expirations`;
    const axiosResponse: AxiosResponse<Expiration> = await httpClient.post(url, createExpirationDto);
    return axiosResponse.data;
  }

  public async updateExpiration(id: number, expirationPaymentsDtos: ExpirationPaymentDto[]): Promise<Expiration> {
    const url = `/expirations/${id}`;
    const axiosResponse: AxiosResponse<Expiration> = await httpClient.put(url, expirationPaymentsDtos);
    return axiosResponse.data;
  }

  public async deleteExpiration(id: number): Promise<Expiration> {
    const url = `/expirations/${id}`;
    const axiosResponse: AxiosResponse<Expiration> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // FAMILIES

  public async getFamilies(): Promise<Family[]> {
    const url = `/families`;
    const axiosResponse: AxiosResponse<Family[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getFamily(id: number): Promise<Family> {
    const url = `/families/${id}`;
    const axiosResponse: AxiosResponse<Family> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createFamily(familyDto: FamilyDto): Promise<Family> {
    const url = `/families`;
    const axiosResponse: AxiosResponse<Family> = await httpClient.post(url, familyDto);
    return axiosResponse.data;
  }

  public async updateFamily(id: number, familyDto: FamilyDto): Promise<Family> {
    const url = `/families/${id}`;
    const axiosResponse: AxiosResponse<Family> = await httpClient.put(url, familyDto);
    return axiosResponse.data;
  }

  public async deleteFamily(id: number): Promise<Family> {
    const url = `/families/${id}`;
    const axiosResponse: AxiosResponse<Family> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // INTERNAL EAN

  public async getInternalEan(productId: number, organizationId: number): Promise<InternalEan> {
    const url = `/products/${productId}/internal-ean?organizationId=${organizationId}`;
    const axiosResponse: AxiosResponse<InternalEan> = await httpClient.get(url);
    return axiosResponse.data;
  }

  // INVENTORIES

  public async getInventories(organizationId: number, storeId: number, startDate: Date | null, endDate: Date | null): Promise<Inventory[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    if (storeId && storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    if (startDate) {
      urlSearchParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
    }
    if (endDate) {
      urlSearchParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    }
    const url = `/inventories?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Inventory[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getInventory(id: number): Promise<Inventory> {
    const url = `/inventories/${id}`;
    const axiosResponse: AxiosResponse<Inventory> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createInventory(inventoryDto: InventoryDto): Promise<Inventory> {
    const url = `/inventories`;
    const axiosResponse: AxiosResponse<Inventory> = await httpClient.post(url, inventoryDto);
    return axiosResponse.data;
  }

  public async saveInventory(id: number, updateInventoryDto: UpdateInventoryDto): Promise<Inventory> {
    const url = `/inventories/${id}/save`;
    const axiosResponse: AxiosResponse<Inventory> = await httpClient.put(url, updateInventoryDto);
    return axiosResponse.data;
  }

  public async fixInventory(id: number, inventoryDto: InventoryDto): Promise<Inventory> {
    const url = `/inventories/${id}/fix-incidence`;
    const axiosResponse: AxiosResponse<Inventory> = await httpClient.put(url, inventoryDto);
    return axiosResponse.data;
  }

  public async deleteInventory(id: number): Promise<Inventory> {
    const url = `/inventories/${id}`;
    const axiosResponse: AxiosResponse<Inventory> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async updateDraftInventory(id: number, inventoryDto: InventoryDto): Promise<Inventory> {
    const url = `/inventories/${id}/draft`;
    const axiosResponse: AxiosResponse<Inventory> = await httpClient.put(url, inventoryDto);
    return axiosResponse.data;
  }

  public async getInventoryFilters(getFiltersInventory: GetFiltersInventory): Promise<InventoryFilters> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('storeId', getFiltersInventory.storeId.toString());
    if (getFiltersInventory.brandId && getFiltersInventory.brandId > 0) {
      urlSearchParams.append('brandId', getFiltersInventory.brandId.toString());
    }
    if (getFiltersInventory.collectionId && getFiltersInventory.collectionId > 0) {
      urlSearchParams.append('collectionId', getFiltersInventory.collectionId.toString());
    }
    if (getFiltersInventory.categoryId && getFiltersInventory.categoryId > 0) {
      urlSearchParams.append('categoryId', getFiltersInventory.categoryId.toString());
    }
    if (getFiltersInventory.subCategoryId && getFiltersInventory.subCategoryId > 0) {
      urlSearchParams.append('subCategoryId', getFiltersInventory.subCategoryId.toString());
    }
    const url = `/inventories/filters?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<InventoryFilters> = await httpClient.get(url);
    return axiosResponse.data;
  }

  // INVOICES

  public async getInvoices(startDate: Date | null, endDate: Date | null, organizationId: number, storeId?: number): Promise<Invoice[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (startDate) {
      urlSearchParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
    }
    if (endDate) {
      urlSearchParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    }
    urlSearchParams.append('organizationId', organizationId.toString());
    if (storeId && storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    const url = `/invoices?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Invoice[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getInvoice(id: number): Promise<Invoice> {
    const url = `/invoices/${id}`;
    const axiosResponse: AxiosResponse<Invoice> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createInvoice(invoiceDto: InvoiceDto): Promise<Invoice> {
    const url = `/invoices`;
    const axiosResponse: AxiosResponse<Invoice> = await httpClient.post(url, invoiceDto);
    return axiosResponse.data;
  }

  public async updateInvoice(id: number, invoiceDto: InvoiceDto): Promise<Invoice> {
    const url = `/invoices/${id}`;
    const axiosResponse: AxiosResponse<Invoice> = await httpClient.put(url, invoiceDto);
    return axiosResponse.data;
  }

  public async deleteInvoice(id: number): Promise<Invoice> {
    const url = `/invoices/${id}`;
    const axiosResponse: AxiosResponse<Invoice> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async updateInvoiceNotes(id: number, invoiceNotesDto: InvoiceNotesDto): Promise<Invoice> {
    const url = `/invoices/${id}/notes`;
    const axiosResponse: AxiosResponse<Invoice> = await httpClient.put(url, invoiceNotesDto);
    return axiosResponse.data;
  }

  public async getInvoicePdf(id: number): Promise<Buffer> {
    const url = `/invoices/${id}/pdf`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, { responseType: 'arraybuffer' });
    return axiosResponse.data;
  }

  public async getInvoiceXml(id: number): Promise<Buffer> {
    const url = `/invoices/${id}/xml`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, { responseType: 'arraybuffer' });
    return axiosResponse.data;
  }

  // NOTIFICATIONS

  public async getNotifications(): Promise<Notification[]> {
    const url = `/notifications`;
    const axiosResponse: AxiosResponse<Notification[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getUnreadNotifications(): Promise<Notification[]> {
    const url = `/notifications/unread`;
    const axiosResponse: AxiosResponse<Notification[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getTotalNumberOfUnreadNotifications(): Promise<TotalUnreadNotifications> {
    const url = `/notifications/total/unread`;
    const axiosResponse: AxiosResponse<TotalUnreadNotifications> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async markNotificationAsRead(id: number): Promise<Notification> {
    const url = `/notifications/read/${id}`;
    const axiosResponse: AxiosResponse<Notification> = await httpClient.put(url);
    return axiosResponse.data;
  }

  public async markAllNotificationsAsRead(markAllNotificationsAsReadDto: MarkAllNotificationsAsReadDto): Promise<{ raw: any; affected?: number; generatedMaps: any[] }> {
    const url = `/notifications/read-all`;
    const axiosResponse: AxiosResponse<{ raw: any; affected?: number; generatedMaps: any[] }> = await httpClient.put(url, markAllNotificationsAsReadDto);
    return axiosResponse.data;
  }

  // ONLINE STORES

  public async getOnlineStores(organizationId: number | null): Promise<OnlineStore[]> {
    let url = `/online-stores`;
    if (organizationId && organizationId > 0) {
      url += `?organizationId=${organizationId}`;
    }
    const axiosResponse: AxiosResponse<OnlineStore[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getOnlineStoresWithStock(organizationId: number | null): Promise<OnlineStore[]> {
    let url = `/online-stores/with-stock`;
    if (organizationId && organizationId > 0) {
      url += `?organizationId=${organizationId}`;
    }
    const axiosResponse: AxiosResponse<OnlineStore[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getOnlineStore(id: number): Promise<OnlineStore> {
    const url = `/online-stores/${id}`;
    const axiosResponse: AxiosResponse<OnlineStore> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createOnlineStore(onlineStoreDto: OnlineStoreDto): Promise<OnlineStore> {
    const url = `/online-stores`;
    const axiosResponse: AxiosResponse<OnlineStore> = await httpClient.post(url, onlineStoreDto);
    return axiosResponse.data;
  }

  public async updateOnlineStore(id: number, onlineStoreDto: OnlineStoreDto): Promise<OnlineStore> {
    const url = `/online-stores/${id}`;
    const axiosResponse: AxiosResponse<OnlineStore> = await httpClient.put(url, onlineStoreDto);
    return axiosResponse.data;
  }

  public async updateOnlineStoreStock(id: number, updateOnlineStoreStock: UpdateOnlineStoreStock): Promise<OnlineStoreStock> {
    const url = `/online-stores/${id}/stock`;
    const axiosResponse: AxiosResponse<OnlineStoreStock> = await httpClient.put(url, updateOnlineStoreStock);
    return axiosResponse.data;
  }

  public async deleteOnlineStore(id: number): Promise<OnlineStore> {
    const url = `/online-stores/${id}`;
    const axiosResponse: AxiosResponse<OnlineStore> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async updateOnlineStock(updateOnlineStock: UpdateOnlineStock): Promise<boolean> {
    const url = `/online-stores/update-online-stock`;
    const axiosResponse: AxiosResponse<boolean> = await httpClient.put(url, updateOnlineStock);
    return axiosResponse.data;
  }

  // ONLINE STORE SYNCHRONIZATIONS

  public async getOnlineStoreSynchronizations(): Promise<OnlineStoreSynchronization[]> {
    const url = `/online-store-synchronizations`;
    const axiosResponse: AxiosResponse<OnlineStoreSynchronization[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getOnlineStoreSynchronization(id: number): Promise<OnlineStoreSynchronization> {
    const url = `/online-store-synchronizations/${id}`;
    const axiosResponse: AxiosResponse<OnlineStoreSynchronization> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async deleteOnlineStoreSynchronization(id: number): Promise<OnlineStoreSynchronization> {
    const url = `/online-store-synchronizations/${id}`;
    const axiosResponse: AxiosResponse<OnlineStoreSynchronization> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // ORGANIZATIONS

  public async getOrganizations(): Promise<Organization[]> {
    const url = `/organizations`;
    const axiosResponse: AxiosResponse<Organization[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getOrganization(id: number): Promise<Organization> {
    const url = `/organizations/${id}`;
    const axiosResponse: AxiosResponse<Organization> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createOrganization(organizationDto: OrganizationDto): Promise<Organization> {
    const url = `/organizations`;
    const axiosResponse: AxiosResponse<Organization> = await httpClient.post(url, organizationDto);
    return axiosResponse.data;
  }

  public async addUserToOrganization(addUserToOrganizationDto: AddUserToOrganizationDto): Promise<void> {
    const url = `/organizations/user`;
    await httpClient.put(url, addUserToOrganizationDto);
  }

  public async updateOrganization(id: number, organizationDto: OrganizationDto): Promise<Organization> {
    const url = `/organizations/${id}`;
    const axiosResponse: AxiosResponse<Organization> = await httpClient.put(url, organizationDto);
    return axiosResponse.data;
  }

  public async deleteOrganization(id: number): Promise<Organization> {
    const url = `/organizations/${id}`;
    const axiosResponse: AxiosResponse<Organization> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async deleteUserInOrganization(userInOrganizationDto: UserInOrganizationDto): Promise<Organization> {
    const url = `/organizations/user`;
    const axiosResponse: AxiosResponse<Organization> = await httpClient.delete(url, { data: userInOrganizationDto });
    return axiosResponse.data;
  }

  public async getOrganizationUsers(id: number): Promise<User[]> {
    const url = `/organizations/${id}/users`;
    const axiosResponse: AxiosResponse<User[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  // ORGANIZATIONS CLIENTS

  public async getOrganizationClients(organizationId: number): Promise<OrganizationClient[]> {
    const url = `/organizations-clients?organizationId=${organizationId}`;
    const axiosResponse: AxiosResponse<OrganizationClient[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getOrganizationClient(id: number): Promise<OrganizationClient> {
    const url = `/organizations-clients/${id}`;
    const axiosResponse: AxiosResponse<OrganizationClient> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createOrganizationClient(organizationClientDto: OrganizationClientDto): Promise<OrganizationClient> {
    const url = `/organizations-clients`;
    const axiosResponse: AxiosResponse<OrganizationClient> = await httpClient.post(url, organizationClientDto);
    return axiosResponse.data;
  }

  public async updateOrganizationClient(id: number, acceptOrganizationRelationDto: AcceptOrganizationRelationDto): Promise<OrganizationClient> {
    const url = `/organizations-clients/${id}`;
    const axiosResponse: AxiosResponse<OrganizationClient> = await httpClient.put(url, acceptOrganizationRelationDto);
    return axiosResponse.data;
  }

  public async deleteOrganizationClient(id: number): Promise<OrganizationClient> {
    const url = `/organizations-clients/${id}`;
    const axiosResponse: AxiosResponse<OrganizationClient> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // PAYROLLS

  public async createPayroll(payrollDto: PayrollDto): Promise<Payroll> {
    const url = `/payrolls`;
    const formData: FormData = new FormData();
    formData.append('name', payrollDto.name);
    formData.append('year', payrollDto.year.toString());
    formData.append('month', payrollDto.month.toString());
    formData.append('userId', payrollDto.userId.toString());
    formData.append('file', payrollDto.file!);
    const axiosResponse: AxiosResponse<Payroll> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async updatePayroll(id: number, payrollDto: PayrollDto): Promise<Payroll> {
    const url = `/payrolls/${id}`;
    const formData: FormData = new FormData();
    formData.append('name', payrollDto.name);
    formData.append('year', payrollDto.year.toString());
    formData.append('month', payrollDto.month.toString());
    formData.append('userId', payrollDto.userId.toString());
    if (payrollDto.file) {
      formData.append('file', payrollDto.file);
    }
    const axiosResponse: AxiosResponse<Payroll> = await httpClient.put(url, formData);
    return axiosResponse.data;
  }

  public async getPayrolls(): Promise<Payroll[]> {
    const url = `/payrolls`;
    const axiosResponse: AxiosResponse<Payroll[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getPayroll(id: number): Promise<Payroll> {
    const url = `/payrolls/${id}`;
    const axiosResponse: AxiosResponse<Payroll> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async downloadPayroll(id: number): Promise<Buffer> {
    const url = `/payrolls/${id}/download`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, { responseType: 'arraybuffer' });
    return axiosResponse.data;
  }

  public async deletePayroll(id: number): Promise<Payroll> {
    const url = `/payrolls/${id}`;
    const axiosResponse: AxiosResponse<Payroll> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // PAYMENT METHODS

  public async createPaymentMethod(paymentMethodDto: PaymentMethodDto): Promise<PaymentMethod> {
    const url = `/payment-methods`;
    const axiosResponse: AxiosResponse<PaymentMethod> = await httpClient.post(url, paymentMethodDto);
    return axiosResponse.data;
  }

  public async updatePaymentMethod(id: number, paymentMethodDto: PaymentMethodDto): Promise<PaymentMethod> {
    const url = `/payment-methods/${id}`;
    const axiosResponse: AxiosResponse<PaymentMethod> = await httpClient.put(url, paymentMethodDto);
    return axiosResponse.data;
  }

  public async deletePaymentMethod(id: number): Promise<PaymentMethod> {
    const url = `/payment-methods/${id}`;
    const axiosResponse: AxiosResponse<PaymentMethod> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // PRODUCTS

  public async getProducts(data: { query?: string; sku?: string }): Promise<Product[]> {
    let url = `/products`;
    if (data.query) {
      url += `?query=${data.query}`;
    } else if (data.sku) {
      url += `?sku=${data.sku}`;
    }
    const axiosResponse: AxiosResponse<Product[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getPaginatedProducts(getPaginatedProducts: GetPaginatedProducts): Promise<PaginatedDto<Product>> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (getPaginatedProducts.page && getPaginatedProducts.page > 0) {
      urlSearchParams.append('page', getPaginatedProducts.page.toString());
    }
    if (getPaginatedProducts.limit && getPaginatedProducts.limit > 0) {
      urlSearchParams.append('limit', getPaginatedProducts.limit.toString());
    }
    if (getPaginatedProducts.orderField && getPaginatedProducts.orderField.length > 0) {
      urlSearchParams.append('orderField', getPaginatedProducts.orderField);
    }
    if (getPaginatedProducts.order && getPaginatedProducts.order.length > 0) {
      urlSearchParams.append('order', getPaginatedProducts.order);
    }
    if (getPaginatedProducts.query && getPaginatedProducts.query.length > 0) {
      urlSearchParams.append('query', getPaginatedProducts.query);
    }
    if (getPaginatedProducts.brandIds && getPaginatedProducts.brandIds.length > 0) {
      urlSearchParams.append('brandIds', getPaginatedProducts.brandIds.join(','));
    }
    if (getPaginatedProducts.collectionIds && getPaginatedProducts.collectionIds.length > 0) {
      urlSearchParams.append('collectionIds', getPaginatedProducts.collectionIds.join(','));
    }
    if (getPaginatedProducts.familyIds && getPaginatedProducts.familyIds.length > 0) {
      urlSearchParams.append('familyIds', getPaginatedProducts.familyIds.join(','));
    }
    if (getPaginatedProducts.subFamilyIds && getPaginatedProducts.subFamilyIds.length > 0) {
      urlSearchParams.append('subFamilyIds', getPaginatedProducts.subFamilyIds.join(','));
    }
    if (getPaginatedProducts.enabled !== null) {
      urlSearchParams.append('enabled', getPaginatedProducts.enabled.toString());
    }
    if (getPaginatedProducts.editable !== null) {
      urlSearchParams.append('editable', getPaginatedProducts.editable.toString());
    }
    if (getPaginatedProducts.images !== null) {
      urlSearchParams.append('images', getPaginatedProducts.images.toString());
    }
    const url = `/products/paginated?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<PaginatedDto<Product>> = await httpClient.get<PaginatedDto<Product>>(url);
    return axiosResponse.data;
  }

  public async exportProducts(getProducts: GetProducts): Promise<Buffer> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (getProducts.orderField && getProducts.orderField.length > 0) {
      urlSearchParams.append('orderField', getProducts.orderField);
    }
    if (getProducts.order && getProducts.order.length > 0) {
      urlSearchParams.append('order', getProducts.order);
    }
    if (getProducts.query && getProducts.query.length > 0) {
      urlSearchParams.append('query', getProducts.query);
    }
    if (getProducts.brandIds && getProducts.brandIds.length > 0) {
      urlSearchParams.append('brandIds', getProducts.brandIds.join(','));
    }
    if (getProducts.collectionIds && getProducts.collectionIds.length > 0) {
      urlSearchParams.append('collectionIds', getProducts.collectionIds.join(','));
    }
    if (getProducts.familyIds && getProducts.familyIds.length > 0) {
      urlSearchParams.append('familyIds', getProducts.familyIds.join(','));
    }
    if (getProducts.subFamilyIds && getProducts.subFamilyIds.length > 0) {
      urlSearchParams.append('subFamilyIds', getProducts.subFamilyIds.join(','));
    }
    if (getProducts.enabled !== null) {
      urlSearchParams.append('enabled', getProducts.enabled.toString());
    }
    if (getProducts.editable !== null) {
      urlSearchParams.append('editable', getProducts.editable.toString());
    }
    if (getProducts.images !== null) {
      urlSearchParams.append('images', getProducts.images.toString());
    }
    const url = `/products/export?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get<Buffer>(url, {
      responseType: 'arraybuffer',
    });
    return axiosResponse.data;
  }

  public async getProduct(id: number): Promise<Product> {
    const url = `/products/${id}`;
    const axiosResponse: AxiosResponse<Product> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async searchProducts(elasticSearchRequest: ElasticSearchRequest): Promise<PaginatedDto<Product>> {
    const url = `/products/search`;
    const axiosResponse: AxiosResponse<PaginatedDto<Product>> = await httpClient.post(url, elasticSearchRequest);
    return axiosResponse.data;
  }

  public async updateProduct(id: number, updateProductDto: UpdateProductDto): Promise<Product> {
    const url = `/products/${id}`;
    const axiosResponse: AxiosResponse<Product> = await httpClient.put(url, updateProductDto);
    return axiosResponse.data;
  }

  public async getProductLabel(id: number, organizationId: number, storeId: number): Promise<Buffer> {
    const url = `/products/${id}/label?organizationId=${organizationId}&storeId=${storeId}`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, { responseType: 'arraybuffer' });
    return axiosResponse.data;
  }

  public async getProductsLabels(getProductsTags: GetProductsTags): Promise<Buffer> {
    const url = `/products/labels`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.post(url, getProductsTags, { responseType: 'arraybuffer' });
    return axiosResponse.data;
  }

  public async updateProductsByCsv(importProductsCsvDto: ImportProductsCsvDto): Promise<Product[]> {
    const formData = new FormData();
    formData.append('organizationId', importProductsCsvDto.organizationId.toString());
    formData.append('file', importProductsCsvDto.file);
    const url = '/products/update-csv';
    const axiosResponse: AxiosResponse<Product[]> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async getImportCsvColumnsProduct(): Promise<CsvColumnDto[]> {
    const url = `/products/import-csv/columns`;
    const axiosResponse: AxiosResponse<CsvColumnDto[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async importCsvProducts(importCsvDto: ImportCsvDto): Promise<void> {
    const formData: FormData = new FormData();
    formData.append('file', importCsvDto.file);
    formData.append('columns', JSON.stringify(importCsvDto.columns));
    const url = `/products/import-csv`;
    const axiosResponse: AxiosResponse<void> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async deleteProduct(id: number): Promise<void> {
    const url = `/products/${id}`;
    const axiosResponse: AxiosResponse<void> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // PRODUCT HISTORY

  public async getProductHistory(productId: number, organizationId: number, storeId: number): Promise<ProductHistory[]> {
    let url = `/product-history/${productId}?organizationId=${organizationId}`;
    if (storeId && storeId > 0) {
      url += `&storeId=${storeId}`;
    }
    const axiosResponse: AxiosResponse<ProductHistory[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  // PRODUCT IMAGES

  public async uploadProductImage(id: number, image: File): Promise<ProductImage> {
    const url = `/product-images/${id}`;
    const formData = new FormData();
    formData.append('image', image);
    const axiosResponse: AxiosResponse<ProductImage> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async uploadProductImages(id: number, images: File[]): Promise<ProductImage[]> {
    const url = `/product-images/${id}/multiple`;
    const formData = new FormData();
    for (const image of images) {
      formData.append('images', image);
    }
    const axiosResponse: AxiosResponse<ProductImage[]> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async deleteProductImage(id: number): Promise<ProductImage> {
    const url = `/product-images/${id}`;
    const axiosResponse: AxiosResponse<ProductImage> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async uploadProductImageFromLink(productId: number, fromLinkDto: FromLinkDto): Promise<ProductImage> {
    const url = `/product-images/${productId}/from-link`;
    const axiosResponse: AxiosResponse<ProductImage> = await httpClient.post(url, fromLinkDto);
    return axiosResponse.data;
  }

  public async uploadProductImageFromBase64(productId: number, fromBase64Dto: FromBase64Dto): Promise<ProductImage> {
    const url = `/product-images/${productId}/from-base64`;
    const axiosResponse: AxiosResponse<ProductImage> = await httpClient.post(url, fromBase64Dto);
    return axiosResponse.data;
  }

  public async setProductImageAsMain(id: number): Promise<ProductImage[]> {
    const url = `/product-images/${id}`;
    const axiosResponse: AxiosResponse<ProductImage[]> = await httpClient.put(url);
    return axiosResponse.data;
  }

  // PURCHASE ORDERS

  public async getPurchaseOrders(getPurchaseOrdersQuery: GetPurchaseOrdersQuery): Promise<PurchaseOrder[]> {
    const urlWithParams: URLSearchParams = new URLSearchParams();
    urlWithParams.append('organizationId', getPurchaseOrdersQuery.organizationId.toString());
    if (getPurchaseOrdersQuery.relatedStoreId) {
      urlWithParams.append('relatedStoreId', getPurchaseOrdersQuery.relatedStoreId.toString());
    }
    urlWithParams.append('startDate', moment(getPurchaseOrdersQuery.startDate).format('YYYY-MM-DD'));
    urlWithParams.append('endDate', moment(getPurchaseOrdersQuery.endDate).format('YYYY-MM-DD'));
    const url = `/purchase-orders?${urlWithParams.toString()}`;
    const axiosResponse: AxiosResponse<PurchaseOrder[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getPurchaseOrder(id: number): Promise<PurchaseOrder> {
    const url = `/purchase-orders/${id}`;
    const axiosResponse: AxiosResponse<PurchaseOrder> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createPurchaseOrder(purchaseOrderDto: PurchaseOrderDto): Promise<PurchaseOrder> {
    const url = `/purchase-orders`;
    const axiosResponse: AxiosResponse<PurchaseOrder> = await httpClient.post(url, purchaseOrderDto);
    return axiosResponse.data;
  }

  public async updatePurchaseOrder(id: number, updatePurchaseOrderDto: UpdatePurchaseOrderDto): Promise<PurchaseOrder> {
    const url = `/purchase-orders/${id}`;
    const axiosResponse: AxiosResponse<PurchaseOrder> = await httpClient.put(url, updatePurchaseOrderDto);
    return axiosResponse.data;
  }

  public async cancelPurchaseOrder(id: number): Promise<PurchaseOrder> {
    const url = `/purchase-orders/${id}/cancel`;
    const axiosResponse: AxiosResponse<PurchaseOrder> = await httpClient.put(url);
    return axiosResponse.data;
  }

  public async purchaseOrderRevision(id: number, purchaseOrderRevisionDto: PurchaseOrderRevisionDto): Promise<PurchaseOrder> {
    const url = `/purchase-orders/${id}/revision`;
    const axiosResponse: AxiosResponse<PurchaseOrder> = await httpClient.put(url, purchaseOrderRevisionDto);
    return axiosResponse.data;
  }

  public async deletePurchaseOrder(id: number): Promise<PurchaseOrder> {
    const url = `/purchase-orders/${id}`;
    const axiosResponse: AxiosResponse<PurchaseOrder> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async getPaginatedStockWholeSaler(getPaginatedStockWholesaler: GetPaginatedStockWholesaler): Promise<PaginatedDto<Stock>> {
    const myUrlWithParams: URLSearchParams = new URLSearchParams();
    if (getPaginatedStockWholesaler.organizationId && getPaginatedStockWholesaler.organizationId > 0) {
      myUrlWithParams.append('organizationId', getPaginatedStockWholesaler.organizationId.toString());
    }
    if (getPaginatedStockWholesaler.stockOrganizationId && getPaginatedStockWholesaler.stockOrganizationId > 0) {
      myUrlWithParams.append('stockOrganizationId', getPaginatedStockWholesaler.stockOrganizationId.toString());
    }
    if (getPaginatedStockWholesaler.brandIds && getPaginatedStockWholesaler.brandIds.length > 0) {
      myUrlWithParams.append('brandIds', getPaginatedStockWholesaler.brandIds.join(','));
    }
    if (getPaginatedStockWholesaler.collectionIds && getPaginatedStockWholesaler.collectionIds.length > 0) {
      myUrlWithParams.append('collectionIds', getPaginatedStockWholesaler.collectionIds.join(','));
    }
    if (getPaginatedStockWholesaler.page && getPaginatedStockWholesaler.page > 0) {
      myUrlWithParams.append('page', getPaginatedStockWholesaler.page.toString());
    }
    if (getPaginatedStockWholesaler.limit && getPaginatedStockWholesaler.limit > 0) {
      myUrlWithParams.append('limit', getPaginatedStockWholesaler.limit.toString());
    }
    if (getPaginatedStockWholesaler.orderField && getPaginatedStockWholesaler.orderField.length > 0) {
      myUrlWithParams.append('orderField', getPaginatedStockWholesaler.orderField);
    }
    if (getPaginatedStockWholesaler.order && getPaginatedStockWholesaler.order.length > 0) {
      myUrlWithParams.append('order', getPaginatedStockWholesaler.order);
    }
    if (getPaginatedStockWholesaler.query && getPaginatedStockWholesaler.query.length > 0) {
      myUrlWithParams.append('query', getPaginatedStockWholesaler.query);
    }
    const url = `/purchase-orders/paginated-stock?${myUrlWithParams.toString()}`;
    const axiosResponse: AxiosResponse<PaginatedDto<Stock>> = await httpClient.get(url);
    return axiosResponse.data;
  }

  // SALES

  public async getSales(getSalesQuery: GetSalesQuery): Promise<Sale[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', getSalesQuery.organizationId.toString());
    if (getSalesQuery.startDate) {
      urlSearchParams.append('startDate', moment(getSalesQuery.startDate).utc().format('YYYY-MM-DD HH:mm'));
    }
    if (getSalesQuery.endDate) {
      urlSearchParams.append('endDate', moment(getSalesQuery.endDate).utc().format('YYYY-MM-DD HH:mm'));
    }
    if (getSalesQuery.storeIds.length > 0) {
      urlSearchParams.append('storeIds', getSalesQuery.storeIds.join(','));
    }
    if (getSalesQuery.internalId) {
      urlSearchParams.append('internalId', getSalesQuery.internalId);
    }
    const url = `/sales?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Sale[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createSale(saleDto: SaleDto): Promise<Sale> {
    const url = `/sales`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.post(url, saleDto);
    return axiosResponse.data;
  }

  public async getSalesPdf(getSalesQuery: GetSalesQuery): Promise<AxiosResponse> {
    const urlWithParams: URLSearchParams = new URLSearchParams();
    urlWithParams.append('organizationId', getSalesQuery.organizationId.toString());
    if (getSalesQuery.startDate) {
      urlWithParams.append('startDate', moment(getSalesQuery.startDate).utc().format('YYYY-MM-DD HH:mm'));
    }
    if (getSalesQuery.endDate) {
      urlWithParams.append('endDate', moment(getSalesQuery.endDate).utc().format('YYYY-MM-DD HH:mm'));
    }
    if (getSalesQuery.storeIds.length > 0) {
      urlWithParams.append('storeIds', getSalesQuery.storeIds.join(','));
    }
    const url = `/sales/pdf?${urlWithParams.toString()}`;
    return httpClient.get(url, { responseType: 'arraybuffer' });
  }

  public async updateSale(id: number, updateSaleDto: UpdateSaleDto): Promise<Sale> {
    const url = `/sales/${id}`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.put(url, updateSaleDto);
    return axiosResponse.data;
  }

  public async cancelSale(id: number, cancelSaleDto: CancelSaleDto): Promise<Sale> {
    const url = `/sales/${id}/cancel`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.put(url, cancelSaleDto);
    return axiosResponse.data;
  }

  public async updateOnlineStatusSale(id: number, updateOnlineStatusSalesDto: UpdateOnlineStatusSaleDto): Promise<Sale> {
    const url = `/sales/${id}/online-status`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.put(url, updateOnlineStatusSalesDto);
    return axiosResponse.data;
  }

  public async getSale(id: number): Promise<Sale> {
    const url = `/sales/${id}`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getCustomerSales(id: number): Promise<Sale[]> {
    const url = `/sales/customer/${id}`;
    const axiosResponse: AxiosResponse<Sale[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async updateSaleNotes(id: number, notes: string | null): Promise<Sale> {
    const url = `/sales/${id}/notes`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.put(url, { notes });
    return axiosResponse.data;
  }

  public async makeSaleReturn(returnDto: ReturnDto): Promise<Sale[]> {
    const url = `/sales/return-sale`;
    const axiosResponse: AxiosResponse<Sale[]> = await httpClient.post(url, returnDto);
    return axiosResponse.data;
  }

  public async makeOnlineSaleReturn(returnDto: ReturnDto): Promise<Sale[]> {
    const url = `/sales/return-online-sale`;
    const axiosResponse: AxiosResponse<Sale[]> = await httpClient.post(url, returnDto);
    return axiosResponse.data;
  }

  public async deleteSale(id: number): Promise<Sale> {
    const url = `/sales/${id}`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async getSaleTicket(id: number): Promise<Buffer> {
    const url = `/sales/${id}/ticket`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, { responseType: 'arraybuffer' });
    return axiosResponse.data;
  }

  public async getSaleGiftTicket(id: number, productIds: number[] = [], manualProductIds: number[] = []): Promise<Buffer> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (productIds.length > 0) {
      urlSearchParams.append('productIds', productIds.join(','));
    }
    if (manualProductIds.length > 0) {
      urlSearchParams.append('manualProductIds', manualProductIds.join(','));
    }
    const url = `/sales/${id}/gift-ticket?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, { responseType: 'arraybuffer' });
    return axiosResponse.data;
  }

  public async sendSaleTicketByEmail(id: number): Promise<boolean> {
    const url = `/sales/${id}/ticket/email`;
    const axiosResponse: AxiosResponse<boolean> = await httpClient.post(url);
    return axiosResponse.data;
  }

  public async updateSaleCustomer(id: number, reassignSaleToCustomerDto: ReassignSaleToCustomerDto): Promise<Sale> {
    const url = `/sales/${id}/reassign`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.put(url, reassignSaleToCustomerDto);
    return axiosResponse.data;
  }

  public async updateSalePaymentMethod(id: number, updateSalePaymentMethodDto: UpdateSalePaymentMethodDto): Promise<Sale> {
    const url = `/sales/${id}/sale-payment-method`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.put(url, updateSalePaymentMethodDto);
    return axiosResponse.data;
  }

  public async updateBookingPayment(id: number, updateBookingPaymentDto: UpdateBookingPaymentDto): Promise<Sale> {
    const url = `/sales/${id}/booking-payment`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.put(url, updateBookingPaymentDto);
    return axiosResponse.data;
  }

  public async getPreviosNextSale(id: number): Promise<PreviosNextSaleDto> {
    const url = `/sales/${id}/previous-next-sale`;
    const axiosResponse: AxiosResponse<PreviosNextSaleDto> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getImportCsvColumnsSales(): Promise<CsvColumnDto[]> {
    const url = `/sales/import-csv/columns`;
    const axiosResponse: AxiosResponse<CsvColumnDto[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async importCsvSales(importCsvDto: ImportCsvDto): Promise<ImportCsvResultDto> {
    const formData: FormData = new FormData();
    formData.append('file', importCsvDto.file);
    formData.append('storeId', importCsvDto.storeId!.toString());
    formData.append('columns', JSON.stringify(importCsvDto.columns));
    const url = `/sales/import-csv`;
    const axiosResponse: AxiosResponse<ImportCsvResultDto> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async replaceManualSaleProduct(replaceManualSaleProductDto: ReplaceManualSaleProductDto): Promise<Sale> {
    const url = `/sales/replace-manual-sale-product`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.post(url, replaceManualSaleProductDto);
    return axiosResponse.data;
  }

  public async fulfillShopifyOrder(saleId: number): Promise<Sale> {
    const url = `/sales/${saleId}/fulfill-shopify-order`;
    const axiosResponse: AxiosResponse<Sale> = await httpClient.post(url);
    return axiosResponse.data;
  }

  // STOCK

  public async getStock(getStock: GetStock): Promise<Stock[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', getStock.organizationId.toString());
    if (getStock.storeIds && getStock.storeIds.length > 0) {
      urlSearchParams.append('storeIds', getStock.storeIds.join(','));
    }
    if (getStock.brandIds && getStock.brandIds.length > 0) {
      urlSearchParams.append('brandIds', getStock.brandIds.join(','));
    }
    if (getStock.categoryIds && getStock.categoryIds.length > 0) {
      urlSearchParams.append('categoryIds', getStock.categoryIds.join(','));
    }
    if (getStock.orderField && getStock.orderField.length > 0) {
      urlSearchParams.append('orderField', getStock.orderField);
    }
    if (getStock.order && getStock.order.length > 0) {
      urlSearchParams.append('order', getStock.order);
    }
    if (getStock.query && getStock.query.length > 0) {
      urlSearchParams.append('query', getStock.query);
    }
    if (getStock.inStock !== null) {
      urlSearchParams.append('inStock', getStock.inStock.toString());
    }
    if (getStock.active !== null) {
      urlSearchParams.append('active', getStock.active.toString());
    }
    if (getStock.online !== null) {
      urlSearchParams.append('online', getStock.online.toString());
    }
    const url = `/stock?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Stock[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async exportStock(getStock: GetStock): Promise<Buffer> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', getStock.organizationId.toString());
    if (getStock.storeIds && getStock.storeIds.length > 0) {
      urlSearchParams.append('storeIds', getStock.storeIds.join(','));
    }
    if (getStock.brandIds && getStock.brandIds.length > 0) {
      urlSearchParams.append('brandIds', getStock.brandIds.join(','));
    }
    if (getStock.categoryIds && getStock.categoryIds.length > 0) {
      urlSearchParams.append('categoryIds', getStock.categoryIds.join(','));
    }
    if (getStock.orderField && getStock.orderField.length > 0) {
      urlSearchParams.append('orderField', getStock.orderField);
    }
    if (getStock.order && getStock.order.length > 0) {
      urlSearchParams.append('order', getStock.order);
    }
    if (getStock.query && getStock.query.length > 0) {
      urlSearchParams.append('query', getStock.query);
    }
    if (getStock.inStock !== null) {
      urlSearchParams.append('inStock', getStock.inStock.toString());
    }
    if (getStock.active !== null) {
      urlSearchParams.append('active', getStock.active.toString());
    }
    if (getStock.online !== null) {
      urlSearchParams.append('online', getStock.online.toString());
    }
    const url = `/stock/export?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get<Buffer>(url, {
      responseType: 'arraybuffer',
    });
    return axiosResponse.data;
  }

  public async getPreviousNextStock(productId: number, getStock: GetStock, isNext: boolean): Promise<PreviousNextStock> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (getStock.organizationId && getStock.organizationId > 0) {
      urlSearchParams.append('organizationId', getStock.organizationId.toString());
    }
    if (getStock.storeIds && getStock.storeIds.length > 0) {
      urlSearchParams.append('storeIds', getStock.storeIds.join(','));
    }
    if (getStock.brandIds && getStock.brandIds.length > 0) {
      urlSearchParams.append('brandIds', getStock.brandIds.join(','));
    }
    if (getStock.categoryIds && getStock.categoryIds.length > 0) {
      urlSearchParams.append('categoryIds', getStock.categoryIds.join(','));
    }
    if (getStock.orderField && getStock.orderField.length > 0) {
      urlSearchParams.append('orderField', getStock.orderField);
    }
    if (getStock.order && getStock.order.length > 0) {
      urlSearchParams.append('order', getStock.order);
    }
    if (getStock.query && getStock.query.length > 0) {
      urlSearchParams.append('query', getStock.query);
    }
    if (getStock.inStock !== null) {
      urlSearchParams.append('inStock', getStock.inStock.toString());
    }
    if (getStock.active !== null) {
      urlSearchParams.append('active', getStock.active.toString());
    }
    if (getStock.online !== null) {
      urlSearchParams.append('online', getStock.online.toString());
    }
    urlSearchParams.append('isNext', isNext.toString());
    const url = `/stock/previous-next/${productId}?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<PreviousNextStock> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getPaginatedStock(getPaginatedStockExtended: GetPaginatedStockExtended): Promise<PaginatedDto<Stock>> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', getPaginatedStockExtended.organizationId.toString());
    if (getPaginatedStockExtended.storeIds && getPaginatedStockExtended.storeIds.length > 0) {
      urlSearchParams.append('storeIds', getPaginatedStockExtended.storeIds.join(','));
    }
    if (getPaginatedStockExtended.brandIds && getPaginatedStockExtended.brandIds.length > 0) {
      urlSearchParams.append('brandIds', getPaginatedStockExtended.brandIds.join(','));
    }
    if (getPaginatedStockExtended.collectionIds && getPaginatedStockExtended.collectionIds.length > 0) {
      urlSearchParams.append('collectionIds', getPaginatedStockExtended.collectionIds.join(','));
    }
    if (getPaginatedStockExtended.categoryIds && getPaginatedStockExtended.categoryIds.length > 0) {
      urlSearchParams.append('categoryIds', getPaginatedStockExtended.categoryIds.join(','));
    }
    if (getPaginatedStockExtended.page && getPaginatedStockExtended.page > 0) {
      urlSearchParams.append('page', getPaginatedStockExtended.page.toString());
    }
    if (getPaginatedStockExtended.limit && getPaginatedStockExtended.limit > 0) {
      urlSearchParams.append('limit', getPaginatedStockExtended.limit.toString());
    }
    if (getPaginatedStockExtended.orderField && getPaginatedStockExtended.orderField.length > 0) {
      urlSearchParams.append('orderField', getPaginatedStockExtended.orderField);
    }
    if (getPaginatedStockExtended.order && getPaginatedStockExtended.order.length > 0) {
      urlSearchParams.append('order', getPaginatedStockExtended.order);
    }
    if (getPaginatedStockExtended.query && getPaginatedStockExtended.query.length > 0) {
      urlSearchParams.append('query', getPaginatedStockExtended.query);
    }
    if (getPaginatedStockExtended.inStock !== null) {
      urlSearchParams.append('inStock', getPaginatedStockExtended.inStock.toString());
    }
    if (getPaginatedStockExtended.active !== null) {
      urlSearchParams.append('active', getPaginatedStockExtended.active.toString());
    }
    if (getPaginatedStockExtended.online !== null) {
      urlSearchParams.append('online', getPaginatedStockExtended.online.toString());
    }
    const url = `/stock/paginated?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<PaginatedDto<Stock>> = await httpClient.get<PaginatedDto<Stock>>(url);
    return axiosResponse.data;
  }

  public async getStockTotals(getStockTotals: GetStockTotals): Promise<StockTotals> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', getStockTotals.organizationId.toString());
    if (getStockTotals.storeIds && getStockTotals.storeIds.length > 0) {
      urlSearchParams.append('storeIds', getStockTotals.storeIds.join(','));
    }
    if (getStockTotals.brandIds && getStockTotals.brandIds.length > 0) {
      urlSearchParams.append('brandIds', getStockTotals.brandIds.join(','));
    }
    if (getStockTotals.collectionIds && getStockTotals.collectionIds.length > 0) {
      urlSearchParams.append('collectionIds', getStockTotals.collectionIds.join(','));
    }
    if (getStockTotals.categoryIds && getStockTotals.categoryIds.length > 0) {
      urlSearchParams.append('categoryIds', getStockTotals.categoryIds.join(','));
    }
    if (getStockTotals.query && getStockTotals.query.length > 0) {
      urlSearchParams.append('query', getStockTotals.query);
    }
    if (getStockTotals.inStock !== null) {
      urlSearchParams.append('inStock', getStockTotals.inStock.toString());
    }
    if (getStockTotals.active !== null) {
      urlSearchParams.append('active', getStockTotals.active.toString());
    }
    if (getStockTotals.online !== null) {
      urlSearchParams.append('online', getStockTotals.online.toString());
    }
    const url = `/stock/totals?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<StockTotals> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getStockControl(organizationId: number | null, storeId: number | null): Promise<StockControl[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    if (storeId && storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    const url = `/stock/control?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<StockControl[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getPosStock(storeId: number, brandId?: number, categoryId?: number, query?: string): Promise<Stock[]> {
    const params: string[] = [];
    if (brandId && brandId > 0) {
      params.push(`brandId=${brandId}`);
    }
    if (categoryId && categoryId > 0) {
      params.push(`categoryId=${categoryId}`);
    }
    if (query && query.length > 0) {
      params.push(`query=${query}`);
    }
    const url = `/stock/pos/${storeId}${params.length > 0 ? `?${params.join('&')}` : ''}`;
    const axiosResponse: AxiosResponse<Stock[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getRepairStock(storeId: number, brandId: number, collectionId: number, categoryId: number, subCategoryId: number, query: string): Promise<Stock[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (brandId && brandId > 0) {
      urlSearchParams.append('brandId', brandId.toString());
    }
    if (collectionId && collectionId > 0) {
      urlSearchParams.append('collectionId', collectionId.toString());
    }
    if (categoryId && categoryId > 0) {
      urlSearchParams.append('categoryId', categoryId.toString());
    }
    if (subCategoryId && subCategoryId > 0) {
      urlSearchParams.append('subCategoryId', subCategoryId.toString());
    }
    if (query && query.length > 0) {
      urlSearchParams.append('query', query);
    }
    const url = `/stock/repairs/${storeId}?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Stock[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getPaginatedPosStock(getPaginatedStock: GetPaginatedStock): Promise<PaginatedDto<Stock>> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', getPaginatedStock.organizationId.toString());
    urlSearchParams.append('orderField', getPaginatedStock.orderField);
    urlSearchParams.append('order', getPaginatedStock.order);
    urlSearchParams.append('limit', getPaginatedStock.limit.toString());
    urlSearchParams.append('page', getPaginatedStock.page.toString());
    if (getPaginatedStock.query) {
      urlSearchParams.append('query', getPaginatedStock.query);
    }
    if (getPaginatedStock.storeId > 0) {
      urlSearchParams.append('storeId', getPaginatedStock.storeId.toString());
    }
    if (getPaginatedStock.brandId > 0) {
      urlSearchParams.append('brandId', getPaginatedStock.brandId.toString());
    }
    if (getPaginatedStock.categoryId > 0) {
      urlSearchParams.append('categoryId', getPaginatedStock.categoryId.toString());
    }
    const url = `/stock/pos/paginated?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<PaginatedDto<Stock>> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getProductInStores(productId: number): Promise<ProductInStore[]> {
    const url = `/stock/product-in-stores/${productId}`;
    const axiosResponse: AxiosResponse<ProductInStore[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getProductInOrganization(organizationId: number, productId: number): Promise<ProductUnitsInStore[]> {
    const url = `/stock/product-in-organization?organizationId=${organizationId}&productId=${productId}`;
    const axiosResponse: AxiosResponse<ProductUnitsInStore[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getProductStoresStock(productId: number, organizationId: number | null): Promise<ProductStoreStock[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('productId', productId.toString());
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    const url = `/stock/product-stores-stock?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<ProductStoreStock[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async toggleEnableProductStock(data: ChangeEnableProductStock): Promise<ProductInStore[]> {
    const url = `/stock/toggle-enable-product`;
    const axiosResponse: AxiosResponse<ProductInStore[]> = await httpClient.post(url, data);
    return axiosResponse.data;
  }

  public async updateStockByCsv(storeId: number, file: File): Promise<Stock[]> {
    const formData = new FormData();
    formData.append('file', file);
    const url = `/stock/update-stock/${storeId}`;
    const axiosResponse: AxiosResponse<Stock[]> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async updateStock(id: number, updateStockDto: UpdateStockDto): Promise<Stock> {
    const url = `/stock/${id}`;
    const axiosResponse: AxiosResponse<Stock> = await httpClient.put(url, updateStockDto);
    return axiosResponse.data;
  }

  public async getImportCsvColumnsStock(storeId: number): Promise<CsvColumnDto[]> {
    const url = `/stock/import-csv/columns?storeId=${storeId}`;
    const axiosResponse: AxiosResponse<CsvColumnDto[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async importCsvStock(importCsvDto: ImportCsvDto): Promise<ImportCsvResultDto> {
    const formData: FormData = new FormData();
    formData.append('file', importCsvDto.file);
    formData.append('storeId', importCsvDto.storeId!.toString());
    formData.append('columns', JSON.stringify(importCsvDto.columns));
    const url = `/stock/import-csv`;
    const axiosResponse: AxiosResponse<ImportCsvResultDto> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async getStockLabels(getPaginatedStockExtended: GetPaginatedStockExtended): Promise<Buffer> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', getPaginatedStockExtended.organizationId.toString());
    if (getPaginatedStockExtended.storeIds && getPaginatedStockExtended.storeIds.length > 0) {
      urlSearchParams.append('storeIds', getPaginatedStockExtended.storeIds.join(','));
    }
    if (getPaginatedStockExtended.brandIds && getPaginatedStockExtended.brandIds.length > 0) {
      urlSearchParams.append('brandIds', getPaginatedStockExtended.brandIds.join(','));
    }
    if (getPaginatedStockExtended.categoryIds && getPaginatedStockExtended.categoryIds.length > 0) {
      urlSearchParams.append('categoryIds', getPaginatedStockExtended.categoryIds.join(','));
    }
    if (getPaginatedStockExtended.page && getPaginatedStockExtended.page > 0) {
      urlSearchParams.append('page', getPaginatedStockExtended.page.toString());
    }
    if (getPaginatedStockExtended.limit && getPaginatedStockExtended.limit > 0) {
      urlSearchParams.append('limit', getPaginatedStockExtended.limit.toString());
    }
    if (getPaginatedStockExtended.orderField && getPaginatedStockExtended.orderField.length > 0) {
      urlSearchParams.append('orderField', getPaginatedStockExtended.orderField);
    }
    if (getPaginatedStockExtended.order && getPaginatedStockExtended.order.length > 0) {
      urlSearchParams.append('order', getPaginatedStockExtended.order);
    }
    if (getPaginatedStockExtended.query && getPaginatedStockExtended.query.length > 0) {
      urlSearchParams.append('query', getPaginatedStockExtended.query);
    }
    if (getPaginatedStockExtended.inStock !== null) {
      urlSearchParams.append('inStock', getPaginatedStockExtended.inStock.toString());
    }
    if (getPaginatedStockExtended.active !== null) {
      urlSearchParams.append('active', getPaginatedStockExtended.active.toString());
    }
    if (getPaginatedStockExtended.online !== null) {
      urlSearchParams.append('online', getPaginatedStockExtended.online.toString());
    }
    const url = `/stock/labels?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, { responseType: 'arraybuffer' });
    return axiosResponse.data;
  }

  public async getStockOfOrganization(getPaginatedStockOrganization: GetPaginatedStockOrganization): Promise<PaginatedDto<Stock>> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('orderField', getPaginatedStockOrganization.orderField);
    urlSearchParams.append('order', getPaginatedStockOrganization.order.toString());
    urlSearchParams.append('limit', getPaginatedStockOrganization.limit.toString());
    urlSearchParams.append('page', getPaginatedStockOrganization.page.toString());
    urlSearchParams.append('query', getPaginatedStockOrganization.query);
    urlSearchParams.append('organizationId', getPaginatedStockOrganization.organizationId.toString());
    if (getPaginatedStockOrganization.storeId && getPaginatedStockOrganization.storeId > 0) {
      urlSearchParams.append('storeId', getPaginatedStockOrganization.storeId.toString());
    }
    if (getPaginatedStockOrganization.brandId && getPaginatedStockOrganization.brandId > 0) {
      urlSearchParams.append('brandId', getPaginatedStockOrganization.brandId.toString());
    }
    if (getPaginatedStockOrganization.categoryId && getPaginatedStockOrganization.categoryId > 0) {
      urlSearchParams.append('categoryId', getPaginatedStockOrganization.categoryId.toString());
    }
    const url = `/stock/products-in-organization?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<PaginatedDto<Stock>> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createStock(stockDto: StockDto): Promise<Stock> {
    const url = `/stock`;
    const axiosResponse: AxiosResponse<Stock> = await httpClient.post(url, stockDto);
    return axiosResponse.data;
  }

  public async changeRepairCustomer(id: number, changeRepairCustomerDto: ChangeRepairCustomerDto): Promise<Repair> {
    const url = `/repairs/${id}/change-customer`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, changeRepairCustomerDto);
    return axiosResponse.data;
  }

  // SUBCATEGORIES

  public async getSubCategories(organizationId?: number): Promise<SubCategory[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (organizationId) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    const url = `/subcategories?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<SubCategory[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getSubCategory(id: number): Promise<SubCategory> {
    const url = `/subcategories/${id}`;
    const axiosResponse: AxiosResponse<SubCategory> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createSubCategory(subCategoryDto: SubCategoryDto): Promise<SubCategory> {
    const url = `/subcategories`;
    const axiosResponse: AxiosResponse<SubCategory> = await httpClient.post(url, subCategoryDto);
    return axiosResponse.data;
  }

  public async updateSubCategory(id: number, subCategoryDto: SubCategoryDto): Promise<SubCategory> {
    const url = `/subcategories/${id}`;
    const axiosResponse: AxiosResponse<SubCategory> = await httpClient.put(url, subCategoryDto);
    return axiosResponse.data;
  }

  public async deleteSubCategory(id: number): Promise<void> {
    const url = `/subcategories/${id}`;
    await httpClient.delete(url);
  }

  // REPAIRS

  public async getRepairs(startDate: Date | null, endDate: Date | null, organizationId: number, storeId?: number): Promise<Repair[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (startDate) {
      urlSearchParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
    }
    if (endDate) {
      urlSearchParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    }
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    if (storeId && storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    const url = `/repairs?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Repair[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getRepair(id: number): Promise<Repair> {
    const url = `/repairs/${id}`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createNewRepair(createRepairDto: CreateRepairDto): Promise<Repair> {
    const url = `/repairs/new`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.post(url, createRepairDto);
    return axiosResponse.data;
  }

  public async updateRepairWithWarranty(id: number, updateRepairWithWarrantyDto: UpdateRepairWithWarrantyDto): Promise<Repair> {
    const url = `/repairs/${id}/warranty`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, updateRepairWithWarrantyDto);
    return axiosResponse.data;
  }

  public async updateRepairPrices(id: number, repairPricesDto: RepairPricesDto): Promise<Repair> {
    const url = `/repairs/${id}/prices`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, repairPricesDto);
    return axiosResponse.data;
  }

  public async updateRepairAcceptBudget(id: number, repairAcceptBudgetDto: RepairAcceptBudgetDto): Promise<Repair> {
    const url = `/repairs/${id}/accept-budget`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, repairAcceptBudgetDto);
    return axiosResponse.data;
  }

  public async updateRepairSentTechnicalService(id: number, repairSentTechnicalServiceDto: RepairSentTechnicalServiceDto): Promise<Repair> {
    const url = `/repairs/${id}/sent-technical-service`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, repairSentTechnicalServiceDto);
    return axiosResponse.data;
  }

  public async updateRepairReceivedFromTechnicalService(id: number, repairReceivedFromTechnicalServiceDto: RepairReceivedFromTechnicalServiceDto): Promise<Repair> {
    const url = `/repairs/${id}/received-from-technical-service`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, repairReceivedFromTechnicalServiceDto);
    return axiosResponse.data;
  }

  public async updateRepairBudgetInStore(id: number, repairBudgetInStoreDto: RepairBudgetInStoreDto): Promise<Repair> {
    const url = `/repairs/${id}/budget-in-store`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, repairBudgetInStoreDto);
    return axiosResponse.data;
  }

  public async updateRepair(id: number, updateRepairDto: UpdateRepairDto): Promise<Repair> {
    const url = `/repairs/${id}`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, updateRepairDto);
    return axiosResponse.data;
  }

  public async cancelRepair(id: number, cancelRepairDto: CancelRepairDto): Promise<Repair> {
    const url = `/repairs/${id}/cancel`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, cancelRepairDto);
    return axiosResponse.data;
  }

  public async deliverRepair(id: number, deliverRepairDto: DeliverRepairDto): Promise<Repair> {
    const url = `/repairs/${id}/deliver`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, deliverRepairDto);
    return axiosResponse.data;
  }

  public async deleteRepair(id: number): Promise<Repair> {
    const url = `/repairs/${id}`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async updateRepairNotes(id: number, notesDto: NotesDto): Promise<Repair> {
    const url = `/repairs/${id}/notes`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, notesDto);
    return axiosResponse.data;
  }

  public async updateRepairInternalReference(id: number, repairInternalReferenceDto: RepairInternalReferenceDto): Promise<Repair> {
    const url = `/repairs/${id}/internal-reference`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, repairInternalReferenceDto);
    return axiosResponse.data;
  }

  public async addRepairRecord(repairRecordDto: RepairRecordDto): Promise<RepairRecord> {
    const url = `/repairs/record`;
    const axiosResponse: AxiosResponse<RepairRecord> = await httpClient.post(url, repairRecordDto);
    return axiosResponse.data;
  }

  public async getRepairTicket(id: number): Promise<Buffer> {
    const url = `/repairs/${id}/ticket`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, { responseType: 'arraybuffer' });
    return axiosResponse.data;
  }

  public async sendRepairTicketByEmail(id: number): Promise<boolean> {
    const url = `/repairs/${id}/ticket/email`;
    const axiosResponse: AxiosResponse<boolean> = await httpClient.post(url);
    return axiosResponse.data;
  }

  public async getImportCsvColumnsRepairs(): Promise<CsvColumnDto[]> {
    const url = `/repairs/import-csv/columns`;
    const axiosResponse: AxiosResponse<CsvColumnDto[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async importCsvRepairs(importCsvDto: ImportCsvDto): Promise<ImportCsvResultDto> {
    const formData: FormData = new FormData();
    formData.append('file', importCsvDto.file);
    formData.append('storeId', importCsvDto.storeId!.toString());
    formData.append('columns', JSON.stringify(importCsvDto.columns));
    const url = `/repairs/import-csv`;
    const axiosResponse: AxiosResponse<ImportCsvResultDto> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async updateRepairPaymentMethod(id: number, updateRepairPaymentMethodDto: UpdateRepairPaymentMethodDto): Promise<Repair> {
    const url = `/repairs/${id}/repair-payment-method`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, updateRepairPaymentMethodDto);
    return axiosResponse.data;
  }

  public async updatePaymentOnAccount(id: number, updatePaymentOnAccountDto: UpdatePaymentOnAccountDto): Promise<Repair> {
    const url = `/repairs/${id}/payment-on-account`;
    const axiosResponse: AxiosResponse<Repair> = await httpClient.put(url, updatePaymentOnAccountDto);
    return axiosResponse.data;
  }

  // REPAIR IMAGES

  public async uploadRepairImage(repairId: number, image: File): Promise<RepairImage> {
    const url = `/repair-images/${repairId}`;
    const formData = new FormData();
    formData.append('image', image);
    const axiosResponse: AxiosResponse<RepairImage> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async uploadRepairImageFromLink(repairId: number, fromLinkDto: FromLinkDto): Promise<RepairImage> {
    const url = `/repair-images/${repairId}/from-link`;
    const axiosResponse: AxiosResponse<RepairImage> = await httpClient.post(url, fromLinkDto);
    return axiosResponse.data;
  }

  public async uploadRepairImageFromBase64(repairId: number, fromBase64Dto: FromBase64Dto): Promise<RepairImage> {
    const url = `/repair-images/${repairId}/from-base64`;
    const axiosResponse: AxiosResponse<RepairImage> = await httpClient.post(url, fromBase64Dto);
    return axiosResponse.data;
  }

  public async deleteRepairImage(id: number): Promise<RepairImage> {
    const url = `/repair-images/${id}`;
    const axiosResponse: AxiosResponse<RepairImage> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // STATS

  public async getSalesStats(getSalesStats: GetSalesStats): Promise<SalesStats> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', getSalesStats.organizationId.toString());
    if (getSalesStats.storeId !== null && getSalesStats.storeId > 0) {
      urlSearchParams.append('storeId', getSalesStats.storeId.toString());
    }
    if (getSalesStats.startDate) {
      urlSearchParams.append('startDate', moment(getSalesStats.startDate).format('YYYY-MM-DD'));
    }
    if (getSalesStats.endDate) {
      urlSearchParams.append('endDate', moment(getSalesStats.endDate).format('YYYY-MM-DD'));
    }
    const url = `/stats/sales?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<SalesStats> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async exportSalesStats(getSalesStats: GetSalesStats): Promise<AxiosResponse<Buffer>> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', getSalesStats.organizationId.toString());
    if (getSalesStats.storeId !== null && getSalesStats.storeId > 0) {
      urlSearchParams.append('storeId', getSalesStats.storeId.toString());
    }
    if (getSalesStats.startDate) {
      urlSearchParams.append('startDate', moment(getSalesStats.startDate).format('YYYY-MM-DD'));
    }
    if (getSalesStats.endDate) {
      urlSearchParams.append('endDate', moment(getSalesStats.endDate).format('YYYY-MM-DD'));
    }
    const url = `/stats/sales/export?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, {
      responseType: 'arraybuffer',
    });
    return axiosResponse;
  }

  public async getSalesStatsGivenBrand(organizationId: number, storeId: number, brandId: number, startDate: Date | null, endDate: Date | null): Promise<SalesStatsByProduct[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', organizationId.toString());
    if (storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    urlSearchParams.append('brandId', brandId.toString());
    if (startDate) {
      urlSearchParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
    }
    if (endDate) {
      urlSearchParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    }
    const url = `/stats/sales-brand?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<SalesStatsByProduct[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getShoppingStats(organizationId: number, storeId: number, startDate: Date | null, endDate: Date | null): Promise<ShoppingStats> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', organizationId.toString());
    if (storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    if (startDate) {
      urlSearchParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
    }
    if (endDate) {
      urlSearchParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    }
    const url = `/stats/shopping?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<ShoppingStats> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getStockStats(organizationId: number, storeId: number, brandId: number): Promise<StockStats> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', organizationId.toString());
    if (storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    if (brandId > 0) {
      urlSearchParams.append('brandId', brandId.toString());
    }
    const url = `/stats/stock?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<StockStats> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getStockStatsGivenBrand(organizationId: number, storeId: number, brandId: number): Promise<StockStatsByProduct[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', organizationId.toString());
    if (storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    urlSearchParams.append('brandId', brandId.toString());
    const url = `/stats/stock-brand?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<StockStatsByProduct[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getStatsMostSelledProducts(getStatsMostSelledProducts: GetStatsMostSelledProducts): Promise<StatsMostSelledProduct[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (getStatsMostSelledProducts.startDate) {
      urlSearchParams.append('startDate', moment(getStatsMostSelledProducts.startDate).format('YYYY-MM-DD'));
    }
    if (getStatsMostSelledProducts.endDate) {
      urlSearchParams.append('endDate', moment(getStatsMostSelledProducts.endDate).format('YYYY-MM-DD'));
    }
    urlSearchParams.append('organizationId', getStatsMostSelledProducts.organizationId.toString());
    if (getStatsMostSelledProducts.storeId && getStatsMostSelledProducts.storeId > 0) {
      urlSearchParams.append('storeId', getStatsMostSelledProducts.storeId.toString());
    }
    if (getStatsMostSelledProducts.brandId) {
      urlSearchParams.append('brandId', getStatsMostSelledProducts.brandId.toString());
    }
    if (getStatsMostSelledProducts.collectionId) {
      urlSearchParams.append('collectionId', getStatsMostSelledProducts.collectionId.toString());
    }
    urlSearchParams.append('limit', getStatsMostSelledProducts.limit.toString());
    const url = `/stats/most-selled-products?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<StatsMostSelledProduct[]> = await httpClient.get<StatsMostSelledProduct[]>(url);
    return axiosResponse.data;
  }

  // STORES

  public async getStore(id: number): Promise<Store> {
    const url = `/stores/${id}`;
    const axiosResponse: AxiosResponse<Store> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getStores(organizationId?: number): Promise<Store[]> {
    let url = '/stores';
    if (organizationId && organizationId > 0) {
      url += `?organizationId=${organizationId}`;
    }
    const axiosResponse: AxiosResponse<Store[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createStore(storeDto: StoreDto): Promise<Store> {
    const url = `/stores`;
    const axiosResponse: AxiosResponse<Store> = await httpClient.post(url, storeDto);
    return axiosResponse.data;
  }

  public async updateStore(id: number, storeDto: StoreDto): Promise<Store> {
    const url = `/stores/${id}`;
    const axiosResponse: AxiosResponse<Store> = await httpClient.put(url, storeDto);
    return axiosResponse.data;
  }

  public async getStoreUsers(id: number): Promise<User[]> {
    const url = `/stores/${id}/users`;
    const axiosResponse: AxiosResponse<User[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async deleteStore(id: number): Promise<Store> {
    const url = `/stores/${id}`;
    const axiosResponse: AxiosResponse<Store> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async updateStoreLogo(id: number, image: File): Promise<Store> {
    const url = `/stores/${id}/logo`;
    const formData = new FormData();
    formData.append('image', image);
    const axiosResponse: AxiosResponse<Store> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async updateQRStore(id: number, image: File): Promise<Store> {
    const url = `/stores/${id}/qr`;
    const formData = new FormData();
    formData.append('image', image);
    const axiosResponse: AxiosResponse<Store> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async deleteStoreLogo(id: number): Promise<Store> {
    const url = `/stores/${id}/logo`;
    const axiosResponse: AxiosResponse<Store> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async deleteQRStore(id: number): Promise<Store> {
    const url = `/stores/${id}/qr`;
    const axiosResponse: AxiosResponse<Store> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async getOrganizationStores(organizationId: number): Promise<Store[]> {
    const url = `/organizations/${organizationId}/stores`;
    const axiosResponse: AxiosResponse<Store[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  // SUBFAMILIES

  public async getSubFamilies(): Promise<SubFamily[]> {
    const url = `/subfamilies`;
    const axiosResponse: AxiosResponse<SubFamily[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getSubFamily(id: number): Promise<SubFamily> {
    const url = `/subfamilies/${id}`;
    const axiosResponse: AxiosResponse<SubFamily> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createSubFamily(subFamilyDto: SubFamilyDto): Promise<SubFamily> {
    const url = `/subfamilies`;
    const axiosResponse: AxiosResponse<SubFamily> = await httpClient.post(url, subFamilyDto);
    return axiosResponse.data;
  }

  public async updateSubFamily(id: number, subFamilyDto: SubFamilyDto): Promise<SubFamily> {
    const url = `/subfamilies/${id}`;
    const axiosResponse: AxiosResponse<SubFamily> = await httpClient.put(url, subFamilyDto);
    return axiosResponse.data;
  }

  public async deleteSubFamily(id: number): Promise<SubFamily> {
    const url = `/subfamilies/${id}`;
    const axiosResponse: AxiosResponse<SubFamily> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // SUPPLIERS

  public async getSuppliers(organizationId?: number): Promise<Supplier[]> {
    let url = '/suppliers';
    if (organizationId && organizationId > 0) {
      url += `?organizationId=${organizationId}`;
    }
    const axiosResponse: AxiosResponse<Supplier[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createSupplier(supplierDto: SupplierDto): Promise<Supplier> {
    const url = `/suppliers`;
    const axiosResponse: AxiosResponse<Supplier> = await httpClient.post(url, supplierDto);
    return axiosResponse.data;
  }

  public async updateSupplier(id: number, supplierDto: SupplierDto): Promise<Supplier> {
    const url = `/suppliers/${id}`;
    const axiosResponse: AxiosResponse<Supplier> = await httpClient.put(url, supplierDto);
    return axiosResponse.data;
  }

  public async deleteSupplier(id: number): Promise<Supplier> {
    const url = `/suppliers/${id}`;
    const axiosResponse: AxiosResponse<Supplier> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // TECHNICAL SERVICES

  public async getTechnicalServices(organizationId?: number): Promise<TechnicalService[]> {
    let url = '/technical-services';
    if (organizationId && organizationId > 0) {
      url += `?organizationId=${organizationId}`;
    }
    const axiosResponse: AxiosResponse<TechnicalService[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createTechnicalService(technicalServiceDto: TechnicalServiceDto): Promise<TechnicalService> {
    const url = `/technical-services`;
    const axiosResponse: AxiosResponse<TechnicalService> = await httpClient.post(url, technicalServiceDto);
    return axiosResponse.data;
  }

  public async updateTechnicalService(id: number, technicalServiceDto: TechnicalServiceDto): Promise<TechnicalService> {
    const url = `/technical-services/${id}`;
    const axiosResponse: AxiosResponse<TechnicalService> = await httpClient.put(url, technicalServiceDto);
    return axiosResponse.data;
  }

  public async deleteTechnicalService(id: number): Promise<TechnicalService> {
    const url = `/technical-services/${id}`;
    const axiosResponse: AxiosResponse<TechnicalService> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // THRESHOLDS

  public async getThresholds(organizationId: number, type: ThresholdType, storeId: number): Promise<Threshold[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', organizationId.toString());
    urlSearchParams.append('type', type.toString());
    if (storeId && storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    const url = `/thresholds?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Threshold[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createThreshold(thresholdDto: ThresholdDto): Promise<Threshold> {
    const url = `/thresholds`;
    const axiosResponse: AxiosResponse<Threshold> = await httpClient.post(url, thresholdDto);
    return axiosResponse.data;
  }

  public async updateThreshold(id: number, thresholdDto: ThresholdDto): Promise<Threshold> {
    const url = `/thresholds/${id}`;
    const axiosResponse: AxiosResponse<Threshold> = await httpClient.put(url, thresholdDto);
    return axiosResponse.data;
  }

  public async deleteThreshold(id: number): Promise<Threshold> {
    const url = `/thresholds/${id}`;
    const axiosResponse: AxiosResponse<Threshold> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // TRANSFERS

  public async getTransfers(startDate: Date | null, endDate: Date | null, organizationId: number, storeId: number): Promise<Transfer[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (startDate) {
      urlSearchParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
    }
    if (endDate) {
      urlSearchParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    }
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    if (storeId && storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    const url = `/transfers?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Transfer[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getTransfer(id: number): Promise<Transfer> {
    const url = `/transfers/${id}`;
    const axiosResponse: AxiosResponse<Transfer> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getTransferToVerify(id: number): Promise<Transfer> {
    const url = `/transfers/${id}/verify`;
    const axiosResponse: AxiosResponse<Transfer> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createTransfer(createTransferDto: CreateTransferDto): Promise<Transfer> {
    const url = `/transfers`;
    const axiosResponse: AxiosResponse<Transfer> = await httpClient.post(url, createTransferDto);
    return axiosResponse.data;
  }

  public async updateTransfer(id: number, updateTransferDto: UpdateTransferDto): Promise<Transfer> {
    const url = `/transfers/${id}/update`;
    const axiosResponse: AxiosResponse<Transfer> = await httpClient.put(url, updateTransferDto);
    return axiosResponse.data;
  }

  public async verifyTransfer(id: number, transferredProductDtos: ProductTransferDto[]): Promise<Transfer> {
    const url = `/transfers/${id}/verify`;
    const axiosResponse: AxiosResponse<Transfer> = await httpClient.put(url, transferredProductDtos);
    return axiosResponse.data;
  }

  public async fixTransfer(id: number, verifyProductsTransferDtos: VerifyProductTransferDto[]): Promise<Transfer> {
    const url = `/transfers/${id}/fix`;
    const axiosResponse: AxiosResponse<Transfer> = await httpClient.put(url, verifyProductsTransferDtos);
    return axiosResponse.data;
  }

  public async deleteTransfer(id: number): Promise<Transfer> {
    const url = `/transfers/${id}`;
    const axiosResponse: AxiosResponse<Transfer> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async getStoresForTransfer(organizationId: number): Promise<Store[]> {
    const url = `/transfers/stores?organizationId=${organizationId}`;
    const axiosResponse: AxiosResponse<Store[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getStockTransfer(fromStoreId: number, toStoreId: number, ignoreTransferId?: number): Promise<StockTransfer[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('fromStoreId', fromStoreId.toString());
    urlSearchParams.append('toStoreId', toStoreId.toString());
    if (ignoreTransferId && ignoreTransferId > 0) {
      urlSearchParams.append('ignoreTransferId', ignoreTransferId.toString());
    }
    const url = `/transfers/stock?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<StockTransfer[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async updateTransferNotes(id: number, notes: string | null): Promise<Transfer> {
    const url = `/transfers/${id}/notes`;
    const axiosResponse: AxiosResponse<Transfer> = await httpClient.put(url, { notes });
    return axiosResponse.data;
  }

  // USERS

  public async me(): Promise<User> {
    const url = '/users/me';
    const axiosResponse: AxiosResponse<User> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getUsers(organizationId: number): Promise<User[]> {
    const url = `/users?organizationId=${organizationId}`;
    const axiosResponse: AxiosResponse<User[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getUser(id: number): Promise<User> {
    const url = `/users/${id}`;
    const axiosResponse: AxiosResponse<User> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getUserByPin(pinDto: PinDto): Promise<User> {
    const url = `/users/pin`;
    const axiosResponse: AxiosResponse<User> = await httpClient.post(url, pinDto);
    return axiosResponse.data;
  }

  public async createUser(userDto: UserDto): Promise<User> {
    const url = `/users`;
    const axiosResponse: AxiosResponse<User> = await httpClient.post(url, userDto);
    return axiosResponse.data;
  }

  public async updateUser(id: number, userDto: UserDto): Promise<User> {
    const url = `/users/${id}`;
    const axiosResponse: AxiosResponse<User> = await httpClient.put(url, userDto);
    return axiosResponse.data;
  }

  public async changeUserPassword(changePasswordDto: ChangePasswordDto): Promise<boolean> {
    const url = `/users/change-password`;
    const axiosResponse: AxiosResponse<boolean> = await httpClient.post(url, changePasswordDto);
    return axiosResponse.data;
  }

  public async deleteUser(userId: number): Promise<User> {
    const url = `/users/${userId}`;
    const axiosResponse: AxiosResponse<User> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  public async updateUserImage(id: number, image: File): Promise<User> {
    const url = `/users/${id}/image`;
    const formData = new FormData();
    formData.append('image', image);
    const axiosResponse: AxiosResponse<User> = await httpClient.post(url, formData);
    return axiosResponse.data;
  }

  public async deleteUserImage(id: number): Promise<User> {
    const url = `/users/${id}/image`;
    const axiosResponse: AxiosResponse<User> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // USER TIME SHEETS

  public async getUserTimeSheet(getUserTimeSheet: GetUserTimeSheet): Promise<UserTimeSheet> {
    const url = `/user-time-sheets?organizationId=${getUserTimeSheet.organizationId}&year=${getUserTimeSheet.year}&month=${getUserTimeSheet.month}`;
    const axiosResponse: AxiosResponse<UserTimeSheet> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async updateUserTimeSheet(id: number, userTimeSheetDto: UserTimeSheetDto): Promise<UserTimeSheet> {
    const url = `/user-time-sheets/${id}`;
    const axiosResponse: AxiosResponse<UserTimeSheet> = await httpClient.put(url, userTimeSheetDto);
    return axiosResponse.data;
  }

  // USER WORK CLOCKINGS

  public async getOrganizationUserWorkClockings(getOrganizationUserWorkClockingsQuery: GetOrganizationUserWorkClockingsQuery): Promise<UserWorkClocking[]> {
    const url = `/user-work-clockings/organization?organizationId=${getOrganizationUserWorkClockingsQuery.organizationId}&startDate=${getOrganizationUserWorkClockingsQuery.startDate}&endDate=${getOrganizationUserWorkClockingsQuery.endDate}`;
    const axiosResponse: AxiosResponse<UserWorkClocking[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getUserWorkClockings(getUserWorkClockingsQuery: GetUserWorkClockingsQuery): Promise<UserWorkClocking[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (getUserWorkClockingsQuery.startDate) {
      urlSearchParams.append('startDate', moment(getUserWorkClockingsQuery.startDate).format('YYYY-MM-DD'));
    }
    if (getUserWorkClockingsQuery.endDate) {
      urlSearchParams.append('endDate', moment(getUserWorkClockingsQuery.endDate).format('YYYY-MM-DD'));
    }
    urlSearchParams.append('userTimeSheetId', getUserWorkClockingsQuery.userTimeSheetId.toString());
    urlSearchParams.append('userId', getUserWorkClockingsQuery.userId.toString());
    const url = `/user-work-clockings/user?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<UserWorkClocking[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async exportUserWorkClockings(getUserWorkClockingsQuery: GetUserWorkClockingsQuery): Promise<AxiosResponse<Buffer>> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (getUserWorkClockingsQuery.startDate) {
      urlSearchParams.append('startDate', moment(getUserWorkClockingsQuery.startDate).format('YYYY-MM-DD'));
    }
    if (getUserWorkClockingsQuery.endDate) {
      urlSearchParams.append('endDate', moment(getUserWorkClockingsQuery.endDate).format('YYYY-MM-DD'));
    }
    urlSearchParams.append('userTimeSheetId', getUserWorkClockingsQuery.userTimeSheetId.toString());
    urlSearchParams.append('userId', getUserWorkClockingsQuery.userId.toString());
    const url = `/user-work-clockings/export?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Buffer> = await httpClient.get(url, {
      responseType: 'arraybuffer',
    });
    return axiosResponse;
  }

  public async createUserWorkClocking(userWorkClockingDto: UserWorkClockingDto): Promise<UserWorkClocking> {
    const url = `/user-work-clockings`;
    const axiosResponse: AxiosResponse<UserWorkClocking> = await httpClient.post(url, userWorkClockingDto);
    return axiosResponse.data;
  }

  public async updateUserWorkClocking(id: number, userWorkClockingDto: UserWorkClockingDto): Promise<UserWorkClocking> {
    const url = `/user-work-clockings/${id}`;
    const axiosResponse: AxiosResponse<UserWorkClocking> = await httpClient.put(url, userWorkClockingDto);
    return axiosResponse.data;
  }

  public async deleteUserWorkClocking(id: number, deleteUserWorkClockingDto: DeleteUserWorkClockingDto): Promise<UserWorkClocking> {
    const url = `/user-work-clockings/${id}`;
    const axiosResponse: AxiosResponse<UserWorkClocking> = await httpClient.delete(url, { data: deleteUserWorkClockingDto });
    return axiosResponse.data;
  }

  // WITHDRAWALS

  public async getWithdrawals(startDate: Date | null, endDate: Date | null, organizationId: number, storeId: number): Promise<Withdrawal[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    if (startDate) {
      urlSearchParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
    }
    if (endDate) {
      urlSearchParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    }
    if (organizationId && organizationId > 0) {
      urlSearchParams.append('organizationId', organizationId.toString());
    }
    if (storeId && storeId > 0) {
      urlSearchParams.append('storeId', storeId.toString());
    }
    const url = `/withdrawals?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<Withdrawal[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getWithdrawal(id: number): Promise<Withdrawal> {
    const url = `/withdrawals/${id}`;
    const axiosResponse: AxiosResponse<Withdrawal> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createWithdrawal(withdrawalDto: WithdrawalDto): Promise<Withdrawal> {
    const url = `/withdrawals`;
    const axiosResponse: AxiosResponse<Withdrawal> = await httpClient.post(url, withdrawalDto);
    return axiosResponse.data;
  }

  public async updateWithdrawal(id: number, withdrawalDto: WithdrawalDto): Promise<Withdrawal> {
    const url = `/withdrawals/${id}/update`;
    const axiosResponse: AxiosResponse<Withdrawal> = await httpClient.put(url, withdrawalDto);
    return axiosResponse.data;
  }

  public async deleteWithdrawal(id: number): Promise<Withdrawal> {
    const url = `/withdrawals/${id}`;
    const axiosResponse: AxiosResponse<Withdrawal> = await httpClient.delete(url);
    return axiosResponse.data;
  }

  // WORK SHIFTS

  public async getWorkShifts(getWorkShiftsQuery: GetWorkShiftsQuery): Promise<WorkShift[]> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('organizationId', getWorkShiftsQuery.organizationId.toString());
    urlSearchParams.append('startDate', moment(getWorkShiftsQuery.startDate).format('YYYY-MM-DD'));
    urlSearchParams.append('endDate', moment(getWorkShiftsQuery.endDate).format('YYYY-MM-DD'));
    if (getWorkShiftsQuery.userId) {
      urlSearchParams.append('userId', getWorkShiftsQuery.userId.toString());
    }
    const url = `/work-shifts?${urlSearchParams.toString()}`;
    const axiosResponse: AxiosResponse<WorkShift[]> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async getWorkShift(id: number): Promise<WorkShift> {
    const url = `/work-shifts/${id}`;
    const axiosResponse: AxiosResponse<WorkShift> = await httpClient.get(url);
    return axiosResponse.data;
  }

  public async createWorkShifts(workShiftsDto: WorkShiftsDto): Promise<WorkShift[]> {
    const url = `/work-shifts`;
    const axiosResponse: AxiosResponse<WorkShift[]> = await httpClient.post(url, workShiftsDto);
    return axiosResponse.data;
  }

  public async updateWorkShift(id: number, workShiftDto: WorkShiftDto): Promise<WorkShift> {
    const url = `/work-shifts/${id}`;
    const axiosResponse: AxiosResponse<WorkShift> = await httpClient.put(url, workShiftDto);
    return axiosResponse.data;
  }

  public async deleteWorkShift(id: number): Promise<WorkShift> {
    const url = `/work-shifts/${id}`;
    const axiosResponse: AxiosResponse<WorkShift> = await httpClient.delete(url);
    return axiosResponse.data;
  }
}

export const api: Api = new Api();
