import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PosStatus } from '../enums/pos-status';
import { SaleStatus } from '../enums/sale-status';
import { Sale } from '../interfaces/sale';
import { api } from '../services/api';
import { downloadPdf, formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { createReturnOnlineSale, createSaleReturn, reset, saleSelector, setSale, setSaveChangeInBalance, updateSale } from '../store/sale-slice';
import { storeSelector } from '../store/store-slice';
import CancelReservationModal from './cancel-reservation-modal';
import PinModal from './pin-modal';
import ReturnPaymentMethods from './return-payment-methods';
import TableBookingPayments from './table-booking-payments';
import { TicketOptions } from './ticket-options';

const SalePayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { store } = useSelector(storeSelector);
  const { change, pending, total, saveChangeInBalance, loading, newProducts, sale, bookingPayments, previosNextSale, customer, requesting } = useSelector(saleSelector);
  const [showPinModal, setShowPinModal] = useState(false);
  const [showPinModalReservation, setShowPinModalReservation] = useState(false);
  const [showCancelReservationModal, setShowCancelReservationModal] = useState(false);
  const [requestingTicket, setRequestingTicket] = useState<boolean>(false);
  const [sendingTicketByEmail, setSendingTicketByEmail] = useState<boolean>(false);

  const disabledFinalizedButton: boolean = useMemo(() => {
    if (loading || requesting) {
      return true;
    }
    if (total < 0) {
      // La suma de los artículos es inferior a la compra original
      return pending !== 0;
    }
    // La suma de los artículos es (devoluciones + cambios) >= 0
    return pending !== 0;
  }, [loading, pending, requesting, total]);

  const onFinalizePayment = () => {
    setShowPinModal(true);
  };

  const saveReservation = () => {
    setShowPinModalReservation(true);
  };

  const cancelReservation = () => {
    if (store?.posStatus === PosStatus.Closed) {
      myToastr.error('Abre la caja para poder devolver productos');
      return;
    }
    setShowCancelReservationModal(true);
  };

  const onClosePinModal = async (pin: string | null) => {
    setShowPinModal(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    let sales: Sale[] = [];
    if (sale?.onlineSaleId) {
      const result = (await dispatch(createReturnOnlineSale(pin))) as any;
      sales = result.payload;
    } else {
      const result = (await dispatch(createSaleReturn(pin))) as any;
      sales = result.payload;
    }
    const returnSale: Sale | undefined = sales.find((s: Sale) => s.status === SaleStatus.Return);
    if (returnSale) {
      dispatch(reset());
      dispatch(setSale(returnSale));
    }
  };

  const onClosePinModalReservation = (pin: string | null) => {
    setShowPinModalReservation(false);
    if (pin == null || pin.length !== 4) {
      return;
    }
    dispatch(updateSale(pin));
  };

  const onPrintTicket = async () => {
    if (requestingTicket) {
      return;
    }
    try {
      myToastr.info('Obteniendo el ticket de la reserva. Espere por favor.');
      setRequestingTicket(true);
      const result = await api.getSaleTicket(sale!.id);
      downloadPdf(result, `ticket-${sale!.internalId}.pdf`);
    } catch (e) {
      myToastr.error('Hubo un error obteniendo el ticket de la reserva');
    } finally {
      setRequestingTicket(false);
    }
  };

  const onSendEmail = async () => {
    if (sendingTicketByEmail) {
      return;
    }
    if (!customer?.email) {
      myToastr.error('El cliente no tiene un email asociado');
      return;
    }
    myToastr.info('Enviando el ticket de la reserva por correo electrónico. Espere por favor.');
    try {
      setSendingTicketByEmail(true);
      const result: boolean = await api.sendSaleTicketByEmail(sale!.id);
      if (result) {
        myToastr.success('Se ha enviado el ticket de la reserva por correo electrónico');
      } else {
        myToastr.error('Hubo un error enviando el ticket por correo electrónico');
      }
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          myToastr.error(Array.isArray(axiosError.response.data.message) ? axiosError.response.data.message.join('<br>') : axiosError.response.data.message);
        }
      } else {
        myToastr.error('Hubo un error enviando el ticket por correo electrónico');
      }
    } finally {
      setSendingTicketByEmail(false);
    }
  };

  const onCloseCancelReservation = (result: boolean) => {
    setShowCancelReservationModal(false);
  };

  if (!sale) {
    return null;
  }

  let title: string = newProducts.length === 0 ? 'Realizar devolución' : 'Realizar devolución con cambio';
  if (sale?.status === SaleStatus.Reservation) {
    title = 'Pago a cuenta';
  }

  return (
    <React.Fragment>
      <div className="sale-payment make-payment h-100">
        <div className="d-flex flex-column h-100 position-relative">
          <div className="mb-auto px-4 pt-3">
            <div className="d-flex flex-row justify-content-between">
              <div className="come-back clickable">
                <ChevronLeft className="me-1" size={14} />
                <span
                  className={clsx('prev-next', { disabled: !previosNextSale?.previousSaleId })}
                  onClick={() => {
                    if (previosNextSale?.previousSaleId) {
                      navigate(`/venta/${previosNextSale.previousSaleId}`);
                    }
                  }}
                >
                  Anterior
                </span>
              </div>
              <div className="text-center mb-3 ">
                <h1>
                  {sale?.internalReservationId}
                  <br />
                  {title}
                </h1>
                {sale?.status === SaleStatus.Reservation && (
                  <button className="cancel-reservation" onClick={cancelReservation}>
                    Cancelar
                  </button>
                )}
              </div>
              <div className="come-back clickable">
                <span
                  className={clsx('prev-next', { disabled: !previosNextSale?.nextSaleId })}
                  onClick={() => {
                    if (previosNextSale?.nextSaleId) {
                      navigate(`/venta/${previosNextSale.nextSaleId}`);
                    }
                  }}
                >
                  Siguiente
                </span>
                <ChevronRight className="me-1" size={14} />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between mb-3">
              <div className="d-flex flex-column payment-columns me-4">
                <ReturnPaymentMethods />
              </div>
              <div className="d-flex flex-column payment-columns ms-4">
                <div className="d-flex flex-column container-money mb-3">
                  <span className="title">Total</span>
                  <span className="value">{formatNumber(total)}€</span>
                </div>
                {total < 0 && (
                  <div className={clsx('d-flex flex-column container-money mb-3', { alert: pending > 0, inactive: pending === 0 })}>
                    <span className="title">Pendiente devolución</span>
                    <span className="value">{formatNumber(pending)}€</span>
                  </div>
                )}
                {total >= 0 && (
                  <div className={clsx('d-flex flex-column container-money mb-3', { alert: pending < 0 || pending > 0, inactive: pending === 0 })}>
                    <span className="title">Pendiente</span>
                    <span className="value">{formatNumber(pending)}€</span>
                  </div>
                )}
                {total >= 0 && (
                  <div className={clsx('d-flex flex-column container-money mb-3', { success: change > 0, inactive: change === 0 })}>
                    <span className="title">Cambio total</span>
                    <div className="d-flex flex-column justify-content-between">
                      <span className="value">{formatNumber(change)}€</span>
                      {change > 0 && (
                        <div className="save-balance">
                          <input
                            type="checkbox"
                            checked={saveChangeInBalance}
                            onChange={(e: any) => {
                              dispatch(setSaveChangeInBalance(e.target.checked));
                            }}
                          />
                          <label>Guardar en saldo</label>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {sale.status === SaleStatus.Reservation && (
              <React.Fragment>
                <div className="my-4">
                  <TableBookingPayments />
                </div>
                <TicketOptions showGift={false} onPrint={onPrintTicket} onSendGift={() => {}} onSendEmail={onSendEmail} />
              </React.Fragment>
            )}
          </div>
          <div className="container-buttons">
            {sale.status === SaleStatus.Finalized && (
              <button
                onClick={onFinalizePayment}
                disabled={disabledFinalizedButton}
                className={clsx('finalize-button', { 'valid-return': pending === 0 && total < 0, 'valid-sale': pending === 0 && total >= 0 })}
              >
                {total >= 0 ? 'Finalizar' : 'Devolver'}
              </button>
            )}
            {sale.status === SaleStatus.Reservation && (
              <button onClick={saveReservation} disabled={bookingPayments.length === 0} className={clsx('finalize-button', { 'valid-sale': bookingPayments.length > 0 })}>
                {pending === 0 ? 'Finalizar' : 'Actualizar'}
              </button>
            )}
          </div>
        </div>
      </div>
      <PinModal show={showPinModal} onCloseModal={onClosePinModal} />
      <PinModal show={showPinModalReservation} onCloseModal={onClosePinModalReservation} />
      <CancelReservationModal show={showCancelReservationModal} closeModal={onCloseCancelReservation} />
    </React.Fragment>
  );
};

export default SalePayment;
