import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';

interface ProductUnitsModalProps {
  show: boolean;
  closeModal: () => void;
  saveUnits: (units: number) => void;
}

interface FormValues {
  units: number | null;
}

const ProductUnitsModal = ({ closeModal, saveUnits, show }: ProductUnitsModalProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (show) {
      reset({
        units: null,
      });
    }
  }, [show, reset]);

  const close = () => {
    reset({
      units: null,
    });
    closeModal();
  };

  const onSubmit = (data: FormValues) => {
    saveUnits(data.units!);
  };

  return (
    <Modal className="vercomi-modal my-form pos-discount-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Unidades</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.units })}>Introduce las unidades del producto *</div>
              <input type="number" {...register('units', { required: true, min: 0, valueAsNumber: true })} min={0} className={clsx({ error: errors?.units })} step="any" />
              {errors.units && <div className="error-message">{errors.units.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default ProductUnitsModal;
