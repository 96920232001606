import clsx from 'clsx';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumber } from '../services/helpers';
import { posSelector, setTotal } from '../store/pos-slice';
import TotalSaleModal from './total-sale-modal';

interface PosSubtotalsProps {
  editable?: boolean;
}

const PosSubtotals = ({ editable }: PosSubtotalsProps) => {
  const dispatch = useDispatch();
  const { items, manualSaleProducts, total, totalIva, totalDiscount } = useSelector(posSelector);
  const [showTotalSaleModal, setShowTotalSaleModal] = useState(false);

  const onTotalChange = (newTotal: number) => {
    if (newTotal !== total) {
      dispatch(setTotal(newTotal));
    }
    setShowTotalSaleModal(false);
  };

  const numLines = items.length + manualSaleProducts.length;
  const totalCanBeModified = editable && numLines > 1;

  return (
    <>
      <div className="pos-subtotals noselect">
        <div className="d-flex flex-column mb-3 totals mt-2">
          <div className="d-flex flex-row justify-content-end mb-1 taxes">
            <div className="">Total IVA ({formatNumber(parseFloat(process.env.REACT_APP_IVA))}%):</div>
            <div className="ms-1">{formatNumber(totalIva)}€</div>
          </div>
          <div className="d-flex flex-row justify-content-end mb-1 taxes">
            <div className="">Descuento:</div>
            <div className="ms-1">{formatNumber(totalDiscount)}€</div>
          </div>
          <div className="d-flex flex-row justify-content-end aling-content-center gap-1 mb-1 total">
            <div className={clsx(totalCanBeModified && 'title-total-sale')}>Total:</div>
            <div
              className={clsx(totalCanBeModified && 'total-sale')}
              onClick={() => {
                if (totalCanBeModified) {
                  setShowTotalSaleModal(true);
                }
              }}
              title={totalCanBeModified ? 'Modificar total' : undefined}
            >
              {formatNumber(total)}€
            </div>
          </div>
        </div>
      </div>
      <TotalSaleModal onCancel={() => setShowTotalSaleModal(false)} onSave={onTotalChange} show={showTotalSaleModal} />
    </>
  );
};

export default PosSubtotals;
