import clsx from 'clsx';
import { useEffect } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';

interface TotalSaleModalProps {
  onCancel: () => void;
  onSave: (total: number) => void;
  show: boolean;
}

interface FormValues {
  total: number | null;
}

const TotalSaleModal = ({ onCancel, onSave, show }: TotalSaleModalProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (show) {
      reset({
        total: null,
      });
    }
  }, [show, reset]);

  const close = () => {
    onCancel();
  };

  const onSubmit = (data: FormValues) => {
    onSave(data.total!);
  };

  return (
    <Modal className="vercomi-modal my-form pos-discount-modal" isOpen={show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Total</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className={clsx('input-name', { error: errors?.total })}>Total*</div>
              <input type="number" {...register('total', { required: true, min: 0, valueAsNumber: true })} min={0} className={clsx({ error: errors?.total })} step="any" />
              {errors.total && <div className="error-message">{errors.total.message}</div>}
            </div>
          </div>
        </div>
        <button className={`save-button`} type="submit">
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default TotalSaleModal;
