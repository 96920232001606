import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PosStep } from '../enums/pos-step';
import { SaleStatus } from '../enums/sale-status';
import { Balance } from '../interfaces/balance';
import { BookingPaymentDto } from '../interfaces/booking-payment.dto';
import { Customer } from '../interfaces/customer';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { ManualSaleProductDto } from '../interfaces/manual-sale-product.dto';
import { PosItem } from '../interfaces/pos-item';
import { Sale } from '../interfaces/sale';
import { SalePaymentMethodDto } from '../interfaces/sale-payment-method.dto';
import { SaleDto } from '../interfaces/sale.dto';
import { Stock } from '../interfaces/stock';
import { api } from '../services/api';
import { roundTwoDecimals } from '../services/helpers';
import myToastr from '../services/toastr';
import { RootState } from './store';

export interface PosState {
  sale: Sale | null;
  notes: string | null;
  customer: Customer | null;
  balance: Balance | null;
  items: PosItem[];
  manualSaleProducts: ManualSaleProductDto[];
  itemIds: { [productId: number]: boolean };
  total: number;
  totalIva: number;
  totalDiscount: number;
  step: PosStep;
  paymentMethods: { [paymentMethodId: number]: number };
  bookingPayments: BookingPaymentDto[];
  status: SaleStatus;
  pending: number;
  changeToClient: number;
  changeInBalance: boolean;
  loading: boolean;
  manualProductPendingToAdd: boolean;
}

const initialState: PosState = {
  sale: null,
  notes: null,
  customer: null,
  balance: null,
  items: [],
  manualSaleProducts: [],
  itemIds: {},
  total: 0,
  totalIva: 0,
  totalDiscount: 0,
  step: PosStep.AddingProducts,
  paymentMethods: {},
  bookingPayments: [],
  status: SaleStatus.Pending,
  pending: 0,
  changeToClient: 0,
  changeInBalance: false,
  loading: false,
  manualProductPendingToAdd: false,
};

function* getCustomerBalance(): any {
  try {
    const state: RootState = yield select();
    const customer: Customer | null = state.pos.customer;
    if (customer) {
      const balance: Balance = yield api.getCustomerBalance(customer.id);
      yield put(posSlice.actions.setBalance(balance));
    } else {
      yield put(posSlice.actions.setBalance(null));
    }
  } catch (e) {
    yield put(posSlice.actions.setBalance(null));
  }
}

export const createSale = createAsyncThunk('pos/createSale', async (pin: string, thunkApi): Promise<Sale | null> => {
  const state: RootState = thunkApi.getState() as RootState;
  try {
    let salePaymentMethods: SalePaymentMethodDto[] = [];
    for (const paymentMethodId in state.pos.paymentMethods) {
      if (state.pos.paymentMethods.hasOwnProperty(paymentMethodId)) {
        const amount: number = state.pos.paymentMethods[paymentMethodId];
        salePaymentMethods.push({
          paymentMethodId: parseInt(paymentMethodId, 10),
          amount,
        });
      }
    }
    let bookingPayments: BookingPaymentDto[] = [];
    let status: SaleStatus = SaleStatus.Finalized;
    if (state.pos.status === SaleStatus.Reservation) {
      if (state.pos.pending > 0) {
        status = SaleStatus.Reservation;
        bookingPayments = state.pos.bookingPayments.slice();
        salePaymentMethods = [];
      } else {
        salePaymentMethods = state.pos.bookingPayments.slice();
        bookingPayments = [];
      }
    }
    const saleDto: SaleDto = {
      organizationId: state.auth.organization!.id,
      pin,
      notes: state.pos.notes,
      status,
      returnDate: null,
      storeId: state.store.selectedStoreId,
      salePaymentMethods,
      bookingPayments,
      customerId: state.pos.customer!.id,
      saleProducts: state.pos.items.map((item: PosItem) => {
        return {
          quantity: item.units,
          discountPercentage: item.discountValue,
          productId: item.stock.productId,
          total: item.total,
        };
      }),
      manualSaleProducts: state.pos.manualSaleProducts,
      changeToClient: state.pos.changeToClient,
      changeInBalance: state.pos.changeInBalance,
    };
    const sale: Sale = await api.createSale(saleDto);
    if (sale) {
      myToastr.success(sale.status === SaleStatus.Finalized ? `Venta finalizada` : `Reserva creada`);
    }
    return sale;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    return null;
  }
});

export const posSlice = createSlice({
  name: 'pos',
  initialState,
  reducers: {
    setSale: (state, action: PayloadAction<Sale>) => {
      state.sale = action.payload;
    },
    setNotes: (state: PosState, action: PayloadAction<string | null>) => {
      state.notes = action.payload;
    },
    setCustomer: (state: PosState, action: PayloadAction<Customer | null>) => {
      state.customer = action.payload;
    },
    setBalance: (state: PosState, action: PayloadAction<Balance | null>) => {
      state.balance = action.payload;
    },
    updateTotals: (state: PosState) => {
      let total = 0;
      let totalDiscount = 0;
      state.items.forEach((item: PosItem) => {
        total += item.total;
        totalDiscount += (item.unitPrice - item.discountedUnitPrice) * item.units;
      });
      state.manualSaleProducts.forEach((manualSaleProduct: ManualSaleProductDto) => {
        total += manualSaleProduct.total || 0;
        if (manualSaleProduct.total !== null && manualSaleProduct.pvp !== null && manualSaleProduct.quantity !== null) {
          totalDiscount += manualSaleProduct.pvp * manualSaleProduct.quantity - manualSaleProduct.total;
        }
      });
      state.total = roundTwoDecimals(total);
      state.totalIva = roundTwoDecimals(total - total / ((100 + parseFloat(process.env.REACT_APP_IVA)) / 100));
      state.totalDiscount = Math.max(roundTwoDecimals(totalDiscount), 0);
      state.pending = state.total;
      state.changeToClient = 0;
      posSlice.caseReducers.calculatePendingAndChange(state);
    },
    addItem: (state: PosState, action: PayloadAction<Stock>) => {
      const stock: Stock = action.payload;
      const index: number = state.items.findIndex((item: PosItem) => item.stock.productId === stock.productId);
      if (index !== -1) {
        state.items[index].units++;
        state.items[index].total = roundTwoDecimals(state.items[index].units * state.items[index].discountedUnitPrice);
      } else {
        state.items.push({
          stock: action.payload,
          units: 1,
          discountValue: 0,
          unitPrice: stock.pvp,
          discountedUnitPrice: stock.pvp,
          total: stock.pvp,
        });
      }
      state.itemIds[action.payload.productId] = true;
      posSlice.caseReducers.updateTotals(state);
    },
    addManualSaleProduct: (state: PosState, action: PayloadAction<ManualSaleProductDto>) => {
      const manualSaleProduct: ManualSaleProductDto = action.payload;
      manualSaleProduct.name = manualSaleProduct.name.trim();
      state.manualSaleProducts.push(manualSaleProduct);
      posSlice.caseReducers.updateTotals(state);
    },
    removeItem: (state: PosState, action: PayloadAction<number>) => {
      const index: number = state.items.findIndex((item: PosItem) => item.stock.productId === action.payload);
      if (index !== -1) {
        state.items.splice(index, 1);
        delete state.itemIds[action.payload];
        posSlice.caseReducers.updateTotals(state);
      }
    },
    removeManualSaleProduct: (state: PosState, action: PayloadAction<number>) => {
      const index: number = action.payload;
      state.manualSaleProducts.splice(index, 1);
      posSlice.caseReducers.updateTotals(state);
    },
    setItemUnits: (state: PosState, action: PayloadAction<{ productId: number; units: number }>) => {
      const index: number = state.items.findIndex((item: PosItem) => item.stock.productId === action.payload.productId);
      if (index > -1) {
        state.items[index].units = action.payload.units;
        state.items[index].total = roundTwoDecimals(state.items[index].units * state.items[index].discountedUnitPrice);
        posSlice.caseReducers.updateTotals(state);
      }
    },
    setManualSaleProductUnits: (state: PosState, action: PayloadAction<{ index: number; units: number }>) => {
      const index: number = action.payload.index;
      state.manualSaleProducts[index].quantity = action.payload.units;
      let pvp = 0;
      if (state.manualSaleProducts[index].pvp !== null) {
        pvp = state.manualSaleProducts[index].pvp as number;
      }
      let discount = 0;
      if (state.manualSaleProducts[index].discountPercentage !== null && state.manualSaleProducts[index].discountPercentage! > 0) {
        discount = (state.manualSaleProducts[index].discountPercentage! * pvp) / 100;
      }
      let quantity = 0;
      if (state.manualSaleProducts[index].quantity !== null) {
        quantity = state.manualSaleProducts[index].quantity as number;
      }
      const total: number = quantity * (pvp - discount);
      state.manualSaleProducts[index].total = roundTwoDecimals(total);
      posSlice.caseReducers.updateTotals(state);
    },
    setItemDiscount: (state: PosState, action: PayloadAction<{ productId: number; discount: number }>) => {
      const index: number = state.items.findIndex((item: PosItem) => item.stock.productId === action.payload.productId);
      if (index > -1) {
        state.items[index].discountValue = action.payload.discount;
        if (action.payload.discount > 0) {
          state.items[index].discountedUnitPrice = state.items[index].unitPrice - (state.items[index].unitPrice * action.payload.discount) / 100;
        }
        state.items[index].total = roundTwoDecimals(state.items[index].units * state.items[index].discountedUnitPrice);
        posSlice.caseReducers.updateTotals(state);
      }
    },
    setManualSaleProductDiscount: (state: PosState, action: PayloadAction<{ index: number; discount: number }>) => {
      const index: number = action.payload.index;
      state.manualSaleProducts[index].discountPercentage = action.payload.discount;
      let pvp = 0;
      if (state.manualSaleProducts[index].pvp !== null) {
        pvp = state.manualSaleProducts[index].pvp as number;
      }
      let discount = 0;
      if (state.manualSaleProducts[index].discountPercentage !== null && state.manualSaleProducts[index].discountPercentage! > 0) {
        discount = (action.payload.discount * pvp) / 100;
      }
      let quantity = 0;
      if (state.manualSaleProducts[index].quantity !== null) {
        quantity = state.manualSaleProducts[index].quantity as number;
      }
      const total: number = quantity * (pvp - discount);
      state.manualSaleProducts[index].total = roundTwoDecimals(total);
      posSlice.caseReducers.updateTotals(state);
    },
    removeItemDiscount: (state: PosState, action: PayloadAction<number>) => {
      const index: number = state.items.findIndex((item: PosItem) => item.stock.productId === action.payload);
      if (index > -1) {
        state.items[index].discountValue = 0;
        state.items[index].discountedUnitPrice = state.items[index].unitPrice;
        state.items[index].total = state.items[index].units * state.items[index].discountedUnitPrice;
        posSlice.caseReducers.updateTotals(state);
      }
    },
    removeManualSaleProductDiscount: (state: PosState, action: PayloadAction<number>) => {
      const index: number = action.payload;
      state.manualSaleProducts[index].discountPercentage = 0;
      let pvp = 0;
      if (state.manualSaleProducts[index].pvp !== null) {
        pvp = state.manualSaleProducts[index].pvp as number;
      }
      let quantity = 0;
      if (state.manualSaleProducts[index].quantity !== null) {
        quantity = state.manualSaleProducts[index].quantity as number;
      }
      const total: number = quantity * pvp;
      state.manualSaleProducts[index].total = roundTwoDecimals(total);
      posSlice.caseReducers.updateTotals(state);
    },
    setItemTotal: (state: PosState, action: PayloadAction<{ productId: number; total: number }>) => {
      const index: number = state.items.findIndex((item: PosItem) => item.stock.productId === action.payload.productId);
      if (index > -1) {
        state.items[index].total = action.payload.total;
        state.items[index].discountValue = roundTwoDecimals(100 - (action.payload.total * 100) / (state.items[index].unitPrice * state.items[index].units));
        state.items[index].discountedUnitPrice = roundTwoDecimals(action.payload.total / state.items[index].units);
        posSlice.caseReducers.updateTotals(state);
      }
    },
    setManualSaleProductTotal: (state: PosState, action: PayloadAction<{ index: number; total: number }>) => {
      const index: number = action.payload.index;
      state.manualSaleProducts[index].total = action.payload.total;
      let pvp = 0;
      if (state.manualSaleProducts[index].pvp !== null) {
        pvp = state.manualSaleProducts[index].pvp as number;
      }
      let quantity = 0;
      if (state.manualSaleProducts[index].quantity !== null) {
        quantity = state.manualSaleProducts[index].quantity as number;
      }
      state.manualSaleProducts[index].discountPercentage = roundTwoDecimals(100 - (action.payload.total * 100) / (pvp * quantity));
      posSlice.caseReducers.updateTotals(state);
    },
    clearShoppingCart: (state: PosState) => {
      state.items = [];
      state.manualSaleProducts = [];
      state.itemIds = {};
      state.total = 0;
      state.totalIva = 0;
      state.step = PosStep.AddingProducts;
      state.balance = null;
      posSlice.caseReducers.calculatePendingAndChange(state);
    },
    setStep: (state: PosState, action: PayloadAction<PosStep>) => {
      state.step = action.payload;
    },
    setPaymentMethodAmount: (state: PosState, action: PayloadAction<{ paymentMethodId: number; amount: number | null }>) => {
      if (!action.payload.amount) {
        delete state.paymentMethods[action.payload.paymentMethodId];
      } else {
        state.paymentMethods[action.payload.paymentMethodId] = action.payload.amount;
      }
      posSlice.caseReducers.calculatePendingAndChange(state);
    },
    setChangeInBalance: (state: PosState, action: PayloadAction<boolean>) => {
      state.changeInBalance = action.payload;
    },
    calculatePendingAndChange: (state: PosState) => {
      let pending = state.total;
      for (const paymentMethodId in state.paymentMethods) {
        if (state.paymentMethods.hasOwnProperty(paymentMethodId)) {
          pending -= state.paymentMethods[paymentMethodId];
        }
      }
      for (const bookingPayment of state.bookingPayments) {
        pending -= bookingPayment.amount;
      }
      state.pending = Math.max(0, roundTwoDecimals(pending));
      let change = 0;
      if (pending < 0) {
        change = -pending;
      }
      state.changeToClient = roundTwoDecimals(change);
    },
    setStatus: (state: PosState, action: PayloadAction<SaleStatus>) => {
      if (action.payload === SaleStatus.Pending) {
        state.paymentMethods = {};
        for (const bookingPayment of state.bookingPayments) {
          state.paymentMethods[bookingPayment.paymentMethodId] = bookingPayment.amount;
        }
        state.bookingPayments = [];
      } else if (action.payload === SaleStatus.Reservation) {
        state.bookingPayments = [];
        for (const paymentMethodId in state.paymentMethods) {
          if (state.paymentMethods.hasOwnProperty(paymentMethodId)) {
            state.bookingPayments.push({
              paymentMethodId: parseInt(paymentMethodId, 10),
              amount: state.paymentMethods[paymentMethodId],
            });
          }
        }
        state.paymentMethods = {};
      } else {
        state.bookingPayments = [];
        state.paymentMethods = {};
      }
      state.status = action.payload;
      posSlice.caseReducers.calculatePendingAndChange(state);
    },
    setBookingPaymentAmount: (state: PosState, action: PayloadAction<{ paymentMethodId: number; amount: number | null }>) => {
      let index: number = state.bookingPayments.findIndex((bookingPayment: BookingPaymentDto) => bookingPayment.paymentMethodId === action.payload.paymentMethodId);
      if (action.payload.amount !== null && action.payload.amount > 0) {
        if (index !== -1) {
          state.bookingPayments[index].amount = action.payload.amount;
        } else {
          state.bookingPayments.push({
            paymentMethodId: action.payload.paymentMethodId,
            amount: action.payload.amount,
          });
        }
      } else {
        if (index !== -1) {
          state.bookingPayments.splice(index, 1);
        }
      }
      posSlice.caseReducers.calculatePendingAndChange(state);
    },
    setManualProductPendingToAdd: (state: PosState, action: PayloadAction<boolean>) => {
      state.manualProductPendingToAdd = action.payload;
    },
    setTotal: (state: PosState, payloadAction: PayloadAction<number>) => {
      const newTotal = payloadAction.payload;
      const originalCartTotal =
        state.items.reduce((acc, item) => {
          const effectiveUnitPrice = item.discountedUnitPrice ?? item.unitPrice;
          return acc + effectiveUnitPrice * item.units;
        }, 0) +
        state.manualSaleProducts.reduce((acc, manualSaleProduct) => {
          const effectivePvp = manualSaleProduct.discountPercentage ? manualSaleProduct.pvp! * (1 - manualSaleProduct.discountPercentage / 100) : manualSaleProduct.pvp || 0;
          return acc + effectivePvp * (manualSaleProduct.quantity || 0);
        }, 0);
      state.items.forEach((item: PosItem) => {
        const basePrice = item.discountedUnitPrice ?? item.unitPrice;
        const fullPriceTotal = basePrice * item.units;
        const proportion = fullPriceTotal / originalCartTotal;
        const newItemTotal = newTotal * proportion;
        const newDiscountedUnitPrice = roundTwoDecimals(newItemTotal / item.units);
        item.discountedUnitPrice = newDiscountedUnitPrice;
        item.total = roundTwoDecimals(item.units * newDiscountedUnitPrice);
        const discountFactor = newDiscountedUnitPrice / item.unitPrice;
        item.discountValue = discountFactor < 1 ? roundTwoDecimals((1 - discountFactor) * 100) : roundTwoDecimals(discountFactor * 100);
        if (item.discountValue === 100) {
          item.discountValue = 0;
        }
      });
      state.manualSaleProducts.forEach((manualSaleProduct: ManualSaleProductDto) => {
        const basePvp = manualSaleProduct.discountPercentage ? manualSaleProduct.pvp! * (1 - manualSaleProduct.discountPercentage / 100) : manualSaleProduct.pvp || 0;
        const quantity = manualSaleProduct.quantity || 0;
        const fullPriceTotal = basePvp * quantity;
        const proportion = fullPriceTotal / originalCartTotal;
        const newItemTotal = newTotal * proportion;
        manualSaleProduct.total = roundTwoDecimals(newItemTotal);
        const unitOriginalPvp = manualSaleProduct.pvp || 0;
        const unitDiscountedPvp = newItemTotal / quantity;
        const discountFactor = unitDiscountedPvp / unitOriginalPvp;
        manualSaleProduct.discountPercentage = discountFactor < 1 ? roundTwoDecimals((1 - discountFactor) * 100) : roundTwoDecimals(discountFactor * 100);
        if (manualSaleProduct.discountPercentage === 100) {
          manualSaleProduct.discountPercentage = 0;
        }
      });
      posSlice.caseReducers.updateTotals(state);
    },
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createSale.pending, (state: PosState) => {
      state.sale = null;
      state.loading = true;
    });
    builder.addCase(createSale.fulfilled, (state: PosState, action) => {
      state.sale = action.payload;
      state.loading = false;
    });
    builder.addCase(createSale.rejected, (state: PosState) => {
      state.sale = null;
      state.loading = false;
    });
  },
});

export const {
  reset,
  setNotes,
  setCustomer,
  addItem,
  addManualSaleProduct,
  removeItem,
  removeManualSaleProduct,
  setItemUnits,
  setManualSaleProductUnits,
  setItemDiscount,
  setManualSaleProductDiscount,
  removeItemDiscount,
  removeManualSaleProductDiscount,
  clearShoppingCart,
  setStep,
  setItemTotal,
  setManualSaleProductTotal,
  setPaymentMethodAmount,
  setBalance,
  setChangeInBalance,
  setStatus,
  setBookingPaymentAmount,
  setManualProductPendingToAdd,
  setTotal,
} = posSlice.actions;

export default posSlice.reducer;

export const posSelector = (state: RootState) => state.pos;

export const posSaga = function* () {
  yield takeLatest(posSlice.actions.setCustomer, getCustomerBalance);
};
