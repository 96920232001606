import clsx from 'clsx';
import React, { useState } from 'react';
import { ChevronLeft, Plus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { PosStep } from '../enums/pos-step';
import { SaleStatus } from '../enums/sale-status';
import { Customer } from '../interfaces/customer';
import { posSelector, setCustomer, setNotes, setStep } from '../store/pos-slice';
import CustomerInfo from './customer-info';
import NoteModal from './note-modal';
import { PosProductsTable } from './pos-products-table';
import PosSubtotals from './pos-subtotals';

const PosSummary = () => {
  const dispatch = useDispatch();
  const { balance, customer, notes, status } = useSelector(posSelector);
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false);

  const onCloseNoteModal = () => {
    setShowNoteModal(false);
  };

  const onSaveNoteModal = (notes: string | null) => {
    dispatch(setNotes(notes));
    setShowNoteModal(false);
  };

  const onDeleteNoteModal = () => {
    dispatch(setNotes(null));
    setShowNoteModal(false);
  };

  return (
    <React.Fragment>
      <div className="sale-summary pos d-flex flex-column h-100 p-4">
        <div className="d-flex justify-content-between align-items-start mb-3 noselect">
          <span onClick={() => dispatch(setStep(PosStep.AddingProducts))} className="d-flex align-items-center come-back" title="Volver al listado de productos">
            <ChevronLeft className="me-2" size={14} />
            Volver
          </span>
          <h1 className="mb-0">{status === SaleStatus.Pending ? 'Venta' : 'Reserva'}</h1>
          <div onClick={() => setShowNoteModal(true)} className={clsx('d-flex align-items-center button-header', { 'has-notes': notes != null && notes !== '' })}>
            {notes != null && notes !== '' ? (
              '1 Nota'
            ) : (
              <>
                <Plus className="me-1" size={18} />
                <span>Nota</span>
              </>
            )}
          </div>
        </div>
        <PosProductsTable enableActions={false} />
        <PosSubtotals />
        {customer && <CustomerInfo customer={customer} onAssignCustomer={(newCustomer: Customer) => dispatch(setCustomer(newCustomer))} balance={balance} />}
      </div>
      <NoteModal editable={true} show={showNoteModal} notes={notes} closeModal={onCloseNoteModal} saveNotes={onSaveNoteModal} deleteNotes={onDeleteNoteModal} />
    </React.Fragment>
  );
};

export default PosSummary;
