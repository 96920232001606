import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { put, select, takeLatest } from 'redux-saga/effects';
import { SaleStatus } from '../enums/sale-status';
import { SaleStep } from '../enums/sale-step';
import { Balance } from '../interfaces/balance';
import { BookingPaymentDto } from '../interfaces/booking-payment.dto';
import { CancelSaleDto } from '../interfaces/cancel-sale.dto';
import { Customer } from '../interfaces/customer';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { ManualSaleProduct } from '../interfaces/manual-sale-product';
import { ManualSaleProductDto } from '../interfaces/manual-sale-product.dto';
import { PosItem } from '../interfaces/pos-item';
import { PreviosNextSaleDto } from '../interfaces/previos-next-sale.dto';
import { ReassignSaleToCustomerDto } from '../interfaces/reasign-sale-to-customer.dto';
import { ReplaceManualSaleProductDto } from '../interfaces/replace-manual-sale-product.dto';
import { ReturnDto } from '../interfaces/return.dto';
import { Sale } from '../interfaces/sale';
import { SalePaymentMethodDto } from '../interfaces/sale-payment-method.dto';
import { SaleProduct } from '../interfaces/sale-product';
import { Stock } from '../interfaces/stock';
import { UpdateBookingPaymentDto } from '../interfaces/update-booking-payment.dto';
import { UpdateOnlineStatusSaleDto } from '../interfaces/update-online-status-sale.dto';
import { UpdateSalePaymentMethodDto } from '../interfaces/update-sale-payment-method.dto';
import { UpdateSaleDto } from '../interfaces/update-sale.dto';
import { api } from '../services/api';
import { roundTwoDecimals } from '../services/helpers';
import myToastr from '../services/toastr';
import { RootState } from './store';

export interface SaleState {
  sales: Sale[];
  sale: Sale | null;
  loading: boolean;
  notes: string | null;
  balance: Balance | null;
  step: SaleStep;
  productToReturnIds: { [productId: number]: boolean };
  manualProductToReturnIds: { [manualProductId: number]: boolean };
  productsToReturn: SaleProduct[];
  manualProductsToReturn: ManualSaleProduct[];
  newProductIds: { [productId: number]: boolean };
  newManualProductIds: { [manualProductId: number]: boolean };
  newProducts: PosItem[];
  newManualSaleProducts: ManualSaleProductDto[];
  total: number;
  totalIva: number;
  totalDiscount: number;
  pending: number;
  change: number;
  saveChangeInBalance: boolean;
  returnPaymentMethods: { [paymentMethodId: number]: number };
  paymentMethods: { [paymentMethodId: number]: number };
  cash: number | null;
  availableUnits: { [productId: number]: number };
  manualAvailableUnits: { [name: string]: number };
  customer: Customer | null;
  bookingPayments: BookingPaymentDto[];
  selectedProductIds: { [productId: number]: boolean };
  selectedManualProductIds: { [manualProductId: number]: boolean };
  requesting: boolean;
  previosNextSale: PreviosNextSaleDto | null;
  manualProductPendingToAdd: boolean;
}

const initialState: SaleState = {
  sales: [],
  sale: null,
  loading: false,
  notes: null,
  balance: null,
  step: SaleStep.Viewing,
  productToReturnIds: {},
  manualProductToReturnIds: {},
  productsToReturn: [],
  manualProductsToReturn: [],
  newProductIds: {},
  newManualProductIds: {},
  newProducts: [],
  newManualSaleProducts: [],
  total: 0,
  totalIva: 0,
  totalDiscount: 0,
  pending: 0,
  change: 0,
  saveChangeInBalance: false,
  returnPaymentMethods: {},
  paymentMethods: {},
  cash: 0,
  availableUnits: {},
  manualAvailableUnits: {},
  customer: null,
  bookingPayments: [],
  selectedProductIds: {},
  selectedManualProductIds: {},
  requesting: false,
  previosNextSale: null,
  manualProductPendingToAdd: false,
};

function* getCustomerBalance(): any {
  try {
    const state: RootState = yield select();
    const customerId: number = state.sale.customer!.id;
    const balance: Balance = yield api.getCustomerBalance(customerId);
    yield put(saleSlice.actions.setBalance(balance));
  } catch (e) {
    yield put(saleSlice.actions.setBalance(null));
  }
}

export const updateSaleNotes = createAsyncThunk('sale/updateSaleNotes', async (args: { id: number; notes: string | null }) => {
  return api.updateSaleNotes(args.id, args.notes);
});

export const createSaleReturn = createAsyncThunk('sale/return', async (pin: string, thunkApi): Promise<Sale[]> => {
  myToastr.info('Realizando devolución...');
  try {
    thunkApi.dispatch(saleSlice.actions.setRequesting(true));
    const state: RootState = thunkApi.getState() as RootState;
    const returnPaymentMethods: SalePaymentMethodDto[] = [];
    if (state.sale.total < 0) {
      for (const paymentMethodId in state.sale.returnPaymentMethods) {
        if (state.sale.returnPaymentMethods.hasOwnProperty(paymentMethodId) && state.sale.returnPaymentMethods[paymentMethodId] !== 0) {
          returnPaymentMethods.push({
            paymentMethodId: parseInt(paymentMethodId, 10),
            amount: -state.sale.returnPaymentMethods[paymentMethodId],
          });
        }
      }
    }
    const returnDto: ReturnDto = {
      organizationId: state.auth.organization!.id,
      pin,
      notes: state.sale.notes,
      storeId: state.store.store!.id,
      saleId: state.sale.sale!.id,
      productsToReturn: state.sale.productsToReturn.map((product: SaleProduct) => {
        return {
          productId: product.productId,
          quantity: product.quantity,
        };
      }),
      manualProductsToReturn: state.sale.manualProductsToReturn.map((product: ManualSaleProduct) => {
        return {
          manualSaleProductId: product.id,
          quantity: product.quantity,
        };
      }),
      changeToClient: state.sale.change,
      changeInBalance: state.sale.total < 0 ? true : state.sale.saveChangeInBalance,
      returnPaymentMethods,
    };
    if (state.sale.newProducts.length > 0 || state.sale.newManualSaleProducts.length > 0) {
      const salePaymentMethods: SalePaymentMethodDto[] = [];
      for (const paymentMethodId in state.sale.paymentMethods) {
        if (state.sale.paymentMethods.hasOwnProperty(paymentMethodId)) {
          salePaymentMethods.push({
            paymentMethodId: parseInt(paymentMethodId, 10),
            amount: state.sale.paymentMethods[paymentMethodId],
          });
        }
      }
      returnDto.newSale = {
        saleProducts: state.sale.newProducts.map((product: PosItem) => {
          return {
            quantity: product.units,
            discountPercentage: product.discountValue,
            productId: product.stock.productId,
            total: product.total,
          };
        }),
        manualSaleProducts: state.sale.newManualSaleProducts,
        salePaymentMethods,
      };
    }
    const sales: Sale[] = await api.makeSaleReturn(returnDto);
    if (sales) {
      myToastr.success('Devolución finalizada');
    }
    thunkApi.dispatch(saleSlice.actions.setRequesting(false));
    return sales;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    thunkApi.dispatch(saleSlice.actions.setRequesting(false));
    return [];
  }
});

export const createReturnOnlineSale = createAsyncThunk('sale/return-online-sale', async (pin: string, thunkApi): Promise<Sale[]> => {
  try {
    myToastr.info('Realizando la devolución en la tienda online. Espere por favor...');
    thunkApi.dispatch(saleSlice.actions.setRequesting(true));
    const state: RootState = thunkApi.getState() as RootState;
    const returnPaymentMethods: SalePaymentMethodDto[] = [];
    if (state.sale.total < 0) {
      for (const paymentMethodId in state.sale.returnPaymentMethods) {
        if (state.sale.returnPaymentMethods.hasOwnProperty(paymentMethodId) && state.sale.returnPaymentMethods[paymentMethodId] !== 0) {
          returnPaymentMethods.push({
            paymentMethodId: parseInt(paymentMethodId, 10),
            amount: -state.sale.returnPaymentMethods[paymentMethodId],
          });
        }
      }
    }
    const returnDto: ReturnDto = {
      organizationId: state.auth.organization!.id,
      pin,
      notes: state.sale.notes,
      storeId: state.sale.sale!.storeId,
      saleId: state.sale.sale!.id,
      productsToReturn: state.sale.productsToReturn.map((product: SaleProduct) => {
        return {
          productId: product.productId,
          quantity: product.quantity,
        };
      }),
      manualProductsToReturn: [],
      changeToClient: state.sale.change,
      changeInBalance: state.sale.total < 0 ? true : state.sale.saveChangeInBalance,
      returnPaymentMethods,
    };
    const sales: Sale[] = await api.makeOnlineSaleReturn(returnDto);
    if (sales) {
      myToastr.success('Devolución finalizada');
    }
    thunkApi.dispatch(saleSlice.actions.setRequesting(false));
    return sales;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    thunkApi.dispatch(saleSlice.actions.setRequesting(false));
    return [];
  }
});

export const updateSale = createAsyncThunk('sale/update', async (pin: string, { getState, dispatch }): Promise<Sale | null> => {
  try {
    dispatch(saleSlice.actions.setRequesting(true));
    const state: RootState = getState() as RootState;
    const updateSaleDto: UpdateSaleDto = {
      organizationId: state.auth.organization!.id,
      pin,
      bookingPayments: state.sale.bookingPayments,
      changeToClient: state.sale.change,
      changeInBalance: state.sale.saveChangeInBalance,
    };
    const sale: Sale = await api.updateSale(state.sale.sale!.id, updateSaleDto);
    dispatch(saleSlice.actions.reset());
    dispatch(saleSlice.actions.setSale(sale));
    const previosNextSaleDto: PreviosNextSaleDto = await api.getPreviosNextSale(sale.id);
    dispatch(saleSlice.actions.setPreviousNextSale(previosNextSaleDto));
    if (sale.status === SaleStatus.Finalized) {
      myToastr.success('Venta finalizadda');
    } else {
      myToastr.success('Reserva actualizada');
    }
    dispatch(saleSlice.actions.setRequesting(false));
    return sale;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(saleSlice.actions.setRequesting(false));
    return null;
  }
});

export const cancelSale = createAsyncThunk('sale/cancel', async (cancelSaleDto: CancelSaleDto, { getState, dispatch }): Promise<Sale | null> => {
  try {
    dispatch(saleSlice.actions.setRequesting(true));
    myToastr.info('Cancelando la reserva...');
    const state: RootState = getState() as RootState;
    const sale: Sale = await api.cancelSale(state.sale.sale!.id, cancelSaleDto);
    dispatch(saleSlice.actions.reset());
    dispatch(saleSlice.actions.setSale(sale));
    const previosNextSaleDto: PreviosNextSaleDto = await api.getPreviosNextSale(sale.id);
    dispatch(saleSlice.actions.setPreviousNextSale(previosNextSaleDto));
    myToastr.success('Reserva cancelada');
    dispatch(saleSlice.actions.setRequesting(false));
    return sale;
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(saleSlice.actions.setRequesting(false));
    return null;
  }
});

export const updateSaleCustomer = createAsyncThunk('sale/reassign', async (customerId: number, { getState, dispatch }): Promise<Sale | null> => {
  try {
    dispatch(saleSlice.actions.setRequesting(true));
    const state: RootState = getState() as RootState;
    const reassignSaleToCustomerDto: ReassignSaleToCustomerDto = {
      customerId,
    };
    const sale: Sale = await api.updateSaleCustomer(state.sale.sale!.id, reassignSaleToCustomerDto);
    dispatch(saleSlice.actions.setSale(sale));
    if (sale.status === SaleStatus.Return) {
      myToastr.success('Cliente de la devolución actualizado');
    } else {
      myToastr.success('Cliente de la venta actualizado');
    }
    dispatch(saleSlice.actions.setRequesting(false));
    return sale;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(saleSlice.actions.setRequesting(false));
    return null;
  }
});

export const updateOnlineStatusSale = createAsyncThunk('sale/update-online-status', async (updateOnlineStatusSaleDto: UpdateOnlineStatusSaleDto, { getState, dispatch }): Promise<Sale | null> => {
  try {
    myToastr.info('Actualizando el estado del pedido en la tienda online. Este proceso puede llevar unos segundos. Espere por favor...');
    dispatch(saleSlice.actions.setRequesting(true));
    const state: RootState = getState() as RootState;
    const sale: Sale = await api.updateOnlineStatusSale(state.sale.sale!.id, updateOnlineStatusSaleDto);
    dispatch(saleSlice.actions.reset());
    dispatch(saleSlice.actions.setSale(sale));
    const previosNextSaleDto: PreviosNextSaleDto = await api.getPreviosNextSale(sale.id);
    dispatch(saleSlice.actions.setPreviousNextSale(previosNextSaleDto));
    myToastr.success('Estado actualizado');
    dispatch(saleSlice.actions.setRequesting(false));
    return sale;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(saleSlice.actions.setRequesting(false));
    return null;
  }
});

export const updateSalePaymentMethod = createAsyncThunk(
  'sale/update-sale-payment-method',
  async (updateSalePaymentMethodDto: UpdateSalePaymentMethodDto, { dispatch, getState }): Promise<Sale | null> => {
    try {
      dispatch(saleSlice.actions.setRequesting(true));
      const state: RootState = getState() as RootState;
      const sale: Sale = await api.updateSalePaymentMethod(state.sale.sale!.id, updateSalePaymentMethodDto);
      dispatch(saleSlice.actions.reset());
      dispatch(saleSlice.actions.setSale(sale));
      const previosNextSaleDto: PreviosNextSaleDto = await api.getPreviosNextSale(sale.id);
      dispatch(saleSlice.actions.setPreviousNextSale(previosNextSaleDto));
      dispatch(saleSlice.actions.setRequesting(false));
      myToastr.success('Método de pago actualizado');
      return sale;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
      dispatch(saleSlice.actions.setRequesting(false));
      return null;
    }
  },
);

export const updateBookingPayment = createAsyncThunk('sale/update-sale-booking-payment', async (updateBookingPaymentDto: UpdateBookingPaymentDto, { dispatch, getState }): Promise<Sale | null> => {
  try {
    dispatch(saleSlice.actions.setRequesting(true));
    const state: RootState = getState() as RootState;
    const sale: Sale = await api.updateBookingPayment(state.sale.sale!.id, updateBookingPaymentDto);
    dispatch(saleSlice.actions.reset());
    dispatch(saleSlice.actions.setSale(sale));
    const previosNextSaleDto: PreviosNextSaleDto = await api.getPreviosNextSale(sale.id);
    dispatch(saleSlice.actions.setPreviousNextSale(previosNextSaleDto));
    dispatch(saleSlice.actions.setRequesting(false));
    myToastr.success('Método de pago actualizado');
    return sale;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(saleSlice.actions.setRequesting(false));
    return null;
  }
});

export const replaceManualSaleProduct = createAsyncThunk('sale/replace-manual-sale-product', async (replaceManualSaleProductDto: ReplaceManualSaleProductDto, { dispatch }): Promise<Sale | null> => {
  try {
    dispatch(saleSlice.actions.setRequesting(true));
    const sale: Sale = await api.replaceManualSaleProduct(replaceManualSaleProductDto);
    dispatch(saleSlice.actions.reset());
    dispatch(saleSlice.actions.setSale(sale));
    const previosNextSaleDto: PreviosNextSaleDto = await api.getPreviosNextSale(sale.id);
    dispatch(saleSlice.actions.setPreviousNextSale(previosNextSaleDto));
    dispatch(saleSlice.actions.setRequesting(false));
    myToastr.success('Producto reemplazado');
    return sale;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const axiosError: AxiosError = e as AxiosError;
      if (axiosError.response?.data) {
        const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
        myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
      }
    }
    dispatch(saleSlice.actions.setRequesting(false));
    return null;
  }
});

export const saleSlice = createSlice({
  name: 'sale',
  initialState,
  reducers: {
    setSale: (state: SaleState, action: PayloadAction<Sale>) => {
      state.sale = action.payload;
      state.notes = action.payload.notes;
      state.sale.saleProducts.forEach((saleProduct: SaleProduct) => {
        state.availableUnits[saleProduct.productId] = saleProduct.quantity;
      });
      state.sale.manualSaleProducts.forEach((manualSaleProduct: ManualSaleProduct) => {
        state.manualAvailableUnits[manualSaleProduct.name.toLowerCase()] = manualSaleProduct.quantity;
      });
      state.sale.returns.forEach((returnSale: Sale) => {
        returnSale.saleProducts.forEach((returnSaleProduct: SaleProduct) => {
          state.availableUnits[returnSaleProduct.productId] += returnSaleProduct.quantity;
        });
        returnSale.manualSaleProducts.forEach((returnManualSaleProduct: ManualSaleProduct) => {
          state.manualAvailableUnits[returnManualSaleProduct.name.toLowerCase()] += returnManualSaleProduct.quantity;
        });
      });
      state.customer = action.payload.customer;
      saleSlice.caseReducers.calculatePendingAndChange(state);
    },
    setNotes: (state: SaleState, action: PayloadAction<string | null>) => {
      state.notes = action.payload;
    },
    setCustomer: (state: SaleState, action: PayloadAction<Customer | null>) => {
      state.customer = action.payload;
    },
    setBalance: (state: SaleState, action: PayloadAction<Balance | null>) => {
      state.balance = action.payload;
    },
    setStep: (state: SaleState, action: PayloadAction<SaleStep>) => {
      state.step = action.payload;
    },
    clearShoppingCart: (state: SaleState) => {
      state.newProducts = [];
      state.newManualSaleProducts = [];
      state.newProductIds = {};
      state.newManualProductIds = {};
      state.productsToReturn = [];
      state.manualProductsToReturn = [];
      state.productToReturnIds = {};
      state.manualProductToReturnIds = {};
      state.selectedProductIds = {};
      state.selectedManualProductIds = {};
      state.total = 0;
      state.totalIva = 0;
      state.totalDiscount = 0;
      state.step = SaleStep.Viewing;
      state.balance = null;
      saleSlice.caseReducers.calculatePendingAndChange(state);
    },
    updateTotals: (state: SaleState) => {
      let total = 0;
      let totalDiscount = 0;
      state.newProducts.forEach((item: PosItem) => {
        total += item.total;
        totalDiscount += (item.unitPrice - item.discountedUnitPrice) * item.units;
      });
      state.newManualSaleProducts.forEach((item: ManualSaleProductDto) => {
        total += item.total || 0;
        if (item.total !== null && item.pvp !== null && item.quantity !== null) {
          totalDiscount += item.pvp * item.quantity - item.total;
        }
      });
      state.productsToReturn.forEach((item: SaleProduct) => {
        total -= item.total;
      });
      state.manualProductsToReturn.forEach((item: ManualSaleProduct) => {
        total -= item.total;
      });
      state.total = roundTwoDecimals(total);
      state.totalDiscount = Math.max(roundTwoDecimals(totalDiscount), 0);
      state.totalIva = roundTwoDecimals(total - total / ((100 + parseFloat(process.env.REACT_APP_IVA)) / 100));
      saleSlice.caseReducers.calculatePendingAndChange(state);
    },
    addProductToReturn: (state: SaleState, action: PayloadAction<SaleProduct>) => {
      const saleProduct: SaleProduct = { ...action.payload };
      const originalQuantity: number = saleProduct.quantity;
      saleProduct.quantity = 0;
      state.sale!.saleProducts.forEach((sp: SaleProduct) => {
        if (sp.productId === action.payload.productId) {
          saleProduct.quantity = sp.quantity;
        }
      });
      state.sale!.returns.forEach((returnSale: Sale) => {
        returnSale.saleProducts.forEach((returnSaleProduct: SaleProduct) => {
          if (returnSaleProduct.productId === action.payload.productId) {
            saleProduct.quantity += returnSaleProduct.quantity;
          }
        });
      });
      saleProduct.total = roundTwoDecimals((saleProduct.total / originalQuantity) * saleProduct.quantity);
      state.productToReturnIds[saleProduct.productId] = true;
      state.productsToReturn.push(saleProduct);
      saleSlice.caseReducers.updateTotals(state);
    },
    addManualProductToReturn: (state: SaleState, action: PayloadAction<ManualSaleProduct>) => {
      const manualProductToReturn: ManualSaleProduct = { ...action.payload };
      const originalQuantity: number = manualProductToReturn.quantity;
      manualProductToReturn.quantity = 0;
      state.sale!.manualSaleProducts.forEach((manualSaleProduct: ManualSaleProduct) => {
        if (manualSaleProduct.name.toLowerCase() === manualProductToReturn.name.toLowerCase()) {
          manualProductToReturn.quantity = manualSaleProduct.quantity;
        }
      });
      state.sale!.returns.forEach((returnSale: Sale) => {
        returnSale.manualSaleProducts.forEach((returnManualSaleProduct: ManualSaleProduct) => {
          if (returnManualSaleProduct.name.toLowerCase() === manualProductToReturn.name.toLowerCase()) {
            manualProductToReturn.quantity += returnManualSaleProduct.quantity;
          }
        });
      });
      manualProductToReturn.total = roundTwoDecimals((manualProductToReturn.total / originalQuantity) * manualProductToReturn.quantity);
      state.manualProductToReturnIds[manualProductToReturn.id] = true;
      state.manualProductsToReturn.push(manualProductToReturn);
      saleSlice.caseReducers.updateTotals(state);
    },
    removeProductToReturn: (state: SaleState, action: PayloadAction<number>) => {
      const productId: number = action.payload;
      const index: number = state.productsToReturn.findIndex((sp: SaleProduct) => sp.productId === productId);
      if (index !== -1) {
        state.productsToReturn.splice(index, 1);
        delete state.productToReturnIds[productId];
      }
      if (state.productsToReturn.length === 0) {
        state.newProducts = [];
        state.newProductIds = {};
      }
      saleSlice.caseReducers.updateTotals(state);
    },
    removeManualProductToReturn: (state: SaleState, action: PayloadAction<number>) => {
      const manualSaleProductId: number = action.payload;
      const index: number = state.manualProductsToReturn.findIndex((sp: ManualSaleProduct) => sp.id === manualSaleProductId);
      if (index !== -1) {
        state.manualProductsToReturn.splice(index, 1);
        delete state.manualProductToReturnIds[manualSaleProductId];
      }
      if (state.manualProductsToReturn.length === 0) {
        state.newManualSaleProducts = [];
        state.newManualProductIds = {};
      }
      saleSlice.caseReducers.updateTotals(state);
    },
    toggleSelectedProduct: (state: SaleState, action: PayloadAction<number>) => {
      const selectedProductIds: { [key: number]: boolean } = { ...state.selectedProductIds };
      const productId: number = action.payload;
      if (selectedProductIds[productId]) {
        delete selectedProductIds[productId];
      } else {
        selectedProductIds[productId] = true;
      }
      state.selectedProductIds = selectedProductIds;
    },
    toggleSelectedManualProduct: (state: SaleState, action: PayloadAction<number>) => {
      const selectedManualProductIds: { [key: number]: boolean } = { ...state.selectedManualProductIds };
      const manualProductId: number = action.payload;
      if (selectedManualProductIds[manualProductId]) {
        delete selectedManualProductIds[manualProductId];
      } else {
        selectedManualProductIds[manualProductId] = true;
      }
      state.selectedManualProductIds = selectedManualProductIds;
    },
    setQuantityProductToReturn: (state: SaleState, action: PayloadAction<{ productId: number; quantity: number }>) => {
      const { productId, quantity } = action.payload;
      const index: number = state.productsToReturn.findIndex((sp: SaleProduct) => sp.productId === productId);
      if (index !== -1) {
        if (quantity === 0) {
          delete state.productToReturnIds[productId];
          delete state.availableUnits[productId];
          state.productsToReturn.splice(index, 1);
        } else {
          const originalSaleProduct: SaleProduct = state.sale!.saleProducts.find((sp: SaleProduct) => sp.productId === productId)!;
          state.productsToReturn[index].quantity = quantity;
          state.productsToReturn[index].total = roundTwoDecimals((originalSaleProduct.total / originalSaleProduct.quantity) * quantity);
        }
        saleSlice.caseReducers.updateTotals(state);
      }
    },
    setQuantityManualProductToReturn: (state: SaleState, action: PayloadAction<{ manualSaleProductId: number; quantity: number }>) => {
      const { manualSaleProductId, quantity } = action.payload;
      const index: number = state.manualProductsToReturn.findIndex((sp: ManualSaleProduct) => sp.id === manualSaleProductId);
      if (index !== -1) {
        if (quantity === 0) {
          delete state.manualProductToReturnIds[manualSaleProductId];
          delete state.manualAvailableUnits[state.manualProductsToReturn[index].name.toLowerCase()];
          state.manualProductsToReturn.splice(index, 1);
        } else {
          const originalManualSaleProduct: ManualSaleProduct = state.sale!.manualSaleProducts.find((msp: ManualSaleProduct) => msp.id === manualSaleProductId)!;
          state.manualProductsToReturn[index].quantity = quantity;
          state.manualProductsToReturn[index].total = roundTwoDecimals((originalManualSaleProduct.total / originalManualSaleProduct.quantity) * quantity);
        }
        saleSlice.caseReducers.updateTotals(state);
      }
    },
    addNewProduct: (state: SaleState, action: PayloadAction<Stock>) => {
      const stock: Stock = action.payload;
      const index: number = state.newProducts.findIndex((sp: PosItem) => sp.stock.productId === stock.productId);
      if (index !== -1) {
        state.newProducts[index].units++;
        state.newProducts[index].total = roundTwoDecimals(state.newProducts[index].units * state.newProducts[index].unitPrice);
      } else {
        let unitPrice: number;
        if (stock.discountValue > 0) {
          unitPrice = stock.pvp - (stock.pvp * stock.discountValue) / 100;
        } else {
          unitPrice = stock.pvp;
        }
        state.newProducts.push({
          stock,
          units: 1,
          discountValue: 0,
          unitPrice,
          discountedUnitPrice: unitPrice,
          total: unitPrice,
        });
      }
      state.newProductIds[action.payload.productId] = true;
      saleSlice.caseReducers.updateTotals(state);
    },
    addManualNewProduct: (state: SaleState, action: PayloadAction<ManualSaleProductDto>) => {
      const manualSaleProduct: ManualSaleProductDto = action.payload;
      manualSaleProduct.name = manualSaleProduct.name.trim();
      state.newManualSaleProducts.push(manualSaleProduct);
      saleSlice.caseReducers.updateTotals(state);
    },
    removeNewProduct: (state: SaleState, action: PayloadAction<number>) => {
      const index: number = state.newProducts.findIndex((item: PosItem) => item.stock.productId === action.payload);
      if (index !== -1) {
        state.newProducts.splice(index, 1);
        delete state.newProductIds[action.payload];
        saleSlice.caseReducers.updateTotals(state);
      }
    },
    removeManualNewProduct: (state: SaleState, action: PayloadAction<number>) => {
      const index: number = action.payload;
      state.newManualSaleProducts.splice(index, 1);
      saleSlice.caseReducers.updateTotals(state);
    },
    setQuantityNewProduct: (state: SaleState, action: PayloadAction<{ productId: number; units: number }>) => {
      const index: number = state.newProducts.findIndex((item: PosItem) => item.stock.productId === action.payload.productId);
      if (index > -1) {
        state.newProducts[index].units = action.payload.units;
        state.newProducts[index].total = roundTwoDecimals(state.newProducts[index].units * state.newProducts[index].discountedUnitPrice);
        saleSlice.caseReducers.updateTotals(state);
      }
    },
    setQuantityManualNewProduct: (state: SaleState, action: PayloadAction<{ index: number; units: number }>) => {
      state.newManualSaleProducts[action.payload.index].quantity = action.payload.units;
      let pvp = 0;
      if (state.newManualSaleProducts[action.payload.index].pvp !== null) {
        pvp = state.newManualSaleProducts[action.payload.index].pvp as number;
      }
      let discount = 0;
      if (state.newManualSaleProducts[action.payload.index].discountPercentage !== null && state.newManualSaleProducts[action.payload.index].discountPercentage! > 0) {
        discount = (pvp * state.newManualSaleProducts[action.payload.index].discountPercentage!) / 100;
      }
      let quantity = 0;
      if (state.newManualSaleProducts[action.payload.index].quantity !== null) {
        quantity = state.newManualSaleProducts[action.payload.index].quantity as number;
      }
      // state.newManualSaleProducts[action.payload.index].total = roundTwoDecimals((action.payload.units / quantity) * (pvp - discount));
      const total: number = quantity * (pvp - discount);
      state.newManualSaleProducts[action.payload.index].total = roundTwoDecimals(total);
      saleSlice.caseReducers.updateTotals(state);
    },
    setItemDiscount: (state: SaleState, action: PayloadAction<{ productId: number; discount: number }>) => {
      const index: number = state.newProducts.findIndex((item: PosItem) => item.stock.productId === action.payload.productId);
      if (index > -1) {
        state.newProducts[index].discountValue = action.payload.discount;
        if (action.payload.discount > 0) {
          state.newProducts[index].discountedUnitPrice = state.newProducts[index].unitPrice - (state.newProducts[index].unitPrice * action.payload.discount) / 100;
        }
        state.newProducts[index].total = roundTwoDecimals(state.newProducts[index].units * state.newProducts[index].discountedUnitPrice);
        saleSlice.caseReducers.updateTotals(state);
      }
    },
    setManualItemDiscount: (state: SaleState, action: PayloadAction<{ index: number; discount: number }>) => {
      state.newManualSaleProducts[action.payload.index].discountPercentage = action.payload.discount;
      let pvp = 0;
      if (state.newManualSaleProducts[action.payload.index].pvp !== null) {
        pvp = state.newManualSaleProducts[action.payload.index].pvp as number;
      }
      let discount = 0;
      if (action.payload.discount > 0) {
        discount = (pvp * action.payload.discount) / 100;
      }
      let quantity = 0;
      if (state.newManualSaleProducts[action.payload.index].quantity !== null) {
        quantity = state.newManualSaleProducts[action.payload.index].quantity as number;
      }
      state.newManualSaleProducts[action.payload.index].total = roundTwoDecimals(quantity * (pvp - discount));
      saleSlice.caseReducers.updateTotals(state);
    },
    removeItemDiscount: (state: SaleState, action: PayloadAction<number>) => {
      const index: number = state.newProducts.findIndex((item: PosItem) => item.stock.productId === action.payload);
      if (index > -1) {
        state.newProducts[index].discountValue = 0;
        state.newProducts[index].discountedUnitPrice = state.newProducts[index].unitPrice;
        state.newProducts[index].total = state.newProducts[index].units * state.newProducts[index].discountedUnitPrice;
        saleSlice.caseReducers.updateTotals(state);
      }
    },
    removeManualItemDiscount: (state: SaleState, action: PayloadAction<number>) => {
      state.newManualSaleProducts[action.payload].discountPercentage = 0;
      let pvp = 0;
      if (state.newManualSaleProducts[action.payload].pvp !== null) {
        pvp = state.newManualSaleProducts[action.payload].pvp as number;
      }
      let quantity = 0;
      if (state.newManualSaleProducts[action.payload].quantity !== null) {
        quantity = state.newManualSaleProducts[action.payload].quantity as number;
      }
      state.newManualSaleProducts[action.payload].total = quantity * pvp;
      saleSlice.caseReducers.updateTotals(state);
    },
    setItemTotal: (state: SaleState, action: PayloadAction<{ productId: number; total: number }>) => {
      const index: number = state.newProducts.findIndex((item: PosItem) => item.stock.productId === action.payload.productId);
      if (index > -1) {
        state.newProducts[index].total = action.payload.total;
        state.newProducts[index].discountValue = roundTwoDecimals(1 - action.payload.total / (state.newProducts[index].discountedUnitPrice * state.newProducts[index].units)) * 100;
        saleSlice.caseReducers.updateTotals(state);
      }
    },
    setManualItemTotal: (state: SaleState, action: PayloadAction<{ index: number; total: number }>) => {
      state.newManualSaleProducts[action.payload.index].total = action.payload.total;
      let pvp = 0;
      if (state.newManualSaleProducts[action.payload.index].pvp !== null) {
        pvp = state.newManualSaleProducts[action.payload.index].pvp as number;
      }
      let quantity = 0;
      if (state.newManualSaleProducts[action.payload.index].quantity !== null) {
        quantity = state.newManualSaleProducts[action.payload.index].quantity as number;
      }
      state.newManualSaleProducts[action.payload.index].discountPercentage = roundTwoDecimals(1 - action.payload.total / (quantity * pvp)) * 100;
      saleSlice.caseReducers.updateTotals(state);
    },
    setPaymentMethodAmount: (state: SaleState, action: PayloadAction<{ paymentMethodId: number; amount: number | null }>) => {
      if (!action.payload.amount) {
        delete state.paymentMethods[action.payload.paymentMethodId];
      } else {
        state.paymentMethods[action.payload.paymentMethodId] = action.payload.amount;
      }
      saleSlice.caseReducers.calculatePendingAndChange(state);
    },
    clearPaymentMethods: (state: SaleState) => {
      state.paymentMethods = {};
      saleSlice.caseReducers.calculatePendingAndChange(state);
    },
    setReturnPaymentMethodAmount: (state: SaleState, action: PayloadAction<{ paymentMethodId: number; amount: number | null }>) => {
      if (!action.payload.amount) {
        delete state.returnPaymentMethods[action.payload.paymentMethodId];
      } else {
        state.returnPaymentMethods[action.payload.paymentMethodId] = action.payload.amount;
      }
      saleSlice.caseReducers.calculatePendingAndChange(state);
    },
    setSaveChangeInBalance: (state: SaleState, action: PayloadAction<boolean>) => {
      state.saveChangeInBalance = action.payload;
    },
    calculatePendingAndChange: (state: SaleState) => {
      let pending: number;
      if (state.sale?.status === SaleStatus.Finalized) {
        if (state.total < 0) {
          // La suma de los artículos es inferior a la compra original
          pending = -state.total;
          for (const paymentMethodId in state.returnPaymentMethods) {
            if (state.returnPaymentMethods.hasOwnProperty(paymentMethodId)) {
              pending -= state.returnPaymentMethods[paymentMethodId];
            }
          }
          state.pending = roundTwoDecimals(pending);
          state.change = 0;
        } else {
          // La suma de los artículos es (devoluciones + cambios) >= 0
          pending = -state.total;
          for (const paymentMethodId in state.paymentMethods) {
            if (state.paymentMethods.hasOwnProperty(paymentMethodId)) {
              pending += state.paymentMethods[paymentMethodId];
            }
          }
          if (pending > 0) {
            state.pending = 0;
            state.change = roundTwoDecimals(pending);
          } else {
            state.pending = roundTwoDecimals(pending);
            state.change = 0;
          }
        }
      } else if (state.sale?.status === SaleStatus.Reservation) {
        pending = state.sale.total;
        // Pagos previos de la reserva
        for (const bookingPayment of state.sale.bookingPayments) {
          pending -= bookingPayment.amount;
        }
        // Nuevos pagos de la reserva
        for (const bookingPayment of state.bookingPayments) {
          pending -= bookingPayment.amount;
        }
        state.total = state.sale.total;
        state.change = 0;
        pending = roundTwoDecimals(pending);
        state.pending = Math.max(0, pending);
        if (pending < 0) {
          state.change = roundTwoDecimals(Math.abs(pending));
        }
      }
    },
    setBookingPaymentAmount: (state: SaleState, action: PayloadAction<{ paymentMethodId: number; amount: number | null }>) => {
      let index: number = state.bookingPayments.findIndex((bookingPayment: BookingPaymentDto) => bookingPayment.paymentMethodId === action.payload.paymentMethodId);
      if (action.payload.amount !== null && action.payload.amount > 0) {
        if (index !== -1) {
          state.bookingPayments[index].amount = action.payload.amount;
        } else {
          state.bookingPayments.push({
            paymentMethodId: action.payload.paymentMethodId,
            amount: action.payload.amount,
          });
        }
      } else {
        if (index !== -1) {
          state.bookingPayments.splice(index, 1);
        }
      }
      saleSlice.caseReducers.calculatePendingAndChange(state);
    },
    setRequesting: (state: SaleState, action: PayloadAction<boolean>) => {
      state.requesting = action.payload;
    },
    setPreviousNextSale: (state: SaleState, action: PayloadAction<PreviosNextSaleDto | null>) => {
      state.previosNextSale = action.payload;
    },
    setManualProductPendingToAdd: (state: SaleState, action: PayloadAction<boolean>) => {
      state.manualProductPendingToAdd = action.payload;
    },
    reset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSaleNotes.fulfilled, (state, action) => {
      state.notes = action.payload.notes;
    });
    builder.addCase(createSaleReturn.fulfilled, (state, action) => {
      state.sales = action.payload;
    });
    builder.addCase(createReturnOnlineSale.fulfilled, (state, action) => {
      state.sales = action.payload;
    });
  },
});

export const {
  setNotes,
  setSale,
  setCustomer,
  setStep,
  addProductToReturn,
  addManualProductToReturn,
  removeProductToReturn,
  removeManualProductToReturn,
  toggleSelectedProduct,
  toggleSelectedManualProduct,
  setQuantityProductToReturn,
  setQuantityManualProductToReturn,
  addNewProduct,
  addManualNewProduct,
  removeNewProduct,
  removeManualNewProduct,
  setQuantityNewProduct,
  setQuantityManualNewProduct,
  reset,
  setPaymentMethodAmount,
  clearPaymentMethods,
  setReturnPaymentMethodAmount,
  setSaveChangeInBalance,
  clearShoppingCart,
  setItemDiscount,
  setManualItemDiscount,
  setItemTotal,
  setManualItemTotal,
  removeItemDiscount,
  removeManualItemDiscount,
  setBookingPaymentAmount,
  setPreviousNextSale,
  setManualProductPendingToAdd,
} = saleSlice.actions;

export default saleSlice.reducer;

export const saleSelector = (state: RootState) => state.sale;

export const saleSaga = function* () {
  yield takeLatest(saleSlice.actions.setSale, getCustomerBalance);
};
