import moment from 'moment';
import printJS from 'print-js';
import { isAndroid } from 'react-device-detect';
import { CashMovementType } from '../enums/cash-movement-type.enum';
import { DateRange } from '../enums/date-range';
import { DeliveryNoteStatus } from '../enums/delivery-note-status';
import { ExpirationStatus } from '../enums/expiration-status';
import { OrganizationType } from '../enums/organization-type';
import { PurchaseOrderStatus } from '../enums/purchase-order-status';
import { RepairStatus } from '../enums/repair-status';
import { Role } from '../enums/role';
import { UserWorkClockingAction } from '../enums/user-work-clocking-action';
import { Product } from '../interfaces/product';
import { Repair } from '../interfaces/repair';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { UserInOrganization } from '../interfaces/user-in-organization';
import { Constants } from './constants';

export const formatNumber = (x: number | null | undefined, isInt: boolean = false) => {
  if (x === null || x === undefined) {
    return 0;
  }
  const roundedNumber: number = Math.round((x + Number.EPSILON) * 100) / 100;
  const result: string = roundedNumber
    .toFixed(2)
    .toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+\b)/g, '.');
  if (isInt) {
    return result.split(',')[0];
  }
  return result;
};

export const roundTwoDecimals = (value: number): number => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const sameMembers = (arr1: any[], arr2: any[]): boolean => {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  return arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item));
};

export const ucFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getTextAndColorRepairStatus = (repair: Repair) => {
  let text: string = '';
  let color: string = '';
  switch (repair.repairStatus) {
    case RepairStatus.Opened:
      text = 'Abierta';
      color = '#d3d3d3';
      break;
    case RepairStatus.InProcess:
      text = 'En proceso';
      color = '#0092FD';
      break;
    case RepairStatus.Budget:
      text = 'Presupuesto';
      color = '#E09200';
      break;
    case RepairStatus.Finished:
      text = 'Recogida';
      color = '#26C44B';
      break;
    case RepairStatus.Delivered:
      text = 'Entregada';
      color = '#1e963a';
      break;
    case RepairStatus.Canceled:
      text = 'Cancelada';
      color = '#E02760';
      break;
  }
  return { color, text };
};

export const isValidUrl = (str: string): boolean => {
  let url: URL;
  try {
    url = new URL(str);
  } catch (e: any) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const productIsEditable = (user: User | null, product: Product | null): boolean => {
  if (!user || !product) {
    return false;
  }
  if (user.role === Role.SuperAdmin) {
    return true;
  }
  if (!product.editable) {
    return false;
  }
  if (user.role === Role.Admin || user.role === Role.Manager) {
    const index: number = user.userInOrganizations.findIndex((userInOrganization: UserInOrganization) => userInOrganization.organizationId === product.organizationId);
    if (index !== -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getDatesGivenDateRange = (dateRange: DateRange): [Date | null, Date | null] => {
  switch (dateRange) {
    case DateRange.Today:
      return [moment().startOf('day').toDate(), moment().endOf('day').toDate()];
    case DateRange.Yesterday:
      return [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()];
    case DateRange.ThisWeek:
      return [moment().startOf('week').toDate(), moment().endOf('week').toDate()];
    case DateRange.ThisMonth:
      return [moment().startOf('month').toDate(), moment().endOf('week').toDate()];
    case DateRange.ThisYear:
      return [moment().startOf('year').toDate(), moment().endOf('year').toDate()];
    case DateRange.LastYear:
      return [moment().subtract(1, 'years').startOf('year').toDate(), moment().subtract(1, 'years').endOf('year').toDate()];
    case DateRange.All:
      return [null, null];
    default:
      return [moment().startOf('day').toDate(), moment().startOf('day').toDate()];
  }
};

export const expirationStatusToString = (status: ExpirationStatus): string => {
  switch (status) {
    case ExpirationStatus.Pending:
      return 'Pendiente';
    case ExpirationStatus.Paid:
      return 'Pagado';
    case ExpirationStatus.Detained:
      return 'Retenido';
    case ExpirationStatus.Compensated:
      return 'Compensado';
  }
};

export const toFixedTrunc = (x: number, n: number): string => {
  const v = (typeof x === 'string' ? x : x.toString()).split('.');
  if (n <= 0) return v[0];
  let f = v[1] || '';
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
  while (f.length < n) f += '0';
  return `${v[0]}.${f}`;
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const replaceDiacriticAccents = (str: string): string => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const isEmail = (str: string | null): boolean => {
  if (!str) {
    return false;
  }
  const re = /\S+@\S+\.\S+/;
  return re.test(str);
};

export const slugify = (str: string | null | undefined): string => {
  if (!str) {
    return '';
  }
  return str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};

export const storesByName = (stores: Store[]): Store[] => {
  return [...stores].sort((a: Store, b: Store) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const cashMovementToString = (cashMovementType: CashMovementType): string => {
  switch (cashMovementType) {
    case CashMovementType.Sale:
      return 'Venta';
    case CashMovementType.Reservation:
      return 'Reserva';
    case CashMovementType.Repair:
      return 'Reparación';
    case CashMovementType.Withdrawal:
      return 'Retirada';
    default:
      return 'Desconocido';
  }
};

export const truncateText = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  } else {
    return text;
  }
};

export const downloadPdf = (arrayBuffer: Buffer | ArrayBuffer, fileName: string) => {
  const urlPdf: string = window.URL.createObjectURL(new Blob([arrayBuffer], { type: 'application/pdf' }));
  if (isAndroid) {
    const link = document.createElement('a');
    link.setAttribute('href', urlPdf);
    link.setAttribute('download', fileName);
    link.click();
    link.remove();
  } else {
    printJS(urlPdf);
  }
};

export const minutesToHourAndMinutesStr = (minutes: number) => {
  const hours: number = Math.floor(minutes / 60);
  const restMinutes: number = minutes % 60;
  return `${hours.toString().padStart(2, '0')}h ${restMinutes.toString().padStart(2, '0')}m`;
};

export const organizationTypeToString = (organizationType: OrganizationType): string => {
  switch (organizationType) {
    case OrganizationType.ALL:
      return 'B2B + B2C';
    case OrganizationType.B2C:
      return 'B2C';
    case OrganizationType.B2B:
      return 'B2B';
    default:
      return '';
  }
};

export const roleToString = (role: Role | null) => {
  switch (role) {
    case Role.Business:
      return 'Comercial';
    case Role.Seller:
      return 'Seller';
    case Role.Admin:
      return 'Admin';
    case Role.Manager:
      return 'Manager';
    case Role.SuperAdmin:
      return 'Super Admin';
    case Role.WholesaleCustomer:
      return 'Cliente';
    default:
      return '';
  }
};

export const getDefaultPathGivenRole = (role: Role): string => {
  switch (role) {
    case Role.Business:
      return Constants.DEFAULT_PATH_BUSINESS;
    case Role.Seller:
      return Constants.DEFAULT_PATH_SELLER;
    case Role.Admin:
      return Constants.DEFAULT_PATH_STORE_ADMIN;
    case Role.Manager:
      return Constants.DEFAULT_PATH_STORE_MANAGER;
    case Role.SuperAdmin:
      return Constants.DEFAULT_PATH_SUPERADMIN;
    case Role.WholesaleCustomer:
      return Constants.DEFAULT_PATH_WHOLESALE_CUSTOMER;
    default:
      return '/';
  }
};

export const monthToString = (month: number): string => {
  switch (month) {
    case 1:
      return 'Enero';
    case 2:
      return 'Febrero';
    case 3:
      return 'Marzo';
    case 4:
      return 'Abril';
    case 5:
      return 'Mayo';
    case 6:
      return 'Junio';
    case 7:
      return 'Julio';
    case 8:
      return 'Agosto';
    case 9:
      return 'Septiembre';
    case 10:
      return 'Octubre';
    case 11:
      return 'Noviembre';
    case 12:
      return 'Diciembre';
    default:
      return '';
  }
};

export const purchaseOrderStatusStr = (purchaseOrderStatus: PurchaseOrderStatus): string => {
  switch (purchaseOrderStatus) {
    case PurchaseOrderStatus.Draft:
      return 'Borrador';
    case PurchaseOrderStatus.Generated:
      return 'Generada';
    case PurchaseOrderStatus.Cancelled:
      return 'Cancelada';
    case PurchaseOrderStatus.DeliveryNote:
      return 'Albarán';
    case PurchaseOrderStatus.Invoice:
      return 'Factura';
    default:
      return '';
  }
};

export const userWorkClockingActionToString = (uwca: UserWorkClockingAction) => {
  switch (uwca) {
    case UserWorkClockingAction.SingUp:
      return 'Fichaje';
    case UserWorkClockingAction.ClockIn:
      return 'Entrada';
    case UserWorkClockingAction.EditSignUp:
      return 'Editar fichaje';
    case UserWorkClockingAction.EditClockIn:
      return 'Editar entrada';
    case UserWorkClockingAction.EditClockOut:
      return 'Editar salida';
    case UserWorkClockingAction.EditStore:
      return 'Editar tienda';
    case UserWorkClockingAction.Delete:
      return 'Eliminar fichaje';
    default:
      return '';
  }
};

export const dateRangeToString = (dateRange: DateRange) => {
  switch (dateRange) {
    case DateRange.Today:
      return 'Hoy';
    case DateRange.Yesterday:
      return 'Ayer';
    case DateRange.ThisWeek:
      return 'Esta semana';
    case DateRange.ThisMonth:
      return 'Este mes';
    case DateRange.ThisYear:
      return 'Este año';
    case DateRange.LastYear:
      return 'Año pasado';
    case DateRange.All:
      return 'Todo';
    default:
      return 'Rango personalizado';
  }
};

export const deliveryNoteStatusToString = (deliveryNoteStatus: DeliveryNoteStatus) => {
  switch (deliveryNoteStatus) {
    case DeliveryNoteStatus.Cancelled:
      return 'Cancelado';
    case DeliveryNoteStatus.Pending:
      return 'Pendiente';
    case DeliveryNoteStatus.Received:
      return 'Recibido';
    case DeliveryNoteStatus.Invoiced:
      return 'Facturado';
    default:
      return '';
  }
};

export const ellipsis = (text: string, maxLength: number) => {
  if (!text) {
    return '';
  }
  const trimed = text.trim();
  if (trimed.length > maxLength) {
    return trimed.substring(0, maxLength) + '...';
  } else {
    return trimed;
  }
};

export const arrayBufferToJson = (arrayBuffer: ArrayBuffer): any => {
  const textDecoder = new TextDecoder('utf-8');
  const jsonString: string = textDecoder.decode(new Uint8Array(arrayBuffer));
  return JSON.parse(jsonString);
};
