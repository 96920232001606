import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Save } from 'react-feather';
import { OrganizationType } from '../enums/organization-type';
import { Role } from '../enums/role';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Organization } from '../interfaces/organization';
import { ProductStoreStock } from '../interfaces/product-store-stock';
import { Stock } from '../interfaces/stock';
import { UpdateStockDto } from '../interfaces/update-stock.dto';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { formatNumber } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface Props {
  productId: number;
  onModifiedStock: () => void;
}

interface ModifiedStock {
  [stockId: number]: {
    retailPrice: number | null;
    pvp: number | null;
    units: number | string;
    enabled: boolean;
    weight: number | null;
  };
}

const ProductStockTable = ({ productId, onModifiedStock }: Props) => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const organizationType: OrganizationType = useAppSelector((state: RootState) => state.auth.organizationType!);
  const [pss, setPss] = useState<ProductStoreStock[]>([]);
  const [modifiedStock, setModifiedStock] = useState<ModifiedStock>({});
  const [requesting, setRequesting] = useState<boolean>(false);

  useEffect(() => {
    const getProductStoresStock = async () => {
      try {
        const pss: ProductStoreStock[] = await api.getProductStoresStock(productId, organization!.id);
        const ms: ModifiedStock = {};
        pss.forEach((ps: ProductStoreStock) => {
          ms[ps.id] = {
            retailPrice: ps.retailPrice,
            pvp: ps.pvp,
            units: ps.quantity,
            enabled: ps.enabled,
            weight: ps.weight,
          };
        });
        setPss(pss);
        setModifiedStock(ms);
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          const axiosError: AxiosError = e as AxiosError;
          if (axiosError.response?.data) {
            const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
            myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
          }
        }
      }
    };
    getProductStoresStock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const updateStock = async (productStoreStock: ProductStoreStock) => {
    if (modifiedStock[productStoreStock.id].units === '') {
      myToastr.error(`Indica las unidades en stock para la tienda ${productStoreStock.storeName}`);
      return;
    }
    if (!modifiedStock[productStoreStock.id].pvp) {
      myToastr.error(`Indica el PVP para la tienda ${productStoreStock.storeName}`);
      return;
    }
    if (modifiedStock[productStoreStock.id].pvp! === 0) {
      myToastr.error(`El precio no puede ser 0 para la tienda ${productStoreStock.storeName}`);
      return;
    }
    setRequesting(true);
    try {
      const updateStockDto: UpdateStockDto = {
        retailPrice: modifiedStock[productStoreStock.id].retailPrice!,
        pvp: modifiedStock[productStoreStock.id].pvp!,
        units: modifiedStock[productStoreStock.id].units as number,
        enabled: modifiedStock[productStoreStock.id].enabled!,
        weight: modifiedStock[productStoreStock.id].weight ? modifiedStock[productStoreStock.id].weight : null,
      };
      const stock: Stock = await api.updateStock(productStoreStock.id, updateStockDto);
      const modifiedPss: ProductStoreStock[] = [...pss];
      const modifiedPs: ProductStoreStock = modifiedPss.find((ps: ProductStoreStock) => ps.id === productStoreStock.id)!;
      if (modifiedPs.quantity !== stock.quantity) {
        onModifiedStock();
      }
      modifiedPs.pvp = stock.pvp;
      modifiedPs.quantity = stock.quantity;
      modifiedPs.enabled = stock.enabled;
      modifiedPs.weight = stock.weight;
      setPss(modifiedPss);
      myToastr.success('Stock actualizado correctamente');
    } catch (e) {}
    setRequesting(false);
  };

  return (
    <table className="my-table">
      <thead>
        <tr>
          <th>{organizationType === OrganizationType.B2C ? 'Tienda' : 'Almacén'}</th>
          {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || user.role === Role.WholesaleCustomer) && <th>P. Coste</th>}
          {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2B) && (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
            <th>PVM</th>
          )}
          <th>PVP</th>
          <th>Peso (gr)</th>
          <th>Unidades</th>
          <th>Dto.</th>
          {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || user.role === Role.WholesaleCustomer) && <th>Beneficio</th>}
          <th>Activo</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {pss.length === 0 ? (
          <tr>
            <td colSpan={user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager ? 9 : 7} className="text-center">
              No hay stock del producto
            </td>
          </tr>
        ) : (
          pss.map((productStoreStock: ProductStoreStock) => {
            const hasChanged: boolean =
              modifiedStock.hasOwnProperty(productStoreStock.id) &&
              (modifiedStock[productStoreStock.id].retailPrice !== productStoreStock.retailPrice ||
                modifiedStock[productStoreStock.id].pvp !== productStoreStock.pvp ||
                modifiedStock[productStoreStock.id].units !== productStoreStock.quantity ||
                modifiedStock[productStoreStock.id].enabled !== productStoreStock.enabled ||
                modifiedStock[productStoreStock.id].weight !== productStoreStock.weight);
            return (
              <tr key={productStoreStock.id}>
                <td>{productStoreStock.storeName}</td>
                {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || user.role === Role.WholesaleCustomer) && (
                  <td>
                    {productStoreStock.discountValue !== null && productStoreStock.discountValue > 0 ? (
                      <div className="d-flex flex-row gap-2">
                        <span style={{ textDecoration: 'line-through' }}>{formatNumber(productStoreStock.price)}€</span>
                        <span>{formatNumber(productStoreStock.price! * (1 - productStoreStock.discountValue / 100))}€</span>
                      </div>
                    ) : (
                      <span>{formatNumber(productStoreStock.price)}€</span>
                    )}
                  </td>
                )}
                {(organization.type === OrganizationType.ALL || organization.type === OrganizationType.B2B) &&
                  (user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
                    <td>
                      <input
                        type="number"
                        value={modifiedStock.hasOwnProperty(productStoreStock.id) ? modifiedStock[productStoreStock.id].retailPrice || '' : 0}
                        min={0}
                        step={0.01}
                        disabled={requesting}
                        style={{ width: 100 }}
                        onChange={(e) => {
                          const data = { ...modifiedStock };
                          data[productStoreStock.id].retailPrice = e.target.value ? parseFloat(e.target.value) : null;
                          setModifiedStock(data);
                        }}
                      />
                    </td>
                  )}
                <td>
                  {user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager ? (
                    <input
                      type="number"
                      value={modifiedStock.hasOwnProperty(productStoreStock.id) ? modifiedStock[productStoreStock.id].pvp || '' : 0}
                      min={0}
                      step={0.01}
                      disabled={requesting}
                      style={{ width: 100 }}
                      onChange={(e) => {
                        const data = { ...modifiedStock };
                        data[productStoreStock.id].pvp = e.target.value ? parseFloat(e.target.value) : null;
                        setModifiedStock(data);
                      }}
                    />
                  ) : (
                    <span>{formatNumber(productStoreStock.pvp)}€</span>
                  )}
                </td>
                <td>
                  {user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager ? (
                    <input
                      type="number"
                      value={modifiedStock.hasOwnProperty(productStoreStock.id) ? modifiedStock[productStoreStock.id].weight || '' : 0}
                      min={0}
                      step={0.01}
                      disabled={requesting}
                      style={{ width: 100 }}
                      onChange={(e) => {
                        const data = { ...modifiedStock };
                        data[productStoreStock.id].weight = e.target.value ? parseFloat(e.target.value) : null;
                        setModifiedStock(data);
                      }}
                    />
                  ) : (
                    <span>{formatNumber(productStoreStock.weight)}gr</span>
                  )}
                </td>
                <td>
                  {user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || user.role === Role.WholesaleCustomer ? (
                    <input
                      type="number"
                      value={modifiedStock.hasOwnProperty(productStoreStock.id) ? modifiedStock[productStoreStock.id].units : ''}
                      step="any"
                      disabled={requesting}
                      style={{ minHeight: 16, width: 75 }}
                      onChange={(e) => {
                        const data = { ...modifiedStock };
                        data[productStoreStock.id].units = e.target.value && !isNaN(e.target.value as any) ? parseFloat(e.target.value) : '';
                        setModifiedStock(data);
                      }}
                    />
                  ) : (
                    <span>{formatNumber(productStoreStock.quantity, true)}</span>
                  )}
                </td>
                <td>{productStoreStock.discountValue ? formatNumber(productStoreStock.discountValue) : 0}%</td>
                {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && <td>{formatNumber(productStoreStock.profit)}€</td>}
                <td>
                  {user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager || user.role === Role.WholesaleCustomer ? (
                    <input
                      type="checkbox"
                      style={{ height: 16, marginTop: 8 }}
                      checked={modifiedStock.hasOwnProperty(productStoreStock.id) ? modifiedStock[productStoreStock.id].enabled : false}
                      disabled={requesting}
                      onChange={(e) => {
                        const data = { ...modifiedStock };
                        data[productStoreStock.id].enabled = e.target.checked;
                        setModifiedStock(data);
                      }}
                    />
                  ) : (
                    <span>{productStoreStock.enabled ? 'Sí' : 'No'}</span>
                  )}
                </td>
                <td>
                  <span
                    title="Guardar cambios"
                    style={{ cursor: hasChanged ? 'pointer' : 'not-allowed' }}
                    onClick={async () => {
                      if (requesting) {
                        return;
                      }
                      if (!hasChanged) {
                        return;
                      }
                      await updateStock(productStoreStock);
                    }}
                  >
                    <Save size={16} color={hasChanged ? '#222E3D' : '#c7cfd8'} />
                  </span>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default ProductStockTable;
